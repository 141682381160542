import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPasswordStageTwo,
  forgotPasswordStageOne,
  resetPassword,
} from "@Redux/Services/Auth/authSevices";

const initialState: IForgotPasswordState = {
  AdminFavorite: null,
  resetParamsValue: null,
  isError: false,
  status: "",
  isSuccess: false,
  reSeted: false,
  message: "",
};

const forgotpasswordSlices = createSlice({
  name: "forgotpassword",
  initialState,
  reducers: {
    resetForgotPasswordStore: (state) => {
      state.AdminFavorite = null;
      state.resetParamsValue = null;
      state.isError = false;
      state.status = "";
      state.isSuccess = false;
      state.reSeted = false;
      state.message = "";
    },
    forgotPasswordResetMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers(builder) {
    builder.addCase(forgotPasswordStageOne.pending, (state, action) => {
      state.status = action.meta.requestStatus;
      console.log("pending", state);
    });
    builder.addCase(forgotPasswordStageOne.fulfilled, (state, action) => {
      state.status = action.meta.requestStatus;
      state.isSuccess = true;
      state.AdminFavorite = action.payload.data;
      console.log(action.payload);
    });
    builder.addCase(forgotPasswordStageOne.rejected, (state, action) => {
      state.status = action.meta.requestStatus;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload as string;
      state.AdminFavorite = null;
      console.log(state);
    });

    builder.addCase(forgotPasswordStageTwo.pending, (state, action) => {
      state.status = action.meta.requestStatus;
      console.log("pending", state);
    });
    builder.addCase(forgotPasswordStageTwo.fulfilled, (state, action) => {
      state.status = action.meta.requestStatus;
      state.isSuccess = true;
      state.resetParamsValue = action.payload.data;
      state.message = action.payload?.message as string;
      state.AdminFavorite = null;
    });
    builder.addCase(forgotPasswordStageTwo.rejected, (state, action) => {
      state.status = action.meta.requestStatus;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload as string;
      state.AdminFavorite = null;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.status = action.meta.requestStatus;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.status = action.meta.requestStatus;
      state.isSuccess = true;
      state.reSeted = true;
      state.isError = false;
      state.message = action.payload?.message as string;
      state.AdminFavorite = null;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.status = action.meta.requestStatus;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload as string;
    });
  },
});

export const { resetForgotPasswordStore, forgotPasswordResetMessage } = forgotpasswordSlices.actions;

export const AdminForgotPasswordState = (state: any) => state.forgotPasswordStore;

export default forgotpasswordSlices.reducer;
