import { createCreatorTypeData } from "@Redux/Services/Creators/creatorsService";
import { selectAdminConstants } from "@Redux/Slices/Constants/constantSlices";
import { selectCreators } from "@Redux/Slices/creator/creatorSlice";

import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import { creatorCustomValue } from "@Schema/schema";
import { creatorCustomDataTypeValidate } from "@Validation/CreatValidation";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Box,
  Text,
  Input,
  Stack,
  Select,
  ModalCloseButton,
} from "@chakra-ui/react";

import { UseCustomAppColors, inputBorderColor } from "@utils/color";
import { inputFontSize } from "@utils/fonts";
import { SlugUnderscores } from "@utils/helper";

import { useCustomToast } from "@utils/toast";

import { Formik } from "formik";
import React, { useEffect, useState } from "react";

type Props = { isOpen: boolean; onOpen: () => void; onClose: () => void; tittle: string };

export const CreateCustomCreator = React.memo(({ isOpen, onOpen, onClose, tittle }: Props) => {
  const { secondBg, inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const { notifyError, notifySuccess } = useCustomToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [creatorData, setCreatorData] = useState<any>();

  const AdminConstantData = useAppSelector(selectAdminConstants);
  const { creator }: ICreator = useAppSelector(selectCreators);
  const [adminRole, setAdminRole] = useState<any>();

  useEffect(() => {
    setCreatorData(creator);
    setAdminRole(AdminConstantData.AdminRole);
  }, [isOpen, creator, AdminConstantData.AdminRole]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>{`Create ${tittle}`}</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody py={["1", "3", "14"]} px={["8", "18", "48"]}>
            <Box bg={secondBg} boxShadow={"0px 0px 4px #b7b7b7"} rounded={"lg"} px={"14"} pb={["10", "20"]}>
              <Heading py={"12"}>{`Create ${tittle}`}</Heading>
              <Box bg="white" rounded="md">
                <Formik
                  initialValues={creatorCustomValue}
                  validationSchema={creatorCustomDataTypeValidate}
                  onSubmit={(values, action) => {
                    try {
                      setIsLoading(true);
                      const init = async () => {
                        let customDataCreate = await dispatch(
                          createCreatorTypeData({ creatorTypeId: creatorData.id, ...values })
                        );

                        if (customDataCreate.meta.requestStatus === "fulfilled") {
                          notifySuccess(customDataCreate.payload.message);
                          action.resetForm();
                          setIsLoading(false);
                          onClose();
                        }
                        if (customDataCreate.meta.requestStatus === "rejected") {
                          console.log(customDataCreate);
                          setIsLoading(false);
                          notifyError(customDataCreate.payload);
                        }
                      };
                      init();
                    } catch (error: any) {
                      console.log(error.message);
                    }
                  }}
                >
                  {({ handleSubmit, errors, touched, handleChange, values, handleBlur }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack spacing={"3"} flexDir={"column"}>
                        <Box>
                          <Input
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="name"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            value={values.name}
                            type="text"
                            onBlur={handleBlur}
                            name="name"
                          />
                          {errors.name && touched.name && <Text className="error">{errors.name}</Text>}
                        </Box>

                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="isRequired"
                            name="isRequired"
                            width={"100%"}
                            height={["30px", "50px"]}
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            placeholder="Select IsRequired"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isRequired}
                          >
                            <option value={"true"}> True</option>
                            <option value={"false"}> False</option>
                          </Select>
                          {/* </FormControl> */}

                          {errors.isRequired && touched.isRequired && (
                            <Text className="error">{errors.isRequired}</Text>
                          )}
                        </Box>
                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="dataType"
                            name="dataType"
                            width={"100%"}
                            height={["30px", "50px"]}
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            placeholder="Select Data Type"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.dataType}
                          >
                            <option value={"STRING"}> STRING</option>
                            <option value={"NUMBER"}> NUMBER</option>
                            <option value={"ARRAY"}> ARRAY</option>
                            <option value={"OBJECT"}> OBJECT</option>
                            <option value={"BOOLEAN"}> BOOLEAN</option>
                          </Select>
                          {/* </FormControl> */}

                          {errors.dataType && touched.dataType && (
                            <Text className="error">{errors.dataType}</Text>
                          )}
                        </Box>

                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="status"
                            name="status"
                            width={"100%"}
                            height={["30px", "50px"]}
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            placeholder="Select Status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.status}
                          >
                            <option value={"true"}> True</option>
                            <option value={"false"}> False</option>
                          </Select>
                          {/* </FormControl> */}

                          {errors.status && touched.status && <Text className="error">{errors.status}</Text>}
                        </Box>

                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="validtionRequired"
                            name="validtionRequired"
                            width={"100%"}
                            height={["30px", "50px"]}
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            placeholder="Select Validtion Required"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.validtionRequired}
                          >
                            <option value={"true"}> True</option>
                            <option value={"false"}> False</option>
                          </Select>
                          {/* </FormControl> */}

                          {errors.validtionRequired && touched.validtionRequired && (
                            <Text className="error">{errors.validtionRequired}</Text>
                          )}
                        </Box>
                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="verifier"
                            name="verifier"
                            width={"100%"}
                            height={["30px", "50px"]}
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            placeholder="Select Verifier"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.verifier}
                          >
                            <option value="NONE">NONE</option>
                            {Object.keys(adminRole!).map((key) => {
                              return (
                                adminRole[key]! !== undefined &&
                                adminRole[key] !== "NONE" && (
                                  <option key={key} value={adminRole[key]!}>
                                    {adminRole[key] !== undefined && SlugUnderscores(adminRole[key]!)}
                                  </option>
                                )
                              );
                            })}
                          </Select>
                          {/* </FormControl> */}

                          {errors.verifier && touched.verifier && (
                            <Text className="error">{errors.verifier}</Text>
                          )}
                        </Box>

                        <Box>
                          <Input
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="description"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            value={values.description}
                            type="text"
                            onBlur={handleBlur}
                            name="description"
                          />
                          {errors.description && touched.description && (
                            <Text className="error">{errors.description}</Text>
                          )}
                        </Box>
                        <Box
                          display={"flex"}
                          flexDir={["column", "row"]}
                          justifyContent={"space-between"}
                          my={"10"}
                        >
                          <Button
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            py={[".4rem", "1.5rem"]}
                            px={["1.8rem", "3.8rem"]}
                            mb={{ base: "2.5" }}
                            bg={inverseSecondBg}
                            color={textColorInverse}
                            borderRadius={"lg"}
                            borderWidth={".1rem"}
                            _hover={{ bg: inverseSecondBg }}
                            isLoading={isLoading}
                            loadingText="Submitting"
                            variant="outline"
                            type="submit"
                          >
                            Create
                          </Button>
                        </Box>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
