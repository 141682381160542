import React from "react";
import { FormControl, FormLabel, Input, InputProps, HStack } from "@chakra-ui/react";

interface CustomInputProps extends InputProps {
  label: string;
}

export const CustomInputMain: React.FC<CustomInputProps> = ({ label, ...props }) => {
  return (
    <FormControl>
      <FormLabel fontWeight={"bold"} htmlFor={props.id || props.name}>
        {label}
      </FormLabel>
      <Input {...props} />
    </FormControl>
  );
};

export const CustomInputLabelAtLeft: React.FC<CustomInputProps> = ({ label, ...props }) => {
  return (
    <FormControl>
      <HStack>
        {" "}
        <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel> <Input {...props} />
      </HStack>
    </FormControl>
  );
};

//
