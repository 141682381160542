/// <reference types="redux-persist/types" />

import { Action, ThunkAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";

// slice import
import authSlice from "./Slices/Auth/authSlices";
import forgotPasswordSlice from "./Slices/Auth/resetPasswordSlices";
import constantSlices from "./Slices/Constants/constantSlices";
import promotionSlices from "@Redux/Slices/EnrollmentAndPromotion/PromotionSlice";
import EnrollmentSlice from "./Slices/EnrollmentAndPromotion/EnrollmentSlice";
import StaffSlice from "./Slices/Staff/StaffSlice";
import creatorSlice from "./Slices/creator/creatorSlice";
import appsettingSlices from "./Slices/AppSetting/appsettingSlices";

const appReducer = combineReducers({
  authStore: authSlice,
  forgotPasswordStore: forgotPasswordSlice,
  constantStore: constantSlices,
  promotionStore: promotionSlices,
  enrollmentStore: EnrollmentSlice,
  staffStore: StaffSlice,
  creatorsStore: creatorSlice,
  AppSettingsStore: appsettingSlices,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "user/logout/pending") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export const removePersistedData = () => {
  persistor.purge();
  storage.removeItem("persist:root");
};
export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
