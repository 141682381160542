import { SetPayload } from "@utils/helper";
import config from "@utils/requestConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createEnrollment = createAsyncThunk(
  "create-enrollment",
  async (EnrollmentData: any, thunkAPI) => {
    const payload = SetPayload(EnrollmentData);
    console.log(payload);
    try {
      const Response = await config.post(`admin/add/enrollment`, payload);
      console.log(Response);
      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getEnrollment = createAsyncThunk("get-enrollment", async (_, thunkAPI) => {
  try {
    const Response = await config.get(`admin/get/enrollment`);
    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateEnrollment = createAsyncThunk(
  "update-enrollment",
  async (enrollmentData: any, thunkAPI) => {
    try {
      const payload = SetPayload(enrollmentData);

      const Response = await config.patch(`admin/update/enrollment`, payload);
      console.log(Response);

      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteEnrollment = createAsyncThunk(
  "delete-enrollment",
  async (enrollmentId: string, thunkAPI) => {
    try {
      const Response = await config.delete(`admin/delete/enrollment/${enrollmentId}`);
      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
