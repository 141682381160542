import { SetPayload } from "@utils/helper";
import config from "@utils/requestConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createPromotion = createAsyncThunk("create-promotion", async (promotionData: any, thunkAPI) => {
  const payload = SetPayload(promotionData);
  try {
    const Response = await config.post(`admin/add/promotion`, payload);
    console.log(Response);

    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const getPromotions = createAsyncThunk("get-promotion", async (_, thunkAPI) => {
  try {
    const Response = await config.get(`admin/get/promotions`);
    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const deletePromotion = createAsyncThunk("delete-promotion", async (promotionId: string, thunkAPI) => {
  try {
    const Response = await config.delete(`admin/delete/promotion/${promotionId}`);
    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatePromotion = createAsyncThunk("update-promotion", async (promotionData: any, thunkAPI) => {
  try {
    console.log(promotionData);

    const payload = SetPayload(promotionData);
    console.log(payload);

    const Response = await config.post(`admin/update/promotion`, payload);
    console.log(Response);

    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const getPromotedAsset = createAsyncThunk("get-promoted-asset", async (_, thunkAPI) => {
  try {
    const Response = await config.get(`admin/get/agency/promotions`);
    console.log(Response);

    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});
