import { createSlice } from "@reduxjs/toolkit";

import {
  createCreatorType,
  getCreatorType,
  getAllCustomCreatorTypeData,
  deleteCreatorType,
  updateCreatorTypeStatus,
  createCreatorTypeData,
  getCustomCreatorTypeData,
} from "@Redux/Services/Creators/creatorsService";

const initialState: ICreator = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  creators: [],
  creator: [],
  creatorCustom: [],
  totalRecords: 0,
};

const creators = createSlice({
  name: `creators`,
  initialState,
  reducers: {
    resetCreators: (state) => {
      state.creators = [];
      state.creator = "";
      state.creatorCustom = [];
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },

    resetCustomData: (state) => {
      state.creator = "";
      state.creatorCustom = [];
    },
    resetCreatorsStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    },
    resetCreatorsMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createCreatorType.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createCreatorType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.creators.push(action.payload?.data?.creator);
      })
      .addCase(createCreatorType.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(createCreatorTypeData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createCreatorTypeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.creatorCustom.push(action.payload?.data?.CreatorCustom);
      })
      .addCase(createCreatorTypeData.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(getCreatorType.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCreatorType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.creators = action.payload.data?.creatorTypes;
      })
      .addCase(getCreatorType.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(getAllCustomCreatorTypeData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllCustomCreatorTypeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.creators = action.payload.data?.CreatorCustom;
      })
      .addCase(getAllCustomCreatorTypeData.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(deleteCreatorType.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCreatorType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.creators = state.creators.filter((obj) => obj.id !== action.meta?.arg?.id);
      })
      .addCase(deleteCreatorType.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(updateCreatorTypeStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCreatorTypeStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.creators = state.creators.filter((obj) => obj.id !== action.meta?.arg?.id);
        state.creators.push(action.payload?.data?.creator);
      })
      .addCase(updateCreatorTypeStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(getCustomCreatorTypeData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCustomCreatorTypeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.creatorCustom = action.payload.data?.creatorType?.creator_custom_data;
        const { creator_custom_data, ...others } = action.payload.data?.creatorType;
        state.creator = others;
      })
      .addCase(getCustomCreatorTypeData.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload as string;
      });
  },
});

export const { resetCreators, resetCreatorsMessage, resetCreatorsStatus, resetCustomData } = creators.actions;

export const selectCreators = (state: any) => state.creatorsStore;
export default creators.reducer;
