import { UseCustomAppColors } from "@utils/color";

import { Box, Button, Flex, Input, Stack, Text } from "@chakra-ui/react";

import { useFormik } from "formik";

import { Weight, inputFontSize } from "@utils/fonts";
import "../auth.styles.css";

import { forgotPasswordStageTwo } from "@Redux/Services/Auth/authSevices";

import { useAppDispatch } from "@Redux/hooks";
import { forgotPasswordValueTwo } from "@Schema/schema";
import { forgortPasswordSchemaTwo } from "@Validation/authValidation";

type Props = {
  loading: boolean;
  setLoading: (value: boolean) => void;
  favoriteName: string | null;
};
export default function ForgotPasswordStageTwo({ favoriteName, loading, setLoading }: Props) {
  const { inverseSecondBg, secondBg, textColor, textColorInverse, HeaderColor } = UseCustomAppColors();

  const dispatch = useAppDispatch();

  const AdminForgotPassword = useFormik({
    initialValues: forgotPasswordValueTwo,
    validationSchema: forgortPasswordSchemaTwo,
    onSubmit: (value, action) => {
      setLoading(true);
      const Data = { favorite: value.favorite };
      dispatch(forgotPasswordStageTwo(Data));
      action.resetForm();
    },
  });
  return (
    <Flex
      w={"100%"}
      bg={secondBg}
      flexDir={"column"}
      boxShadow={"base"}
      p="6"
      rounded="lg"
      m={"auto"}
      px={{ md: "14", lg: "20" }}
      maxW={["400px", "700px", "1200px"]}
    >
      {/* <Box> */}
      <Text color={HeaderColor} w={"100%"} fontSize={["16px", "30px", "40px"]} fontWeight={Weight.MEDIUM}>
        HQ Forgot Password B
      </Text>

      {/* </Box> */}

      <Box>
        <form onSubmit={AdminForgotPassword.handleSubmit}>
          <Flex flexDir={"column"} pt={"3rem"}>
            <Text mb={"3"} color={HeaderColor} as={"p"} w={"100%"} fontSize={["12px", "16px", "20px"]}>
              {favoriteName}
            </Text>
            <Stack spacing={"2"} mb={["8", "12"]}>
              <Input
                width={"100%"}
                height={["35px", "55px"]}
                placeholder="Enter Favorite"
                borderColor={textColor}
                fontSize={[inputFontSize.base, inputFontSize.md]}
                onChange={AdminForgotPassword.handleChange}
                value={AdminForgotPassword.values.favorite}
                onBlur={AdminForgotPassword.handleBlur}
                name="favorite"
              />
              {AdminForgotPassword.errors.favorite && AdminForgotPassword.touched.favorite && (
                <Text className="error">{AdminForgotPassword.errors.favorite}</Text>
              )}
            </Stack>
          </Flex>
          <Flex flexDir={"column"} width={"100%"}>
            <Flex justifyContent={"right"}>
              <Button
                fontSize={[inputFontSize.base, inputFontSize.md]}
                py={[".6rem", "1.8rem"]}
                px={["2rem", "4rem"]}
                bg={inverseSecondBg}
                color={textColorInverse}
                borderRadius={"base"}
                borderWidth={".1rem"}
                _hover={{ bg: inverseSecondBg }}
                isLoading={loading}
                loadingText="Submitting"
                colorScheme="teal"
                variant="outline"
                type="submit"
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        </form>
      </Box>
    </Flex>
  );
}
