import { Box, CloseButton, Stack } from "@chakra-ui/react";

import { UseCustomAppColors } from "@utils/color";

type PropsMobileLeftSideBar = {
  handleClose?: () => void;
  isOpen: boolean;
};
export function MobileLeftSideBar({ handleClose, isOpen }: PropsMobileLeftSideBar) {
  const { textColorInverse } = UseCustomAppColors();

  return (
    <Box
      py={2}
      pb={8}
      px={6}
      w={["100%", "80%"]}
      display={["block", "block", "none", "none"]}
      minW={210}
      maxW={280}
      h={"100%"}
      bg={textColorInverse}
      minH="88vh"
    >
      <Stack
        alignItems={"end"}
        mb={4}
        position={"sticky"}
        top={0}
        zIndex={5}
        py={4}
        backdropFilter={"blur(4px)"}
        bg={"#fff50"}
      >
        <CloseButton display={["block", "block", "block", "none"]} onClick={handleClose} variant={"ghost"} />
      </Stack>
      MobilSideBar
    </Box>
  );
}
