import { useColorModeValue } from "@chakra-ui/react";

export function UseCustomAppColors() {
  const bg = useColorModeValue("#F7F7F7", "#050505");
  const secondBg = useColorModeValue("#FFFFFF", "#042040");

  const inverseSecondBg = useColorModeValue("#042040", "#FFFFFF");

  const textColor = useColorModeValue("#042040", "#FFFFFF");

  const textColorInverse = useColorModeValue("#FFFFFF", "#042040");

  const HeaderColor = useColorModeValue("#042040", "#FFFFFF");

  return { bg, secondBg, textColor, HeaderColor, textColorInverse, inverseSecondBg };
}

export const inputBorderColor = { borderColor: "#b7b7b7", focusBorderColor: "#8c8c8c" };
