import { useState, useEffect } from "react";
import AuthLayout from "@layouts/authLayout/AppAuthlayout";
import { UseCustomAppColors } from "@utils/color";
// import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, Icon, Input, InputGroup, InputRightElement, Stack, Text } from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import { resetPasswordValue } from "@Schema/schema";
import { resetPasswordSchema } from "@Validation/authValidation";
import { Weight, inputFontSize } from "@utils/fonts";
import "./auth.styles.css";
import { routePath } from "@utils/routes";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";

// import { AdminAuthStageOne, resetMessage } from "../../Redux/Slices/Auth/authSlices";
// import { useCustomToast } from "../../utils/toast";
import {
  AdminForgotPasswordState,
  forgotPasswordResetMessage,
  resetForgotPasswordStore,
} from "@Redux/Slices/Auth/resetPasswordSlices";
import { resetAuthStore } from "@Redux/Slices/Auth/authSlices";
import { resetPassword } from "@Redux/Services/Auth/authSevices";
import { useCustomToast } from "@utils/toast";
import { useNavigate } from "react-router-dom";

export default function Reset_password() {
  const { inverseSecondBg, secondBg, textColor, textColorInverse, HeaderColor } = UseCustomAppColors();
  const { notifyError, notifySuccess } = useCustomToast();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const resetPasswordState: IForgotPasswordState = useAppSelector(AdminForgotPasswordState);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    try {
      if (resetPasswordState.isError !== null) {
        setLoading(false);
        resetPasswordState.message !== "" && notifyError(resetPasswordState.message!);
        dispatch(forgotPasswordResetMessage());
      }
      if (resetPasswordState.isSuccess && resetPasswordState.reSeted) {
        setLoading(false);
        resetPasswordState.message !== "" && notifySuccess(resetPasswordState.message!);
        dispatch(forgotPasswordResetMessage());
        dispatch(resetForgotPasswordStore());
        dispatch(resetAuthStore());
        navigate(routePath.login);
      }
    } catch (error) {
      console.log(error as TypeError);
    }
  }, [resetPasswordState]);

  useEffect(() => {
    try {
      if (resetPasswordState.resetParamsValue === null) {
        navigate(routePath.forgot_password);
      }
    } catch (error) {
      console.log(error as TypeError);
    }
  }, []);

  const AdminResetPasswordLogin = useFormik({
    initialValues: resetPasswordValue,
    validationSchema: resetPasswordSchema,
    onSubmit: (value, action) => {
      value.restId = resetPasswordState.resetParamsValue?.resetValue;

      setLoading(true);
      dispatch(resetPassword(value));
      action.resetForm();
    },
  });
  return (
    <AuthLayout>
      <Flex
        w={"100%"}
        bg={secondBg}
        flexDir={"column"}
        boxShadow={"base"}
        p="6"
        rounded="lg"
        m={"auto"}
        px={{ md: "14", lg: "20" }}
        maxW={["400px", "700px", "1200px"]}
      >
        {/* <Box> */}
        <Text color={HeaderColor} w={"100%"} fontSize={["16px", "30px", "40px"]} fontWeight={Weight.MEDIUM}>
          Puzzle HQ
        </Text>

        {/* </Box> */}

        <Box>
          <form onSubmit={AdminResetPasswordLogin.handleSubmit}>
            <Flex flexDir={"column"} pt={"3.5rem"}>
              {/* <Text color={HeaderColor} w={"100%"} fontSize={[Font.PRIMARY, Font.SECONDARY]}>
                Reset Password
              </Text> */}
              <Stack spacing={"2"} mb={["6", "10"]}>
                <Input
                  width={"100%"}
                  height={["35px", "55px"]}
                  placeholder="Enter OTP"
                  borderColor={textColor}
                  fontSize={[inputFontSize.base, inputFontSize.md]}
                  onChange={AdminResetPasswordLogin.handleChange}
                  value={AdminResetPasswordLogin.values.otp}
                  onBlur={AdminResetPasswordLogin.handleBlur}
                  name="otp"
                />

                {AdminResetPasswordLogin.errors.otp && AdminResetPasswordLogin.touched.otp && (
                  <Text className="error">{AdminResetPasswordLogin.errors.otp}</Text>
                )}
              </Stack>

              <Stack spacing={"2"} mb={["6", "10"]}>
                <Box>
                  <InputGroup width={"100%"} height={["35px", "55px"]}>
                    <Input
                      pr="4.5rem"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      onChange={AdminResetPasswordLogin.handleChange}
                      value={AdminResetPasswordLogin.values.password}
                      onBlur={AdminResetPasswordLogin.handleBlur}
                      borderColor={textColor}
                      name="password"
                      fontSize={[inputFontSize.base, inputFontSize.md]}
                      width={"100%"}
                      height={"100%"}
                    />
                    <InputRightElement height={["35px", "55px"]}>
                      <Icon
                        // position={"absolute"}
                        as={showPassword ? ViewIcon : ViewOffIcon}
                        fontSize={["2xs", "2xl"]}
                        onClick={() => {
                          showPassword ? setShowPassword(false) : setShowPassword(true);
                        }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Box>
                {/* <ErrorMessage name="email" component={"div"} className="error" /> */}

                {AdminResetPasswordLogin.errors.password && AdminResetPasswordLogin.touched.password && (
                  <Text className="error">{AdminResetPasswordLogin.errors.password}</Text>
                )}
              </Stack>

              <Stack spacing={"2"}>
                <Box>
                  <InputGroup width={"100%"} height={["35px", "55px"]}>
                    <Input
                      pr="4.5rem"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter confirmPassword"
                      onChange={AdminResetPasswordLogin.handleChange}
                      value={AdminResetPasswordLogin.values.confirmPassword}
                      onBlur={AdminResetPasswordLogin.handleBlur}
                      borderColor={textColor}
                      name="confirmPassword"
                      fontSize={[inputFontSize.base, inputFontSize.md]}
                      width={"100%"}
                      height={["35px", "55px"]}
                    />
                    <InputRightElement height={["35px", "55px"]}>
                      <Icon
                        // position={"absolute"}
                        as={showPassword ? ViewIcon : ViewOffIcon}
                        fontSize={["2xs", "2xl"]}
                        onClick={() => {
                          showPassword ? setShowPassword(false) : setShowPassword(true);
                        }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Box>
                {/* <ErrorMessage name="email" component={"div"} className="error" /> */}

                {AdminResetPasswordLogin.errors.confirmPassword &&
                  AdminResetPasswordLogin.touched.confirmPassword && (
                    <Text className="error">{AdminResetPasswordLogin.errors.confirmPassword}</Text>
                  )}
              </Stack>
            </Flex>
            <Flex flexDir={"column"} width={"100%"}>
              <Box display={"flex"} justifyContent={["center", "right"]} my={"10"}>
                <Button
                  fontSize={[inputFontSize.base, inputFontSize.md]}
                  py={[".6rem", "1.8rem"]}
                  px={["2rem", "4rem"]}
                  bg={inverseSecondBg}
                  color={textColorInverse}
                  borderRadius={"base"}
                  borderWidth={".1rem"}
                  _hover={{ bg: inverseSecondBg }}
                  isLoading={loading}
                  loadingText="Submitting"
                  colorScheme="teal"
                  variant="outline"
                  type="submit"
                >
                  Reset Password
                </Button>
              </Box>
            </Flex>
          </form>
        </Box>
      </Flex>
    </AuthLayout>
  );
}
