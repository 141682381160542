import {
  Box,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Heading,
  HStack,
  Button,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { UseCustomAppColors } from "@utils/color";

import { CustomInputMain } from "@components/modals/CustomInputMain";
import { useEffect, useState } from "react";
import { IoIosRemoveCircle } from "react-icons/io";

import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import {
  resetSettings,
  resetSettingsMessage,
  selectAppSettings,
} from "@Redux/Slices/AppSetting/appsettingSlices";

import { getSettings } from "@Redux/Services/AppSetting/appsetting";

import { MainLayout2 } from "@layouts/dashboardLayout/MainLayout2";
import { onlySlugUnderscores, SlugUnderscores } from "@utils/helper";
import { RemoveItemTypeItem } from "./AppSettingComponentsForType/RemoveItemInType";
import { EditAppType } from "./AppSettingComponentsForType/EditType";
import { AddNewAppType } from "./AppSettingComponentsForType/CreateType";
import { MdAddCircleOutline } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";

function Index() {
  const { isOpen: removeIsOpen, onOpen: removeOnOpen, onClose: removeOnClose } = useDisclosure();
  const { isOpen: createIsOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  const { inverseSecondBg, textColor, secondBg } = UseCustomAppColors();

  const [appTypeData, setAppTypeData] = useState<AppType[] | null>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<AppType | null>();
  const [valueToEdit, setValueToEdit] = useState<string | number>();
  const [valueToRemove, setValueToRemove] = useState<string | number>();
  const [editItem, setEditItem] = useState<AppType | null>();
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDelete = () => {
    setIsModalOpen(false);
  };

  const dispatch = useAppDispatch();

  const { appType,isLoading }: IAppSettings = useAppSelector(selectAppSettings);

  useEffect(() => {
    dispatch(resetSettingsMessage());
    dispatch(getSettings({}));
    dispatch(resetSettings());
  }, [dispatch]);

  useEffect(() => {
    try {
      if (appType) {
        setAppTypeData(appType);
      }
    } catch (error) {
      console.log(error);
    }
  }, [appType, isLoading]);

  let TabsForAppTypeForSmallScreen = appTypeData?.map((appType: AppType) => {
    return (
      <Tab
        _selected={{ color: textColor, bg: secondBg }}
        textTransform={"capitalize"}
        key={appType.type}
        minW={"200px"}
        boxShadow={"0px 0px 4px #b7b7b7"}
      >
        {" "}
        {onlySlugUnderscores(appType.name!)}
      </Tab>
    );
  });

  let TabsForAppType = appTypeData?.map((appType: AppType) => {
    return (
      <Tab
        key={appType.type}
        _selected={{ color: "white", bg: inverseSecondBg }}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          w={"5"}
          h={"5"}
          bg={inverseSecondBg}
          rounded={"full"}
          outline={"solid"}
          outlineOffset={"2px"}
          p={"2"}
          mr={"2"}
        ></Box>
        <Text textTransform={"capitalize"} w={"60%"}>
          {SlugUnderscores(appType.name!)}
        </Text>
      </Tab>
    );
  });

  let listTabsForAppType = appTypeData?.map((appType: AppType) => {
    return (
      <TabPanel key={appType.type}>
        <Box padding={"1"} py="1" pb={"12"}>
          <Flex justify={"space-between"} boxShadow={"base"} py={"3"} px={"5"}>
            <Flex justify={"flex-start"}>
              <HStack mr={"3"}>
                <Heading fontWeight={"medium"} fontSize={["13px", "18px", "22px"]} mr={"1"}>
                  Name:
                </Heading>
                <Text textTransform={"capitalize"} fontWeight={"medium"} fontSize={["11px", "14px", "18px"]}>
                  {onlySlugUnderscores(appType.name!)}
                </Text>
              </HStack>

              <HStack>
                <Heading fontWeight={"medium"} fontSize={["13px", "18px", "22px"]} mr={"1"}>
                  Type:
                </Heading>
                <Text textTransform={"capitalize"} fontWeight={"medium"} fontSize={["11px", "14px", "18px"]}>
                  {onlySlugUnderscores(appType.type!)}
                </Text>
              </HStack>
            </Flex>
            <Button
              colorScheme="gray"
              variant={"unstyled"}
              onClick={() => {
                setEditItem(
                  appTypeData?.find(
                    (type: AppType) => type.name === appType.name && type.type === appType.type
                  ) || null
                );
                return createOnOpen();
              }}
            >
              <MdAddCircleOutline fontSize={"2.2rem"} />
            </Button>
          </Flex>
          <Box mt={"5"} boxShadow={"base"} py={"3"} px={"5"}>
            {appType.value &&
              Object.entries(appType?.value).map(([key, typeValue], index) => (
                <Stack
                  key={`${typeValue}_${index}`}
                  direction={["column", "column", "row", "row"]}
                  align={"flex-end"}
                >
                  <CustomInputMain
                    label="Name"
                    name="name"
                    value={key}
                    isReadOnly={true}
                    color={"gray.400"}
                  />
                  <CustomInputMain
                    label="Value"
                    name="end"
                    value={typeValue}
                    isReadOnly={true}
                    color={"gray.400"}
                  />
                  <Popover placement="left-start">
                    <PopoverTrigger>
                      <Button variant={"unstyled"}>
                        <BsThreeDots fontSize={"1.6rem"} />
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent w={["140px", "150px", "180px"]}>
                        <PopoverArrow />
                        <PopoverBody boxShadow={"0px 0px 4px #808080"} rounded={["md", "md", "2xl"]}>
                          <Flex flexDir={"column"}>
                            <Button
                              colorScheme="gray"
                              variant={"unstyled"}
                              onClick={() => {
                                setEditItem(
                                  appTypeData?.find(
                                    (type: AppType) =>
                                      type.name === appType.name && type.type === appType.type
                                  ) || null
                                );
                                setValueToEdit(key);
                                return editOnOpen();
                              }}
                            >
                              <HStack justifyContent={"space-between"}>
                                <Text mr={["5px", "6px"]}>Edit</Text>
                                <Button variant={"unstyled"}>
                                  {" "}
                                  <FiEdit />
                                </Button>
                              </HStack>
                            </Button>

                            <Button
                              colorScheme="gray"
                              variant={"unstyled"}
                              onClick={() => {
                                setItemToRemove(
                                  appTypeData?.find(
                                    (type: AppType) =>
                                      type.name === appType.name && type.type === appType.type
                                  ) || null
                                );
                                setValueToRemove(key);
                                setIsModalOpen(true);
                                removeOnOpen();
                              }}
                            >
                              <HStack justifyContent={"space-between"}>
                                <Text mr={["4px"]}>Remove</Text>
                                <Button variant={"unstyled"}>
                                  {" "}
                                  <IoIosRemoveCircle fontSize={"1.8rem"} />
                                </Button>
                              </HStack>
                            </Button>
                          </Flex>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Stack>
              ))}
          </Box>
        </Box>
      </TabPanel>
    );
  });

  return (
    <MainLayout2>
      <Box display={["none", "none", "none", "flex"]} flexDir={"row"}>
        <Tabs w={"100%"}>
          <Box justifyContent={"space-between"} display={"flex"} minH={"85vh"}>
            <Box w={"20%"} bg={secondBg} py={"20"}>
              <TabList display={"flex"} flexDir={"column"}>
                {TabsForAppType}
              </TabList>
            </Box>
            <Box w={"78%"} bg={secondBg}>
              <TabPanels>{listTabsForAppType}</TabPanels>
            </Box>
          </Box>
        </Tabs>
      </Box>
      <Box display={["flex", "flex", "flex", "none"]}>
        <Tabs w={"100%"}>
          {/* maxW={["350px", "500px", "600px"]} */}
          <Box overflowX={"scroll"} w={"100%"}>
            {" "}
            <TabList>{TabsForAppTypeForSmallScreen}</TabList>
          </Box>
          <TabPanels w={"100%"}>{listTabsForAppType}</TabPanels>
        </Tabs>
      </Box>

      <RemoveItemTypeItem
        itemToRemove={itemToRemove!}
        valueToRemove={valueToRemove!}
        isOpen={removeIsOpen}
        onOpen={removeOnOpen}
        onClose={removeOnClose}
        onDelete={handleDelete}
      />

      <EditAppType
        isOpen={editIsOpen}
        onOpen={editOnOpen}
        onClose={editOnClose}
        editData={editItem!}
        valueToEdit={valueToEdit!}
      />

      <AddNewAppType
        editData={editItem!}
        isOpen={createIsOpen}
        onClose={createOnClose}
        onOpen={createOnOpen}
      />
    </MainLayout2>
  );
}

export default Index;
