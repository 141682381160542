export const initialValuesLogin: LoginUser = {
  email: "",
  password: "",
};

export const otpValue: otpValueType = {
  otp: "",
};

export const forgotPasswordValueOne: forgotPasswordValueType = {
  email: "",
};

export const forgotPasswordValueTwo: forgotPasswordValueType = {
  favorite: "",
};

export const resetPasswordValue: resetPasswordType = {
  otp: "",
  password: "",
  restId: "",
  confirmPassword: "",
};

export const promotionValue: promotionType = {
  amount: "",
  status: "",
  paymentMethod: "",
  duration: "",
  name: "",
};

export const enrollmentValue: EnrollmentType = {
  amount: "",
  status: "",
  stage: "",
};

export const staffValue: Staff = {
  first_last: "",
  last_name: "",
  gender: "",
  email: "",
  password: "",
  admin_role: "",
};

export const creatorTypeValue: creatorsType = {
  name: "",
  status: "",
};

export const creatorCustomValue: creatorCustomDataType = {
  isRequired: "",
  dataType: "",
  status: "",
  name: "",
  description: "",
  validtionRequired: "",
  verifier: "",
};
