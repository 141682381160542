import { useEffect, useState } from "react";
import { MainLayout } from "../../../layouts/dashboardLayout/MainLayout";
import { Box, Table, Thead, Tbody, Tfoot, Tr, Th, TableContainer, useDisclosure, Td } from "@chakra-ui/react";
import { UseCustomAppColors } from "../../../utils/color";

import { CreatePromotion } from "./Create";
import { EditPromotion } from "./Edit";
import { InnerSearchHeader } from "@layouts/dashboardLayout/InnerSearchHeader";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import {
  resetEnrollmentMessage,
  resetEnrollmentStatus,
  selectEnrollment,
} from "@Redux/Slices/EnrollmentAndPromotion/EnrollmentSlice";
import { getEnrollment } from "@Redux/Services/EnrollmentAndPromotion/EnrollmentService";
import { useCustomToast } from "@utils/toast";

import { Font, Weight } from "@utils/fonts";
import { formatAndCapitalizeSlug, formatDateToCustomFormat } from "@utils/helper";

import PaginationLayout from "@layouts/dashboardLayout/PaginationLayout";

function Index() {
  const { inverseSecondBg, textColorInverse, textColor } = UseCustomAppColors();
  const { isOpen: createIsOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();

  const [filteredData, setFilteredData] = useState<enrollmentType[]>();
  const [enrollmentData, setEnrollmentsData] = useState<enrollmentType[]>();
  const [enrollmentId, setEnrollmentId] = useState<string | undefined>();
  const { notifySuccess } = useCustomToast();

  const dispatch = useAppDispatch();
  const handleFiltedChanges = (filteredData: enrollmentType[]) => setFilteredData(filteredData);

  const { isLoading, isSuccess, message, enrollments }: IEnrollments = useAppSelector(selectEnrollment);

  useEffect(() => {
    dispatch(resetEnrollmentMessage());
    dispatch(getEnrollment());
    dispatch(resetEnrollmentStatus());
  }, []);

  useEffect(() => {
    try {
      if (isSuccess && message) {
        message !== "" && notifySuccess(message!);
        dispatch(resetEnrollmentMessage());
      }
      if (enrollments) {
        setEnrollmentsData(enrollments);
      }
    } catch (error) {
      console.log(error);
    }
  }, [enrollments, isLoading]);

  // const handleEdit = (enrollmentId: string | undefined) => {
  //   setEnrollmentId(enrollmentId);
  //   if (enrollmentId) {
  //     editOnOpen();
  //   }
  // };

  // const handleStatusChange = async (enrollment: enrollmentType) => {
  //   try {
  //     setIsLoading(true);
  //     let changeStatus = await dispatch(
  //       updateEnrollment({
  //         status: enrollment?.status === "ACTIVE" ? "IN_ACTIVE" : "ACTIVE",
  //         id: enrollment?.id,
  //       })
  //     );

  //     if (changeStatus.meta.requestStatus === "fulfilled") {
  //       notifySuccess(changeStatus.payload.message);
  //       // dispatch(getPromotions());
  //       setIsLoading(false);
  //     }
  //     if (changeStatus.meta.requestStatus === "rejected") {
  //       notifySuccess(changeStatus.payload);
  //       setIsLoading(false);
  //     }
  //   } catch (error: any) {
  //     notifyError(error.message);
  //   }
  // };
  const handleSetEnrollment = () => {
    setEnrollmentId("");
  };

  // const handleDeleteEnrollment = async (enrollmentId: string | any) => {
  //   try {
  //     setIsLoading(true);
  //     let deleteEnroll = await dispatch(deleteEnrollment(enrollmentId));
  //     if (deleteEnroll.meta.requestStatus === "fulfilled") {
  //       notifySuccess(deleteEnroll.payload.message);
  //       // dispatch(getPromotions());

  //       console.log(deleteEnroll.meta);

  //       setIsLoading(false);
  //     }
  //     if (deleteEnroll.meta.requestStatus === "rejected") {
  //       notifySuccess(deleteEnroll.payload);
  //       setIsLoading(false);
  //     }
  //   } catch (error: any) {
  //     notifyError(error.message);
  //   }
  // };

  let enrollmentDataDisplyed = filteredData && filteredData?.length > 0 ? filteredData : enrollmentData;

  let enrollmentTableData =
    enrollments &&
    enrollmentDataDisplyed?.map((enrollment, index) => (
      <>
        <Tr key={`${index}_${enrollment?.stage}`}>
          <Td color={textColor} p={"1.5"}>
            {index + 1}
          </Td>
          <Td
            color={textColor}
            p={"1.5"}
            textTransform={"capitalize"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.SMALL}
          >
            {formatAndCapitalizeSlug(enrollment?.stage!)}
          </Td>
          <Td
            color={textColor}
            p={"1.5"}
            textTransform={"capitalize"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {formatAndCapitalizeSlug(enrollment?.amount!)}
          </Td>
          <Td
            color={textColor}
            p={"1.5"}
            textTransform={"capitalize"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {formatAndCapitalizeSlug(enrollment?.status!)}
          </Td>

          <Td
            color={textColor}
            p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {/* {CurrentTime(promotion?.createdAt)} */}
            {formatDateToCustomFormat(enrollment?.createdAt)}
          </Td>

          <Td
            color={textColor}
            p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          ></Td>
        </Tr>
      </>
    ));

  return (
    <MainLayout>
      <InnerSearchHeader
        onOpen={createOnOpen}
        title="Enrollment"
        isCreate={true}
        searches={["stage", "status", "amount"]}
        data={enrollmentData}
        setFilteredData={handleFiltedChanges}
      />
      <Box maxH={"100%"}>
        <TableContainer
          css={{
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
              borderRadius: "24px",
            },
          }}
        >
          <Table variant="simple" overflowY={"scroll"}>
            <Thead bg={inverseSecondBg} color={textColorInverse} p={"2"}>
              <Tr>
                <Th color={textColorInverse}>S/N</Th>
                <Th color={textColorInverse}>Stage</Th>
                <Th color={textColorInverse}>Amount</Th>
                <Th color={textColorInverse}>Status</Th>
                <Th color={textColorInverse}>Created At</Th>

                <Th color={textColorInverse}>Action</Th>
              </Tr>
            </Thead>
            <Tbody overflowX={"scroll"} minH={"100%"} alignItems={"flex-start"}>
              {enrollmentTableData}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
          <CreatePromotion
            isOpen={createIsOpen}
            onOpen={createOnOpen}
            onClose={createOnClose}
            tittle="Enrollment"
          />
          <EditPromotion
            isOpen={editIsOpen}
            onOpen={editOnOpen}
            onClose={editOnClose}
            tittle="Enrollment"
            enrollmentId={enrollmentId}
            setEnrollmentId={handleSetEnrollment}
          />
        </TableContainer>
      </Box>
      <PaginationLayout />
    </MainLayout>
  );
}

export default Index;
