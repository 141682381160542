import { createSlice } from "@reduxjs/toolkit";

import { createStaff, getStaffs, updateStaff } from "@Redux/Services/Staffs/StaffService";

const initialState: IStaff = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  staffs: [],
  totalRecords: 0,
};

const Staff = createSlice({
  name: `promotion`,
  initialState,
  reducers: {
    resetStaff: (state) => {
      state.staffs = [];
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    resetStaffStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    },
    resetStaffMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createStaff.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.totalRecords += 1;
        state.staffs.push(action.payload.data?.Staff);
      })
      .addCase(createStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(getStaffs.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStaffs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.staffs = action.payload.data?.staffs;
        state.totalRecords = action.payload.data?.totalRecord;
      })
      .addCase(getStaffs.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(updateStaff.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.staffs = state.staffs.filter((obj) => obj.id !== action.meta?.arg?.id);
        state.staffs.push(action.payload?.data?.staff);
      })
      .addCase(updateStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      });
  },
});

export const { resetStaff, resetStaffMessage, resetStaffStatus } = Staff.actions;

export const selectStaffs = (state: any) => state.staffStore;
export default Staff.reducer;
