import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Box,
  Button,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { CustomInputLabelAtLeft } from "@components/modals/CustomInputMain";
import { useAppDispatch } from "@Redux/hooks";

import { SlugUnderscores, formatAndCapitalizeSlug } from "@utils/helper";
import { getSettings, updateSetting } from "@Redux/Services/AppSetting/appsetting";
import { inputFontSize } from "@utils/fonts";
import { UseCustomAppColors } from "@utils/color";
import { useCustomToast } from "@utils/toast";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  editData: AppType;
};

type CreateType = {
  name: string | number;
  value: string | number;
};

export const AddNewAppType: React.FC<Props> = ({ isOpen, onClose, onOpen, editData }) => {
  const { inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const [appTypeData, setAppTypeData] = useState<AppType>();

  const [appTypeNewData, setAppTypeNewData] = useState<CreateType>({ name: "", value: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { notifyError, notifySuccess } = useCustomToast();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAppTypeNewData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const value =
      typeof +appTypeNewData.value === "number"
        ? appTypeNewData.value
        : SlugUnderscores(String(appTypeNewData.value));

    try {
      setIsLoading(true);
      const data = {
        id: appTypeData?.id!,
        type: appTypeData?.type!,
        name: appTypeData?.name!,
        value: {
          ...appTypeData?.value,
          [appTypeNewData.name.toString().trim()]: value,
        },
      };

      let addItem = await dispatch(updateSetting(data));

      if (addItem.meta.requestStatus === "fulfilled") {
        notifySuccess(addItem.payload.message);
        await dispatch(getSettings({}));
        setIsLoading(false);
        onClose();
      }
      if (addItem.meta.requestStatus === "rejected") {
        notifyError(addItem.payload);
        setIsLoading(false);
        onClose();
      }

      setAppTypeNewData({ name: "", value: "" });
    } catch (error) {}
  };

  useEffect(() => {
    setAppTypeData(editData!);
  }, [isOpen, editData]);

  return (
    <>
      {isOpen && appTypeData && (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay bg={"blackAlpha.200"} />
          <ModalContent>
            <ModalBody py={["1", "3", "14"]} px={["8", "14", "24"]}>
              <Box boxShadow="0px 0px 4px #b7b7b7" rounded="lg" px="14" pb={["5", "10"]}>
                <Heading py="4">{`Add ${formatAndCapitalizeSlug(appTypeData.name!)}`}</Heading>
                <Box mt={"5"} boxShadow={"base"}>
                  <VStack p={"2"}>
                    <CustomInputLabelAtLeft
                      label="Name"
                      name="name"
                      w={"75%"}
                      mb={"3"}
                      value={appTypeNewData?.name}
                      onChange={(e) => handleChange(e)}
                    />
                    <CustomInputLabelAtLeft
                      label="Value"
                      name="value"
                      w={"70%"}
                      value={appTypeNewData?.value}
                      onChange={(e) => handleChange(e)}
                    />
                  </VStack>
                </Box>
                <Flex justify={"flex-end"} mt={"6"}>
                  <Button
                    fontSize={[inputFontSize.base, inputFontSize.md]}
                    py={[".4rem", "1.5rem"]}
                    px={["1.8rem", "3.8rem"]}
                    mb={{ base: "2.5" }}
                    bg={inverseSecondBg}
                    color={textColorInverse}
                    borderRadius={"lg"}
                    borderWidth={".1rem"}
                    _hover={{ bg: inverseSecondBg }}
                    isLoading={isLoading}
                    loadingText="Submitting"
                    variant="outline"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </Flex>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
