import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Box,
  Text,
  Input,
  Stack,
  Select,
} from "@chakra-ui/react";
import { Formik } from "formik";
import {
  selectPromotion,
} from "@Redux/Slices/EnrollmentAndPromotion/PromotionSlice";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import {
  getPromotions,
  updatePromotion,
} from "@Redux/Services/EnrollmentAndPromotion/PromotionService";
import { UseCustomAppColors, inputBorderColor } from "@utils/color";
import { inputFontSize } from "@utils/fonts";

import { useCustomToast } from "@utils/toast";
import { promotionValidate } from "@Validation/CreatValidation";
import { selectConstants } from "@Redux/Slices/Constants/constantSlices";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  tittle: string;
  promotionId?: string;
  setPromotionId: () => void;
};

export const EditPromotion: React.FC<Props> = ({
  isOpen,
  onOpen,
  onClose,
  tittle,
  promotionId,
  setPromotionId,
}: Props) => {
  const dispatch = useAppDispatch();
  const { secondBg, inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const { notifyError, notifySuccess } = useCustomToast();

  const ConstantData = useAppSelector(selectConstants);
  const [promotionData, setPromotionData] = useState<promotionType | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isSuccess, promotions }: IPromotion = useAppSelector(selectPromotion);

  const [paymentMethod, setPaymentMethod] = useState<any>();
  const [statusData, setStatus] = useState<any>();

  useEffect(() => {
    setStatus(ConstantData.Status);
    setPaymentMethod(ConstantData.Paymentmethod);
    if (promotionId) {
      const filteredPromotion = promotions.find((promotion) => promotion.id === promotionId);
      // setPromotionId();
      setPromotionData(filteredPromotion);
    }
  }, [promotionId, isSuccess]);

  const handleFormSubmit = async (values: any, action: any) => {
    setIsLoading(true);
    try {
      console.log(values);

      const promoData = { ...values, id: promotionId };
      console.log(promoData);
      const promoEdit = await dispatch(updatePromotion(promoData));

      if (promoEdit.meta.requestStatus === "fulfilled") {
        notifySuccess(promoEdit.payload.message);
        console.log(promoEdit.meta);
        console.log(promoEdit.payload);

        dispatch(getPromotions());
        action.resetForm();
        setPromotionId();
        setIsLoading(false);
        onClose();
      }

      if (promoEdit.meta.requestStatus === "rejected") {
        setIsLoading(false);
        setPromotionId();
        notifyError(promoEdit.payload.message);
      }
    } catch (error) {
      // Handle error appropriately
    }
  };

  return (
    <>
      {isOpen && promotionData ? (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalBody py={["1", "3", "14"]} px={["8", "18", "48"]}>
              <Box bg={secondBg} boxShadow="0px 0px 4px #b7b7b7" rounded="lg" px="14" pb={["10", "20"]}>
                <Heading py="12">{`Edit ${tittle}`}</Heading>
                <Box bg="white" rounded="md">
                  <Formik
                    initialValues={{
                      amount: promotionData?.amount,
                      duration: promotionData?.duration,
                      paymentMethod: promotionData?.paymentMethod,
                      name: promotionData?.name,
                      status: promotionData?.status,
                    }}
                    validationSchema={promotionValidate}
                    onSubmit={handleFormSubmit}
                    enableReinitialize
                  >
                    {({ handleSubmit, errors, touched, handleChange, values, handleBlur }) => (
                      <form onSubmit={handleSubmit}>
                        <Stack spacing={"3"} flexDir={"column"}>
                          <Box>
                            <Input
                              width={"100%"}
                              focusBorderColor={inputBorderColor.focusBorderColor}
                              borderColor={inputBorderColor.borderColor}
                              height={["35px", "55px"]}
                              placeholder="Promotion Name"
                              // borderColor={textColor}
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              onChange={handleChange}
                              value={values.name}
                              onBlur={handleBlur}
                              name="name"
                            />
                            {errors.name && touched.name && <Text className="error">{errors.name}</Text>}
                          </Box>

                          <Box>
                            <Input
                              width={"100%"}
                              height={["30px", "50px"]}
                              placeholder="Amount"
                              focusBorderColor={inputBorderColor.focusBorderColor}
                              borderColor={inputBorderColor.borderColor}
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              onChange={handleChange}
                              value={values.amount}
                              type="number"
                              onBlur={handleBlur}
                              name="amount"
                            />
                            {errors.amount && touched.amount && (
                              <Text className="error">{errors.amount}</Text>
                            )}
                          </Box>

                          <Box>
                            <Input
                              width={"100%"}
                              height={["30px", "50px"]}
                              placeholder="Duration"
                              type="number"
                              focusBorderColor={inputBorderColor.focusBorderColor}
                              borderColor={inputBorderColor.borderColor}
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              onChange={handleChange}
                              value={values.duration}
                              onBlur={handleBlur}
                              name="duration"
                            />
                            {errors.duration && touched.duration && (
                              <Text className="error">{errors.duration}</Text>
                            )}
                          </Box>

                          <Box>
                            {/* <FormControl> */}
                            <Select
                              id="status"
                              name="status"
                              width={"100%"}
                              height={["30px", "50px"]}
                              placeholder="Select Status"
                              focusBorderColor={inputBorderColor.focusBorderColor}
                              borderColor={inputBorderColor.borderColor}
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                            >
                              {Object.keys(statusData!).map((key) => {
                                return (
                                  statusData[key]! !== undefined && (
                                    <option key={key} value={statusData[key]!}>
                                      {" "}
                                      {statusData[key]!}
                                    </option>
                                  )
                                );
                              })}
                            </Select>
                            {/* </FormControl> */}

                            {errors.status && touched.status && (
                              <Text className="error">{errors.status}</Text>
                            )}
                          </Box>

                          <Box>
                            {/* <FormControl> */}
                            <Select
                              id="paymentMethod"
                              name="paymentMethod"
                              width={"100%"}
                              height={["30px", "50px"]}
                              focusBorderColor={inputBorderColor.focusBorderColor}
                              borderColor={inputBorderColor.borderColor}
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              placeholder="Select Payment Method"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.paymentMethod}
                            >
                              {Object.keys(paymentMethod!).map((key) => {
                                return (
                                  paymentMethod[key]! !== undefined && (
                                    <option key={key} value={paymentMethod[key]!}>
                                      {" "}
                                      {paymentMethod[key] !== undefined && paymentMethod[key]!}
                                    </option>
                                  )
                                );
                              })}
                            </Select>
                            {/* </FormControl> */}

                            {errors.paymentMethod && touched.paymentMethod && (
                              <Text className="error">{errors.paymentMethod}</Text>
                            )}
                          </Box>
                          <Box
                            display={"flex"}
                            flexDir={["column", "row"]}
                            justifyContent={"space-between"}
                            my={"10"}
                          >
                            <Button
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              py={[".4rem", "1.5rem"]}
                              px={["1.8rem", "3.8rem"]}
                              mb={{ base: "2.5" }}
                              bg={inverseSecondBg}
                              color={textColorInverse}
                              borderRadius={"lg"}
                              borderWidth={".1rem"}
                              _hover={{ bg: inverseSecondBg }}
                              isLoading={isLoading}
                              loadingText="Submitting"
                              variant="outline"
                              type="submit"
                            >
                              Edit
                            </Button>
                          </Box>
                        </Stack>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
