import { createSlice } from "@reduxjs/toolkit";
import { AdminConstants, DefaultConstants } from "@Redux/Services/Constants/constantsService";

const initialState = {
  AdminConstants: {},
  defaultConstants: {},
};

const generalConstants = createSlice({
  name: `constants`,
  initialState,
  reducers: {
    reset: (state) => {
      state.AdminConstants = {};
      state.defaultConstants = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(AdminConstants.fulfilled, (state, action) => {
        state.AdminConstants = action.payload.data;
      })
      .addCase(DefaultConstants.fulfilled, (state, action) => {
        state.defaultConstants = action.payload.data;
      });
  },
});

export const { reset } = generalConstants.actions;

export const selectAdminConstants = (state: any) => state.constantStore.AdminConstants;
export const selectConstants = (state: any) => state.constantStore.defaultConstants;
export default generalConstants.reducer;
