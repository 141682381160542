import {
  Box,
  useColorMode,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
  Text,
} from "@chakra-ui/react";
import { UseCustomAppColors } from "@utils/color";

import { Link } from "react-router-dom";

import { protectedRoutes, protectedRoutesExtra } from "@utils/routes";

export function LeftSideBar() {
  const { colorMode } = useColorMode();
  const { textColorInverse } = UseCustomAppColors();

  return (
    <Box
      w={"100%"}
      display={["none", "none", "block", "block"]}
      minW={220}
      maxW={280}
      bg={textColorInverse}
      minH="calc(100vh - 8vh)"
      pt={["0", "0", "8", "10"]}
    >
      {" "}
      <Box>
        <Accordion allowToggle display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
          <Box>
            {protectedRoutes.map((Route, index) => (
              <>
                {Route.children?.length! > 0 ? (
                  <AccordionItem key={`${Route.path}_${index}`} my={"3"}>
                    {({ isExpanded }) => (
                      <>
                        <h2 style={{ fontSize: "30px" }}>
                          <AccordionButton
                            key={`${Route.path}_${index}`}
                            borderLeftWidth="6px"
                            borderColor={isExpanded ? "#042040" : "transparent"}
                            borderLeftColor={
                              isExpanded ? (colorMode === "light" ? "#042040" : "#FFFFFF") : "transparent"
                            }
                            p={"1"}
                          >
                            <Box
                              // flex="1"
                              textAlign="left"
                              alignItems={"center"}
                              px={"3"}
                              py={"1"}
                              pl={"6"}
                              width={"100%"}
                              display={"flex"}
                              flexDir={"row"}
                            >
                              <Icon
                                as={Route.icon}
                                fontSize={"3xl"}
                                // color={"#042040"}
                                // _dark={{ color: "#042040" }}
                                // _light={{ color: "#FFFFFF" }}
                              />{" "}
                              <Text pl={"4"} fontSize={"18px"}>
                                {" "}
                                {Route.parentName}
                              </Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} pl={""} key={`${Route.path}_${index}`}>
                          {Route.children?.map((innerRoute: routeList, innerIndex: number) => {
                            return innerRoute.show ? (
                              <Link to={innerRoute.path} key={`${innerRoute.name}_${innerIndex}`}>
                                <Box
                                  textAlign="left"
                                  alignItems={"center"}
                                  px={"3"}
                                  py={"1"}
                                  // pl={"6"}
                                  width={"100%"}
                                  display={"flex"}
                                  flexDir={"row"}
                                >
                                  <Icon
                                    as={innerRoute.icon}
                                    fontSize={"2xl"}
                                    color={colorMode === "light" ? "#042040" : "#FFFFFF"}
                                  />{" "}
                                  <Text pl={"4"} fontSize={"18px"}>
                                    {innerRoute.name}
                                  </Text>
                                </Box>
                              </Link>
                            ) : (
                              ""
                            );
                          })}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ) : (
                  <AccordionItem key={`${Route.path}_${index}`} my={"3"}>
                    {({ isExpanded }) => (
                      <>
                        <Link to={Route.path}>
                          <h2 style={{ fontSize: "30px" }}>
                            <AccordionButton
                              borderLeftWidth="6px"
                              borderColor={isExpanded ? "#042040" : "transparent"}
                              borderLeftColor={
                                isExpanded ? (colorMode === "light" ? "#042040" : "#FFFFFF") : "transparent"
                              }
                              p={"1"}
                            >
                              <Box
                                textAlign="left"
                                alignItems={"center"}
                                px={"3"}
                                py={"1"}
                                pl={"6"}
                                width={"100%"}
                                display={"flex"}
                                flexDir={"row"}
                              >
                                <Icon
                                  as={Route.icon}
                                  fontSize={"3xl"}
                                  color={colorMode === "light" ? "#042040" : "#FFFFFF"}
                                />{" "}
                                <Text pl={"4"} fontSize={"18px"}>
                                  {" "}
                                  {Route.name}
                                </Text>
                              </Box>
                            </AccordionButton>
                          </h2>
                        </Link>
                      </>
                    )}
                  </AccordionItem>
                )}
              </>
            ))}
          </Box>

          <Box mt={"4rem"}>
            {protectedRoutesExtra.map((extraRoute, index) => (
              <AccordionItem key={`${extraRoute.name}_${index}`}>
                {({ isExpanded }) => (
                  <>
                    <Link to={extraRoute.path}>
                      <h2 style={{ fontSize: "30px" }}>
                        <AccordionButton
                          borderLeftWidth="6px"
                          borderColor={isExpanded ? "#042040" : "transparent"}
                          borderLeftColor={
                            isExpanded ? (colorMode === "light" ? "#042040" : "#FFFFFF") : "transparent"
                          }
                          p={"1"}
                        >
                          <Box
                            textAlign="left"
                            alignItems={"center"}
                            px={"3"}
                            py={"1"}
                            pl={"6"}
                            width={"100%"}
                            display={"flex"}
                            flexDir={"row"}
                          >
                            <Icon
                              as={extraRoute.icon}
                              fontSize={"3xl"}
                              color={colorMode === "light" ? "#042040" : "#FFFFFF"}
                            />{" "}
                            <Text pl={"4"} fontSize={"18px"}>
                              {" "}
                              {extraRoute.name}
                            </Text>
                          </Box>
                        </AccordionButton>
                      </h2>
                    </Link>
                  </>
                )}
              </AccordionItem>
            ))}
          </Box>
        </Accordion>
      </Box>
      {/* <Stack>
        {  ex}
      </Stack> */}
    </Box>
  );
}
