import AuthLayout from "@layouts/authLayout/AppAuthlayout";
import "./auth.styles.css";
import { useEffect, useState } from "react";
import { AdminForgotPasswordState, forgotPasswordResetMessage } from "@Redux/Slices/Auth/resetPasswordSlices";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import { routePath } from "@utils/routes";
import { useCustomToast } from "@utils/toast";
import ForgotPasswordStageOne from "./forgotSteps/ForgotPasswordStageOne";
import ForgotPasswordStageTwo from "./forgotSteps/ForgotPasswordStageTwo";

export default function ForgotPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { notifyError, notifySuccess } = useCustomToast();
  const forgotPasswordState: IForgotPasswordState = useAppSelector(AdminForgotPasswordState);

  useEffect(() => {
    const handleForgotPassword = async () => {
      try {
        if (forgotPasswordState.isError) {
          setLoading(false);
          forgotPasswordState.message !== "" && notifyError(forgotPasswordState.message!);
          dispatch(forgotPasswordResetMessage());
        }

        if (forgotPasswordState.isSuccess && forgotPasswordState.AdminFavorite?.AdminFavorite !== null) {
          setLoading(false);
          setStep((step) => (step += 1));
        }

        if (forgotPasswordState.isSuccess && forgotPasswordState.resetParamsValue !== null) {
          setLoading(false);
          forgotPasswordState.message !== "" && notifySuccess(forgotPasswordState.message!);
          dispatch(forgotPasswordResetMessage());
          navigate(routePath.reset_password);
        }
      } catch (error) {
        console.log(error as TypeError);
      }
    };
    handleForgotPassword();
  }, [forgotPasswordState]);

  let Display =
    step === 0 ? (
      <ForgotPasswordStageOne loading={loading} setLoading={setLoading} />
    ) : (
      <ForgotPasswordStageTwo
        favoriteName={forgotPasswordState.AdminFavorite?.AdminFavorite ?? null}
        loading={loading}
        setLoading={setLoading}
      />
    );
  return <AuthLayout>{Display}</AuthLayout>;
}
