import React, { useEffect, useState } from "react";
import { MainLayout } from "../../../layouts/dashboardLayout/MainLayout";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  HStack,
  TableContainer,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Button,
  Portal,
  Flex,
  Text,
  Switch,
  Td,
} from "@chakra-ui/react";
import { UseCustomAppColors } from "../../../utils/color";

import { CreatePromotion } from "./Create";
import { EditPromotion } from "./Edit";
import { InnerSearchHeader } from "@layouts/dashboardLayout/InnerSearchHeader";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";

import {
  deleteCreatorType,
  getCreatorType,
  getCustomCreatorTypeData,
  updateCreatorTypeStatus,
} from "@Redux/Services/Creators/creatorsService";
import { useCustomToast } from "@utils/toast";

import { Font, Weight } from "@utils/fonts";
import { formatAndCapitalizeSlug, formatDateToCustomFormat } from "@utils/helper";
import { MdDeleteForever } from "react-icons/md";


import {
  resetCreatorsMessage,
  resetCreatorsStatus,
  selectCreators,
} from "@Redux/Slices/creator/creatorSlice";

import { routePath } from "@utils/routes";
import { useNavigate } from "react-router-dom";

function Index() {
  const { inverseSecondBg, textColorInverse, textColor } = UseCustomAppColors();
  const { isOpen: createIsOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<creatorsType[]>();
  const [creatorsData, setCreatorsData] = useState<creatorsType[]>();
  const [creatorId, setCreatorId] = useState<string | undefined>();
  const { notifyError, notifySuccess } = useCustomToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const handleFiltedChanges = (filteredData: creatorsType[]) => setFilteredData(filteredData);

  const {  isSuccess, message, creators }: ICreator = useAppSelector(selectCreators);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetCreatorsMessage());
    dispatch(getCreatorType());
    dispatch(resetCreatorsStatus());
  }, []);

  useEffect(() => {
    try {
      if (isSuccess && message) {
        message !== "" && notifySuccess(message!);
        dispatch(resetCreatorsMessage());
      }
      if (creators) {
        setCreatorsData(creators);
      }
    } catch (error) {
      console.log(error);
    }
  }, [creators, isLoading]);

  const handleStatusChange = async (creator: creatorsType) => {
    try {
      setIsLoading(true);
      let changeStatus = await dispatch(
        updateCreatorTypeStatus({
          status: creator?.status ? false : true,
          id: creator?.id,
        })
      );

      if (changeStatus.meta.requestStatus === "fulfilled") {
        notifySuccess(changeStatus.payload.message);
        setIsLoading(false);
      }
      if (changeStatus.meta.requestStatus === "rejected") {
        notifyError(changeStatus.payload);
        setIsLoading(false);
      }
    } catch (error: any) {
      notifyError(error.message);
    }
  };
  const handleSetEnrollment = () => {
    setCreatorId("");
  };

  const handleDeleteCreator = async (creatorTypeName: string | any) => {
    try {
      setIsLoading(true);
      let deleteCreator = await dispatch(deleteCreatorType({ id: creatorTypeName }));

      if (deleteCreator.meta.requestStatus === "fulfilled") {
        notifySuccess(deleteCreator.payload.message);
        setIsLoading(false);
      }
      if (deleteCreator.meta.requestStatus === "rejected") {
        notifyError(deleteCreator.payload);
        setIsLoading(false);
      }
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  const getCustomCreatorType = async (creator: creatorsType) => {
    dispatch(getCustomCreatorTypeData(creator?.id!));
    navigate(`${routePath.creatorCustomType}${creator.name}`);
  };

  let creatorsDataDisplyed = filteredData && filteredData?.length > 0 ? filteredData : creatorsData;

  let creatorTableData = creatorsDataDisplyed?.map((creator, index) => (
    <>
      <Tr key={`${index}_${creator?.name}`}>
        <Td color={textColor} p={"1.5"}>
          {index + 1}
        </Td>

        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
          textTransform={"capitalize"}
          onClick={() => getCustomCreatorType(creator)}
        >
          {formatAndCapitalizeSlug(creator?.name!)}
        </Td>

        <Td
          color={textColor}
          p={"1.5"}
          textTransform={"capitalize"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {creator?.status === "1" ? "true" : creator?.status === "0" ? "false" : `${creator?.status}`}
        </Td>

        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {/* {CurrentTime(promotion?.createdAt)} */}
          {formatDateToCustomFormat(creator?.createdAt)}
        </Td>

        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          <Popover placement="left-start">
            <PopoverTrigger>
              <Button variant={"unstyled"}>Action</Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent w={["140px", "150px", "180px"]}>
                <PopoverArrow />

                <PopoverBody boxShadow={"0px 0px 4px #808080"} rounded={["md", "md", "2xl"]}>
                  <Flex flexDir={"column"}>
                    {/* <Button colorScheme="gray" variant={"unstyled"}>
                      <HStack justifyContent={"space-between"}>
                        <Text mr={["5px", "6px"]}>Name</Text>
                        <Button onClick={() => handleEdit(creator.id)} variant={"unstyled"}>
                          {" "}
                          <FiEdit />
                        </Button>
                      </HStack>
                    </Button> */}
                    <Button colorScheme="gray" variant={"unstyled"}>
                      <HStack justifyContent={"space-between"}>
                        <Text mr={["4px"]}>Delete</Text>
                        <Button onClick={() => handleDeleteCreator(creator.id)} variant={"unstyled"}>
                          {" "}
                          <MdDeleteForever fontSize={"1.4rem"} color="red" />
                        </Button>
                      </HStack>
                    </Button>

                    <Button colorScheme="gray" variant={"unstyled"}>
                      <HStack justifyContent={"space-between"}>
                        <Text mr={["5px", "6px"]}>Status</Text>
                        <Switch
                          size="lg"
                          isChecked={creator.status ? true : false}
                          onChange={() => handleStatusChange(creator)}
                        />
                      </HStack>
                    </Button>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Td>
      </Tr>
    </>
  ));

  return (
    <MainLayout>
      <InnerSearchHeader
        onOpen={createOnOpen}
        title="Creator Types"
        isCreate={true}
        searches={["nmae", "status"]}
        data={creatorsData}
        setFilteredData={handleFiltedChanges}
      />
      <Box maxH={"100%"} minH={"72vh"}>
        <TableContainer
          css={{
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
              borderRadius: "24px",
            },
          }}
        >
          <Table variant="simple" overflowY={"scroll"}>
            <Thead bg={inverseSecondBg} color={textColorInverse} p={"2"}>
              <Tr>
                <Th color={textColorInverse}>S/N</Th>
                <Th color={textColorInverse}>Name</Th>
                <Th color={textColorInverse}>Status</Th>
                <Th color={textColorInverse}>Created At</Th>
                <Th color={textColorInverse}>Action</Th>
              </Tr>
            </Thead>
            <Tbody overflowX={"scroll"} minH={"100%"} alignItems={"flex-start"}>
              {creatorTableData}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
          <CreatePromotion
            isOpen={createIsOpen}
            onOpen={createOnOpen}
            onClose={createOnClose}
            tittle="Create Type"
          />
          <EditPromotion
            isOpen={editIsOpen}
            onOpen={editOnOpen}
            onClose={editOnClose}
            tittle="Create Type"
            enrollmentId={creatorId}
            setEnrollmentId={handleSetEnrollment}
          />
        </TableContainer>
      </Box>
      {/* <PaginationLayout /> */}
    </MainLayout>
  );
}

export default Index;
