import React, { useEffect, useState } from "react";

import { Box, Table, Thead, Tbody, Tfoot, Tr, Th, TableContainer, useDisclosure, Td } from "@chakra-ui/react";
import { UseCustomAppColors } from "../../../utils/color";

import { useAppSelector } from "@Redux/hooks";

import { Font, Weight } from "@utils/fonts";
import { capitalize, formatAndCapitalizeSlug, formatDateToCustomFormat } from "@utils/helper";

import { selectCreators } from "@Redux/Slices/creator/creatorSlice";

import { CreateCustomCreator } from "./CreatorCustomCreate";
import { useParams } from "react-router-dom";
import { InnerSearchHeaderForCustoms } from "@layouts/dashboardLayout/InnerSearchHeaderForCustoms";
import { TableList } from "@Skeleton/services";

function Index() {
  const { inverseSecondBg, secondBg, textColorInverse, textColor } = UseCustomAppColors();
  const { isOpen: createIsOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();

  const [filteredData, setFilteredData] = useState<CreatorCustomData[]>();
  const [creatorCustomData, setCreatorCustomData] = useState<CreatorCustomData[]>();

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  let { name } = useParams();

  const handleFiltedChanges = (filteredData: CreatorCustomData[]) => setFilteredData(filteredData);

  const { creatorCustom, isLoading }: ICreator = useAppSelector(selectCreators);

  useEffect(() => {
    setCreatorCustomData(creatorCustom);
  }, [creatorCustom]);

  useEffect(() => {
    try {
      if (creatorCustom) {
        setCreatorCustomData(creatorCustom);
      }
    } catch (error) {
      console.log(error);
    }
  }, [creatorCustom, isLoading]);

  let creatorsDataDisplyed = filteredData && filteredData?.length > 0 ? filteredData : creatorCustomData;
  let creatorTableData = creatorsDataDisplyed?.map((creatorCustom, index) => (
    <>
      <Tr key={`${index}_${creatorCustom?.name}`}>
        <Td color={textColor} textTransform={"capitalize"} p={"3.5"}>
          {index + 1}
        </Td>

        <Td
          color={textColor}
          p={"3.5"}
          textTransform={"capitalize"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {formatAndCapitalizeSlug(creatorCustom.name!)}
        </Td>

        <Th
          color={textColor}
          p={"3.5"}
          textTransform={"capitalize"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {creatorCustom?.status === "1"
            ? "True"
            : creatorCustom?.status === "0"
            ? "False"
            : `${creatorCustom?.status}`}
        </Th>

        <Th
          color={textColor}
          p={"3.5"}
          textTransform={"capitalize"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {`${creatorCustom?.isRequired}`}
        </Th>
        <Th
          color={textColor}
          p={"3.5"}
          textTransform={"capitalize"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {`${formatAndCapitalizeSlug(creatorCustom?.verifier!)}`}
        </Th>

        <Th
          color={textColor}
          p={"3.5"}
          textTransform={"capitalize"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {creatorCustom?.dataType}
        </Th>
        <Th
          color={textColor}
          textTransform={"capitalize"}
          p={"3.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {creatorCustom?.validtionRequired === "1"
            ? "True"
            : creatorCustom?.validtionRequired === "0"
            ? "False"
            : `${creatorCustom?.validtionRequired}`}
        </Th>
        <Th
          color={textColor}
          p={"3.5"}
          textTransform={"capitalize"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {creatorCustom?.description}
        </Th>

        <Th
          color={textColor}
          p={"3.5"}
          textTransform={"capitalize"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {formatDateToCustomFormat(creatorCustom?.createdAt)}
        </Th>
      </Tr>
    </>
  ));

  return (
    <Box w={"100%"} minH={"inherit"} pt={"5"} px={"3"} my={"5"} rounded={"md"} bg={secondBg}>
      <InnerSearchHeaderForCustoms
        onOpen={createOnOpen}
        title="Custom Data"
        customCreatorName={capitalize(name!)}
        isCreate={true}
        searches={["name", "status"]}
        data={creatorCustomData}
        setFilteredData={handleFiltedChanges}
      />
      <Box maxH={"100%"}>
        <TableContainer
          css={{
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
              borderRadius: "24px",
            },
          }}
        >
          <Table variant="simple" overflowY={"scroll"}>
            <Thead bg={inverseSecondBg} color={textColorInverse} p={"2"}>
              <Tr>
                <Th color={textColorInverse}>S/N</Th>
                <Th color={textColorInverse}>Name</Th>
                <Th color={textColorInverse}>Status</Th>
                <Th color={textColorInverse}>Is Required</Th>
                <Th color={textColorInverse}>Verifier</Th>
                <Th color={textColorInverse}>Data Type</Th>
                <Th color={textColorInverse}>Validtion Required</Th>
                <Th color={textColorInverse}>Description</Th>
                <Th color={textColorInverse}>Created At</Th>
              </Tr>
            </Thead>
            <Tbody overflowX={"scroll"} minH={"100%"} alignItems={"flex-start"}>
              {creatorTableData ? creatorTableData : <TableList />}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
          <CreateCustomCreator
            isOpen={createIsOpen}
            onOpen={createOnOpen}
            onClose={createOnClose}
            tittle="Create Type"
          />
          {/* <EditPromotion
            isOpen={editIsOpen}
            onOpen={editOnOpen}
            onClose={editOnClose}
            tittle="Create Type"
            enrollmentId={creatorId}
            setEnrollmentId={handleSetEnrollment}
          /> */}
        </TableContainer>
      </Box>
      {/* <PaginationLayout /> */}
    </Box>
  );
}

export default Index;
