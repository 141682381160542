import { SetPayload } from "@utils/helper";
import config from "@utils/requestConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createStaff = createAsyncThunk("create-staff", async (staffData: any, thunkAPI) => {
  const payload = SetPayload(staffData);
  try {
    const Response = await config.post(`admin/add/staff`, payload);

    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const getStaffs = createAsyncThunk(
  "get-staff",
  async (data: { page: number; staffType: string }, thunkAPI) => {
    try {
      const payload = { page: data.page, staffType: data.staffType, limit: 50 };
      const Response = await config.get(`admin/get/staffs`, payload);
      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const deleteSa = createAsyncThunk("delete-promotion", async (promotionId: string, thunkAPI) => {
//   try {
//     const Response = await config.delete(`admin/delete/promotion/${promotionId}`);
//     return Response;
//   } catch (error: any) {
//     console.log(error);
//     const message =
//       (error.response && error.response.data && error.response.data.message) ||
//       error.message ||
//       error.toString();
//     console.log(message);
//     return thunkAPI.rejectWithValue(message);
//   }
// });

export const updateStaff = createAsyncThunk("update-staff-status", async (promotionData: any, thunkAPI) => {
  try {
    const payload = SetPayload(promotionData);
    const Response = await config.patch(`admin/update/staff/status`, payload);

    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});
