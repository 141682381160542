import { Box, Flex, Text } from "@chakra-ui/react";

export function NotFound() {
  return (
    <Flex justifyContent={"center"} alignItems={"center"} h={"100vh"}>
      <Box textAlign="center" m={"auto"}>
        <Text as={"h1"} fontSize={["1rem", "4rem", "8rem"]} fontWeight={"extrabold"}>
          404
        </Text>
        <Text pl={"4"} fontSize={"2rem"}>
          Page not found
        </Text>
      </Box>
    </Flex>
  );
}
