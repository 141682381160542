import config from "@utils/requestConfig";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { SetPayload } from "@utils/helper";

export const AdminLoginStageOne = createAsyncThunk(
  "auth/admin-login-stage-one",
  async (userData: any, thunkAPI) => {
    const payload = SetPayload(userData);
    console.log(payload);

    try {
      const Response = await config.post(`auth/admin/login`, payload);
      console.log(Response);
      return Response;
    } catch (error: any) {
      console.log(error);

      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AdminLoginStageTwo = createAsyncThunk(
  "auth/admin-login-stage-two",
  async (verifyData: any, thunkAPI) => {
    const payload = SetPayload(verifyData);
    try {
      const Response = await config.post(`auth/admin/login/verify`, payload);
      console.log(Response);
      return Response;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPasswordStageOne = createAsyncThunk(
  "forgotpassword/admin-login-stage-one",
  async (forgotData: any, thunkAPI) => {
    // const payload: object = SetPayload(forgotData);
    try {
      const Response = await config.get(`auth/admin/forgotpassword`, forgotData);
      console.log(Response);
      return Response;
    } catch (error: any) {
      console.log(error);

      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPasswordStageTwo = createAsyncThunk(
  "forgotpassword/forgot-password-stage-two",
  async (verifyData: any, thunkAPI) => {
    const payload = SetPayload(verifyData);
    try {
      const Response = await config.post(`auth/admin/forgotpassword`, payload);
      console.log(Response);
      return Response;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "forgotpassword/reset-password",
  async (resetPasswordData: any, thunkAPI) => {
    console.log(resetPasswordData);
    const { confirmPassword, restId, ...resetData } = resetPasswordData;
    console.log(restId);

    console.log(resetData);
    const payload = SetPayload(resetData);
    try {
      const Response = await config.patch(`auth/admin/resetpassword/${restId}`, payload);
      console.log(Response);
      return Response;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
