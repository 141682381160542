import { Box, BoxProps, Flex, Stack } from "@chakra-ui/react";

import { LeftSideBar } from "./LeftSideBar";

import { MobileHeader } from "./MobileHeader";
import { UseCustomAppColors } from "@utils/color";

import { MainHeader } from "./MainHeader";

interface MainLayoutProps extends BoxProps {
  HideLeftSideBar?: boolean;
}

export const MainLayout2 = ({ children, HideLeftSideBar = true, ...rest }: MainLayoutProps) => {
  const { bg } = UseCustomAppColors();

  return (
    <Box overflowX={"hidden"} bg={bg}>
      <MobileHeader />
      <MainHeader />
      <Flex bg={"#F5F5F5"} w={"100%"} h="calc(100vh - 8vh)">
        {HideLeftSideBar && <LeftSideBar />}
        <Box h={"100%"} flex={1} {...rest} overflowX={"hidden"}>
          <Stack h={"100%"} pb={3} overflow={"auto"}>
            <Box w={"100%"} minH={"inherit"} pt={"5"} px={"6"} my={"5"} rounded={"md"} bg={"transparent"}>
              {children}
            </Box>
          </Stack>
        </Box>
      </Flex>
      {/* <DashFooter /> */}
    </Box>
  );
};
