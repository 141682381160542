import * as yup from "yup";

export const loginSchema = yup.object({
  email: yup.string().email("Invalid email address").required("Email is required"),
  password: yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
});

export const otpSchema = yup.object({
  otp: yup.number().required("OTP is required"),
});

export const forgortPasswordSchemaOne = yup.object({
  email: yup.string().email("Invalid email address").required("Email is required"),
});

export const forgortPasswordSchemaTwo = yup.object({
  favorite: yup.string().required("Favorite is required"),
});

export const resetPasswordSchema = yup.object({
  password: yup.string().min(8).required("Password is required"),
  confirmPassword: yup
    .string()
    .min(8)
    .required("Please retype your password.")
    .oneOf([yup.ref("password")], "Your passwords do not match."),
});
