import moment from "moment";

const formatter = new Intl.NumberFormat();

export const numberFormatter = (num: number) => {
  return formatter.format(num);
};

export const momentsAgo = (date: string, noPrefix = false) => {
  return moment.utc(`${date}`).local().startOf("seconds").fromNow(noPrefix);
};

export const capitalizeFirstLetter = (str: string | undefined) => {
  let item = "";
  if (str === undefined) return item;
  const regex = /^\w+$/;
  const isNotMoreThanOneWord = regex.test(str);
  if (isNotMoreThanOneWord) {
    item = str;
  } else {
    item = str.toLowerCase().replace(/\b[a-z]/g, function (letter: string) {
      return letter.toUpperCase();
    });
  }
  return item;
};

export const CurrentTime = () => {
  return moment(new Date()).format("MMMM D YYYY, h:mm a");
};

export const FormattedTime = (value: string) => {
  return moment(value).format("h:mm a");
};

export const SetPayload = (value: { [key: string]: boolean | string | number | object | [] }) => {
  return { data: value };
};

export function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0;
}

export function formatDateToCustomFormat(date: any) {
  const momentDate = moment(date);
  const formattedDate = momentDate.format("DD/MM/YYYY");
  return formattedDate;
}

export function SlugUnderscores(input: string) {
  return input
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, " ")
    .replace(/-+/g, "-")
    .replace(/-/g, "_")
    .toUpperCase();
}

export function onlySlugUnderscores(input: string) {
  return input
    .toString()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, " ")
    .replace(/-+/g, "-")
    .replace(/-/g, "_");
}

export function formatAndCapitalizeSlug(input: string) {
  return input
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, " ")
    .replace(/-+/g, "-")
    .replace(/-/g, "_")
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

export function capitalize(string: string | undefined) {
  if (string === undefined) return "";
  const formated = string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  return formated;
}
