import { createSlice } from "@reduxjs/toolkit";
import {
  createEnrollment,
  deleteEnrollment,
  getEnrollment,
  updateEnrollment,
} from "@Redux/Services/EnrollmentAndPromotion/EnrollmentService";


const initialState: IEnrollments = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  enrollments: [],
};

const promotionEarning = createSlice({
  name: `enrollment`,
  initialState,
  reducers: {
    resetEnrollment: (state) => {
      state.enrollments = [];
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    resetEnrollmentStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    },
    resetEnrollmentMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createEnrollment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createEnrollment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.enrollments.push(action.payload?.data?.Enrollment);
      })
      .addCase(createEnrollment.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(getEnrollment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEnrollment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.enrollments = action.payload.data?.Enrollments;
      })
      .addCase(getEnrollment.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(deleteEnrollment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteEnrollment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.enrollments = state.enrollments.filter((obj) => obj.id !== action.meta.arg);
      })
      .addCase(deleteEnrollment.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(updateEnrollment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateEnrollment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.enrollments = state.enrollments.filter((obj) => obj.id !== action.meta?.arg?.id);
        state.enrollments.push(action.payload?.data?.Enrollment);
      })
      .addCase(updateEnrollment.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      });
  },
});

export const { resetEnrollment, resetEnrollmentMessage, resetEnrollmentStatus } = promotionEarning.actions;

export const selectEnrollment = (state: any) => state.enrollmentStore;
export default promotionEarning.reducer;
