import { useAppDispatch } from "@Redux/hooks";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { updateSetting, getSettings } from "@Redux/Services/AppSetting/appsetting";
import { useCustomToast } from "@utils/toast";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onDelete: () => void;
  itemToRemove: AppType;
  valueToRemove: any;
}

export const RemoveItemTypeItem: React.FC<Props> = ({
  isOpen,
  onClose,
  onDelete,
  valueToRemove,
  itemToRemove,
}: Props) => {
  const [appTypeData, setAppTypeData] = useState<AppType | null>();
  const { notifyError, notifySuccess } = useCustomToast();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setAppTypeData(itemToRemove);
  }, [itemToRemove]);
  const handleDeleteRange = async (
    appTypeData: AppType,
    valueToRemove: string,
    onDelete: () => void,
    onClose: () => void
  ) => {
    try {
      const editedType = appTypeData?.value
        ? Object.entries(appTypeData?.value)
            .filter(([key, value]) => {
              return key !== valueToRemove;
            })
            .reduce((acc: any, [k, v]) => ((acc[k] = v), acc), {})
        : null;

      const updateType = {
        id: appTypeData.id,
        name: appTypeData.name,
        type: appTypeData.type,
        value: editedType,
      };

      let removeItem = await dispatch(updateSetting(updateType));
      if (removeItem.meta.requestStatus === "fulfilled") {
        notifySuccess(removeItem.payload.message);

        await dispatch(getSettings({}));
        onDelete();
        onClose();
      }
      if (removeItem.meta.requestStatus === "rejected") {
        notifyError(removeItem.payload);
        onDelete();
        onClose();
      }
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg={"blackAlpha.200"} />
      <ModalContent>
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete {valueToRemove}?</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => handleDeleteRange(appTypeData!, valueToRemove, onDelete, onClose)}
          >
            Delete
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
