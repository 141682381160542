export enum Font {
  PRIMARY = "14px",
  SECONDARY = "24px",
  MIN_SECONDARY = "18px",
  MAIN_PRIMARY = "20px",
  FORM_PRIMARY_ERROR = "10px",
  FORM_SECONDARY_ERROR = "16px",
}

export enum Weight {
  MIN_SMALL = "400",
  SMALL = "600",
  MEDIUM = "700",
  LARGE = "900",
}

export const inputFontSize = { base: "16px", md: "1.2rem" };
