import {
  Image,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Divider,
  Center,
  ModalHeader,
} from "@chakra-ui/react";

type NotificationTypes = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  // notifications?: NotificationType[] | undefined;
  details?: [] | undefined;
  isLoading: boolean;
};

export function ProfileMoadal({ isOpen, onClose, onOpen, details }: NotificationTypes) {
  return (
    <Box w={"1rem"}>
      <Modal size={"xs"} onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
        {/* <ModalOverlay /> */}
        <ModalContent position={"absolute"} right={"1.2rem"} top={".4rem"} boxShadow={"md"}>
          <Center>
            <ModalHeader p={"2.5"}> Profile</ModalHeader>
          </Center>
          <Divider variant={"solid"} borderWidth={["thin", "thin", "1.8px", "2px"]} />
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Box maxW={"3.8rem"}>
                <Image borderRadius="full" src="https://bit.ly/dan-abramov" alt="Profile" />
              </Box>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
