import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setCredentials } from "./Redux/Slices/Auth/authSlices";

export const NoAccessRoute = () => {
  // const user: AuthData = useSelector(selectCurrentUser);

  const userFromLocalStorage = window.localStorage.getItem("userInfo");
  if (userFromLocalStorage === null) {
    return <Outlet />;
  }
  const token = window.localStorage.getItem("userInfo");
  const gottenToken: AuthData | null = token ? JSON.parse(token!) : <Navigate to={"/auth/admin/login"} />;
  const auth: string = gottenToken?.access_token!;
  return !auth ? <Outlet /> : <Navigate to={"/admin/dashboard"} />;
};

export const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const user: AuthData = useSelector(selectCurrentUser);

  if (user === null || user?.Admin === null || user?.access_token === null) {
    if (window.localStorage.getItem("userInfo") !== null) {
      const userInfo: AuthData = JSON.parse(window.localStorage?.getItem("userInfo")!);
      dispatch(setCredentials(userInfo));
      return <Outlet />;
    } else {
      return <Navigate to={`/auth/admin/login?redirect=${window.location.pathname}`} />;
    }
  }
  return <Outlet />;
};
