import { createSlice } from "@reduxjs/toolkit";
import {
  createPromotion,
  deletePromotion,
  updatePromotion,
  getPromotions,
} from "@Redux/Services/EnrollmentAndPromotion/PromotionService";

const initialState: IPromotion = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  promotions: [],
};

const promotionEarning = createSlice({
  name: `promotion`,
  initialState,
  reducers: {
    resetPromotion: (state) => {
      state.promotions = [];
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    resetPromotionStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    },
    resetPromotionMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createPromotion.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createPromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        console.log(action.payload.data);

        // state.promotions.push();
      })
      .addCase(createPromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(getPromotions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPromotions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.promotions = action.payload.data?.Promotions;
      })
      .addCase(getPromotions.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(deletePromotion.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deletePromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.promotions = state.promotions.filter((obj) => obj.id !== action.meta.arg);
      })
      .addCase(deletePromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(updatePromotion.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePromotion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        console.log(action.payload.data?.Promotions);

        state.promotions = action.payload.data?.Promotions;
      })
      .addCase(updatePromotion.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      });
  },
});

export const { resetPromotion, resetPromotionMessage, resetPromotionStatus } = promotionEarning.actions;

export const selectPromotion = (state: any) => state.promotionStore;
export default promotionEarning.reducer;
