import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  useDisclosure,
  useColorMode,
  Drawer,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";

import { NotificationMoadal } from "./NotificationMoadal";

import { IoMdNotifications } from "react-icons/io";
import { UseCustomAppColors } from "@utils/color";
import { ProfileMoadal } from "./ProfileMoadal";
import { FaBars } from "react-icons/fa";
import { IoMoon } from "react-icons/io5";
import { MdOutlineWbSunny } from "react-icons/md";
import { MobileLeftSideBar } from "./MobileLeftSideBar";

export function MobileHeader() {
  const { textColor, HeaderColor } = UseCustomAppColors();
  const {
    isOpen: isOpenNotification,
    onClose: onCloseNotification,
    onOpen: onOpenNotification,
  } = useDisclosure();

  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen: isOpenProfile, onClose: onCloseProfile, onOpen: onOpenProfile } = useDisclosure();
  const { isOpen: isMenuOpen, onOpen: openMenu, onClose: closeMenu } = useDisclosure();
  return (
    <Box
      maxH={80}
      display={["block", "block", "none", "none"]}
      borderColor={"blue.900"}
      borderWidth={"thin"}
      // p={"3.5"}
    >
      <Flex flexDir={"row"} alignItems={"center"} justifyContent={"space-between"} h={70}>
        <Box p={"2.5"}>
          {/* MENU DRAWER */}
          <Button variant={"unstyled"} onClick={openMenu}>
            <Icon as={FaBars} fontSize={"3xl"} color={textColor} />
          </Button>
          <Drawer placement={"left"} onClose={closeMenu} isOpen={isMenuOpen}>
            <DrawerOverlay />
            <DrawerContent pb={10} overflowY={"auto"}>
              <MobileLeftSideBar isOpen={isMenuOpen} handleClose={closeMenu} />
            </DrawerContent>
          </Drawer>
        </Box>

        <Flex maxW={"72"} h={"100%"} alignItems={"center"} justifyContent={"space-evenly"}>
          <Box>
            <Button variant={"unstyled"} onClick={toggleColorMode}>
              {colorMode === "light" ? (
                <Icon as={MdOutlineWbSunny} fontSize={"2xl"} color={textColor} />
              ) : (
                <Icon as={IoMoon} fontSize={"2xl"} color={textColor} />
              )}
            </Button>
          </Box>
          <Box>
            <Button
              variant={"unstyled"}
              onClick={onOpenNotification}
              _hover={{ bg: "transparent", color: { HeaderColor } }}
              aria-label="Notifications"
            >
              <Icon as={IoMdNotifications} fontSize={"2xl"} color={textColor} />
            </Button>
          </Box>
          <NotificationMoadal
            isOpen={isOpenNotification}
            onClose={onCloseNotification}
            onOpen={onOpenNotification}
            isLoading={false}
            notifications={[]}
          />

          <Box w={"2.3rem"} position={"relative"}>
            <Image
              onClick={onOpenProfile}
              borderRadius="full"
              src="https://bit.ly/dan-abramov"
              alt="Profile"
            />
          </Box>
          <ProfileMoadal
            isOpen={isOpenProfile}
            onClose={onCloseProfile}
            onOpen={onOpenProfile}
            isLoading={false}
            details={[]}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
