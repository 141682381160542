import { useState, useEffect } from "react";
import AuthLayout from "@layouts/authLayout/AppAuthlayout";
import { UseCustomAppColors } from "@utils/color";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Flex, Icon, Input, InputGroup, InputRightElement, Stack, Text } from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";

import { initialValuesLogin } from "@Schema/schema";
import { loginSchema } from "@Validation/authValidation";
import { Weight, inputFontSize } from "@utils/fonts";
import "./auth.styles.css";
import { routePath } from "@utils/routes";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import { AdminLoginStageOne } from "@Redux/Services/Auth/authSevices";
import { AdminAuthStageOne, resetMessage, resetAuthStore, resetStatus } from "@Redux/Slices/Auth/authSlices";
import { useCustomToast } from "@utils/toast";

export default function Login() {
  const { inverseSecondBg, secondBg, textColor, textColorInverse, HeaderColor } = UseCustomAppColors();
  const { notifyError, notifySuccess } = useCustomToast();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const AuthStageOne: IAuthState = useAppSelector(AdminAuthStageOne);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      dispatch(resetAuthStore());
    } catch (error) {
      console.error(error, "error");
    }
  }, []);

  // useEffect(() => {
  //   try {
  //     if (AuthStageOne?.isSuccess && AuthStageOne.authStageOne !== null && AuthStageOne?.authStageOne?.Otp) {
  //       setLoading(false);
  //       AuthStageOne?.message !== "" && notifySuccess(AuthStageOne?.message!);
  //       dispatch(resetMessage());
  //       dispatch(resetStatus());
  //       navigate(routePath.verify_otp);
  //     }

  //     if (AuthStageOne?.isError && AuthStageOne?.message) {
  //       setLoading(false);
  //       AuthStageOne?.message !== "" && notifyError(AuthStageOne?.message);
  //       dispatch(resetMessage());
  //     }
  //   } catch (error) {
  //     console.error(error, "error");
  //     notifyError(error as string);
  //   }
  // }, [AuthStageOne.authStageOne?.Otp, AuthStageOne.isError, AuthStageOne.isSuccess]);

  const AdminLogin = useFormik({
    initialValues: initialValuesLogin,
    validationSchema: loginSchema,
    onSubmit: async (value, action) => {
      setLoading(true);

      const login = await dispatch(AdminLoginStageOne(value));

      if (login.meta.requestStatus === "fulfilled") {
        notifySuccess(login.payload.message);
        navigate(routePath.verify_otp);
        setLoading(false);
      }

      if (login.meta.requestStatus === "rejected") {
        notifyError(login.payload);
        setLoading(false);
      }

      action.resetForm();
    },
  });
  return (
    <AuthLayout>
      <Flex
        w={"100%"}
        bg={secondBg}
        flexDir={"column"}
        boxShadow={"base"}
        p="6"
        rounded="lg"
        m={"auto"}
        px={{ md: "14", lg: "20" }}
        maxW={["400px", "700px", "1200px"]}
      >
        {/* <Box> */}
        <Text color={HeaderColor} w={"100%"} fontSize={["16px", "30px", "40px"]} fontWeight={Weight.MEDIUM}>
          Puzzle SU
        </Text>
        {/* </Box> */}

        <Box>
          <form onSubmit={AdminLogin.handleSubmit}>
            <Flex flexDir={"column"} pt={"3.5rem"}>
              <Stack spacing={"2"} mb={["8", "12"]}>
                <Input
                  width={"100%"}
                  height={["35px", "55px"]}
                  placeholder="Email"
                  borderColor={textColor}
                  fontSize={[inputFontSize.base, inputFontSize.md]}
                  onChange={AdminLogin.handleChange}
                  value={AdminLogin.values.email}
                  onBlur={AdminLogin.handleBlur}
                  name="email"
                />
                {/* <ErrorMessage name="email" component={"div"} className="error" /> */}
                {AdminLogin.errors.email && AdminLogin.touched.email && (
                  <Text className="error">{AdminLogin.errors.email}</Text>
                )}
              </Stack>

              <Stack spacing={"2"}>
                <Box>
                  <InputGroup width={"100%"} height={["35px", "55px"]}>
                    <Input
                      pr="4.5rem"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      onChange={AdminLogin.handleChange}
                      value={AdminLogin.values.password}
                      onBlur={AdminLogin.handleBlur}
                      borderColor={textColor}
                      name="password"
                      fontSize={[inputFontSize.base, inputFontSize.md]}
                      width={"100%"}
                      height={"100%"}
                    />
                    <InputRightElement height={["35px", "55px"]}>
                      <Icon
                        // position={"absolute"}
                        as={showPassword ? ViewIcon : ViewOffIcon}
                        fontSize={["2xs", "2xl"]}
                        onClick={() => {
                          showPassword ? setShowPassword(false) : setShowPassword(true);
                        }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Box>
                {/* <ErrorMessage name="email" component={"div"} className="error" /> */}

                {AdminLogin.errors.password && AdminLogin.touched.password && (
                  <Text className="error">{AdminLogin.errors.password}</Text>
                )}
              </Stack>
            </Flex>
            <Flex flexDir={"column"} width={"100%"}>
              <Flex justifyContent={"right"}>
                <Link to={routePath.forgot_password} className="Forgot_password">
                  <Text
                    fontSize={[inputFontSize.base, inputFontSize.md]}
                    color={HeaderColor}
                    fontWeight={[Weight.MEDIUM]}
                    py={"2"}
                  >
                    Forgot password
                  </Text>
                </Link>
              </Flex>
              <Box display={"flex"} flexDir={["column", "row"]} justifyContent={"space-between"} my={"10"}>
                <Button
                  fontSize={[inputFontSize.base, inputFontSize.md]}
                  py={[".6rem", "1.8rem"]}
                  px={["2rem", "4rem"]}
                  mb={{ base: "2.5" }}
                  bg={inverseSecondBg}
                  color={textColorInverse}
                  borderRadius={"base"}
                  borderWidth={".1rem"}
                  _hover={{ bg: inverseSecondBg }}
                  isLoading={loading}
                  loadingText="Submitting"
                  colorScheme="teal"
                  variant="outline"
                  type="submit"
                >
                  Login
                </Button>
                {/* 
                <Button
                  fontSize={[inputFontSize.base, inputFontSize.md]}
                  py={[".6rem", "1.8rem"]}
                  px={["2rem", "4rem"]}
                  // colorScheme={inverseSecondBg}
                  color={textColor}
                  borderRadius={"base"}
                  borderWidth={".1rem"}
                  borderColor={textColor}
                  bg={"secondBg"}
                  _hover={{ bg: secondBg }}
                >
                  Create Su
                </Button> */}
              </Box>
            </Flex>
          </form>
        </Box>
      </Flex>
    </AuthLayout>
  );
}
