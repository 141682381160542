import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Box,
  Text,
  Input,
  Stack,
  Select,

} from "@chakra-ui/react";
import { Formik } from "formik";

import { useAppDispatch, useAppSelector } from "@Redux/hooks";

import { UseCustomAppColors, inputBorderColor } from "@utils/color";
import { inputFontSize } from "@utils/fonts";

import { useCustomToast } from "@utils/toast";
import { enrollmentValidate } from "@Validation/CreatValidation";
import { selectConstants, selectAdminConstants } from "@Redux/Slices/Constants/constantSlices";
import { selectEnrollment } from "@Redux/Slices/EnrollmentAndPromotion/EnrollmentSlice";
import {  updateEnrollment } from "@Redux/Services/EnrollmentAndPromotion/EnrollmentService";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  tittle: string;
  enrollmentId?: string;
  setEnrollmentId: () => void;
};

export const EditPromotion: React.FC<Props> = ({
  isOpen,
  onOpen,
  onClose,
  tittle,
  enrollmentId,
  setEnrollmentId,
}: Props) => {
  const dispatch = useAppDispatch();
  const { secondBg, inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const { notifyError, notifySuccess } = useCustomToast();

  const ConstantData = useAppSelector(selectConstants);
  const AdminConstantData = useAppSelector(selectAdminConstants);
  const [enrollmentData, setEnrollmentData] = useState<enrollmentType | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isError, isSuccess, enrollments }: IEnrollments = useAppSelector(selectEnrollment);

  const [enrollmentStages, setEnrollmentStages] = useState<any>();
  const [statusData, setStatus] = useState<any>();

  useEffect(() => {
    setStatus(ConstantData.Status);
    setEnrollmentStages(AdminConstantData.EnrollmentStages);
    if (enrollmentId) {
      const filteredEnrollment = enrollments.find((enrollment) => enrollment.id === enrollmentId);

      setEnrollmentData(filteredEnrollment);
    }
  }, [enrollmentId, isSuccess]);

  const handleFormSubmit = async (values: any, action: any) => {
    setIsLoading(true);
    try {
      console.log(values);

      const enrollData = { ...values, id: enrollmentId };
      const promoEdit = await dispatch(updateEnrollment(enrollData));

      if (promoEdit.meta.requestStatus === "fulfilled") {
        notifySuccess(promoEdit.payload.message);
        // dispatch(getEnrollment());
        action.resetForm();
        setEnrollmentId();
        setIsLoading(false);
        onClose();
      }

      if (promoEdit.meta.requestStatus === "rejected") {
        setIsLoading(false);
        setEnrollmentId();
        notifyError(promoEdit.payload);
      }
    } catch (error) {
      // Handle error appropriately
    }
  };

  return (
    <>
      {isOpen && enrollmentData ? (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalBody py={["1", "3", "14"]} px={["8", "18", "48"]}>
              <Box bg={secondBg} boxShadow="0px 0px 4px #b7b7b7" rounded="lg" px="14" pb={["10", "20"]}>
                <Heading py="12">{`Edit ${tittle}`}</Heading>
                <Box bg="white" rounded="md">
                  <Formik
                    initialValues={{
                      amount: enrollmentData?.amount,
                      stage: enrollmentData?.stage,
                      status: enrollmentData?.status,
                    }}
                    validationSchema={enrollmentValidate}
                    onSubmit={handleFormSubmit}
                    enableReinitialize
                  >
                    {({ handleSubmit, errors, touched, handleChange, values, handleBlur }) => (
                      <form onSubmit={handleSubmit}>
                        <Stack spacing={"3"} flexDir={"column"}>
                          <Box>
                            <Input
                              width={"100%"}
                              height={["30px", "50px"]}
                              placeholder="Amount"
                              focusBorderColor={inputBorderColor.focusBorderColor}
                              borderColor={inputBorderColor.borderColor}
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              onChange={handleChange}
                              value={values.amount}
                              type="number"
                              onBlur={handleBlur}
                              name="amount"
                            />
                            {errors.amount && touched.amount && (
                              <Text className="error">{errors.amount}</Text>
                            )}
                          </Box>

                          <Box>
                            {/* <FormControl> */}
                            <Select
                              id="status"
                              name="status"
                              width={"100%"}
                              height={["30px", "50px"]}
                              placeholder="Select Status"
                              focusBorderColor={inputBorderColor.focusBorderColor}
                              borderColor={inputBorderColor.borderColor}
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                            >
                              {Object.keys(statusData!).map((key) => {
                                return (
                                  statusData[key]! !== undefined && (
                                    <option key={key} value={statusData[key]!}>
                                      {" "}
                                      {statusData[key]!}
                                    </option>
                                  )
                                );
                              })}
                            </Select>
                            {/* </FormControl> */}

                            {errors.status && touched.status && (
                              <Text className="error">{errors.status}</Text>
                            )}
                          </Box>

                          <Box>
                            {/* <FormControl> */}
                            <Select
                              id="stage"
                              name="stage"
                              width={"100%"}
                              height={["30px", "50px"]}
                              focusBorderColor={inputBorderColor.focusBorderColor}
                              borderColor={inputBorderColor.borderColor}
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              placeholder="Select Stage"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.stage}
                            >
                              {Object.keys(enrollmentStages!).map((key) => {
                                return (
                                  enrollmentStages[key]! !== undefined && (
                                    <option key={key} value={enrollmentStages[key]!}>
                                      {" "}
                                      {enrollmentStages[key] !== undefined && enrollmentStages[key]!}
                                    </option>
                                  )
                                );
                              })}
                            </Select>
                            {/* </FormControl> */}

                            {errors.stage && touched.stage && <Text className="error">{errors.stage}</Text>}
                          </Box>
                          <Box
                            display={"flex"}
                            flexDir={["column", "row"]}
                            justifyContent={"space-between"}
                            my={"10"}
                          >
                            <Button
                              fontSize={[inputFontSize.base, inputFontSize.md]}
                              py={[".4rem", "1.5rem"]}
                              px={["1.8rem", "3.8rem"]}
                              mb={{ base: "2.5" }}
                              bg={inverseSecondBg}
                              color={textColorInverse}
                              borderRadius={"lg"}
                              borderWidth={".1rem"}
                              _hover={{ bg: inverseSecondBg }}
                              isLoading={isLoading}
                              loadingText="Submitting"
                              variant="outline"
                              type="submit"
                            >
                              Edit
                            </Button>
                          </Box>
                        </Stack>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
