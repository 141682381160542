import { ReactNode } from "react";

import { Box, Icon } from "@chakra-ui/react";
import MotionBox from "@utils/MotionBox";

import styles from "@components/styles/Home.module.css";
import { UseCustomAppColors } from "@utils/color";
import { logoIconLight } from "@components/icons/logo";

export default function authLayout({ children }: { children: ReactNode }) {
  const { bg } = UseCustomAppColors();
  return (
    <Box minH="100vh" bg={bg}>
      <Box flexDir={"column"} px={{ base: "7", md: "15%", lg: "27%" }}>
        <Box
          minH={["6vh", "12vh", "18vh"]}
          display={"flex"}
          justifyContent={"left"}
          alignItems={"end"}
          pt={[".8rem", "1.6rem", "2.5rem"]}
        >
          {/* <Box height={"1.5"} w={"3"}> */}
          <Icon as={logoIconLight} height={["24", "36"]} w={["24", "36"]} />
          {/* </Box> */}
        </Box>

        <Box minH={["90vh", "85vh", "70vh"]} maxW={"100%"}>
          <MotionBox animationType="fadeUp" className={styles.auth_banner}>
            {children}
          </MotionBox>
        </Box>
      </Box>
    </Box>
  );
}
