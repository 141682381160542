
import { createStaff } from "@Redux/Services/Staffs/StaffService";
import { selectConstants, selectAdminConstants } from "@Redux/Slices/Constants/constantSlices";


import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import { staffValue } from "@Schema/schema";
import { staffValidate } from "@Validation/CreatValidation";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Box,
  Text,
  Input,
  Stack,
  Select,
  ModalCloseButton,
} from "@chakra-ui/react";

import { UseCustomAppColors, inputBorderColor } from "@utils/color";
import { inputFontSize } from "@utils/fonts";
import { SlugUnderscores } from "@utils/helper";

import { useCustomToast } from "@utils/toast";

import { Formik } from "formik";
import React, { useEffect, useState } from "react";

type Props = { isOpen: boolean; onOpen: () => void; onClose: () => void; tittle: string };

export const Create = React.memo(({ isOpen, onOpen, onClose, tittle }: Props) => {
  const { secondBg, inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const { notifyError, notifySuccess } = useCustomToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const ConstantData = useAppSelector(selectConstants);
  const AdminConstantData = useAppSelector(selectAdminConstants);

  const [adminRole, setAdminRole] = useState<any>();
  const [gender, setGender] = useState<any>();

  useEffect(() => {
    setAdminRole(AdminConstantData.AdminRole);
    setGender(ConstantData.Gender);
    console.log("constant data");
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>{`Create ${tittle}`}</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody py={["1", "3", "14"]} px={["8", "18", "48"]}>
            <Box bg={secondBg} boxShadow={"0px 0px 4px #b7b7b7"} rounded={"lg"} px={"14"} pb={["10", "20"]}>
              <Heading py={"12"}>{`Create ${tittle}`}</Heading>
              <Box bg="white" rounded="md">
                <Formik
                  initialValues={staffValue}
                  validationSchema={staffValidate}
                  onSubmit={(values, action) => {
                    try {
                      setIsLoading(true);
                      const init = async () => {
                        console.log(values);

                        let staffCreate = await dispatch(createStaff(values));

                        if (staffCreate.meta.requestStatus === "fulfilled") {
                          notifySuccess(staffCreate.payload.message);
                          // dispatch(getEnrollment());
                          action.resetForm();
                          setIsLoading(false);
                          onClose();
                        }
                        if (staffCreate.meta.requestStatus === "rejected") {
                          console.log(staffCreate);
                          setIsLoading(false);
                          notifyError(staffCreate.payload);
                        }
                      };
                      init();
                    } catch (error: any) {
                      console.log(error.message);
                    }
                  }}
                >
                  {({ handleSubmit, errors, touched, handleChange, values, handleBlur }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack spacing={"3"} flexDir={"column"}>
                        <Box>
                          <Input
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="first last"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            value={values.first_last}
                            type="text"
                            onBlur={handleBlur}
                            name="first_last"
                          />
                          {errors.first_last && touched.first_last && (
                            <Text className="error">{errors.first_last}</Text>
                          )}
                        </Box>

                        <Box>
                          <Input
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="last name"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            value={values.last_name}
                            type="text"
                            onBlur={handleBlur}
                            name="last_name"
                          />
                          {errors.last_name && touched.last_name && (
                            <Text className="error">{errors.last_name}</Text>
                          )}
                        </Box>
                        <Box>
                          <Input
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="Enter Email"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            value={values.email}
                            type="email"
                            onBlur={handleBlur}
                            name="email"
                          />
                          {errors.email && touched.email && <Text className="error">{errors.email}</Text>}
                        </Box>

                        <Box>
                          <Input
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="Enter Password"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            value={values.password}
                            type="password"
                            onBlur={handleBlur}
                            name="password"
                          />
                          {errors.password && touched.password && (
                            <Text className="error">{errors.password}</Text>
                          )}
                        </Box>

                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="gender"
                            name="gender"
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="Select Gender"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gender}
                          >
                            {Object.keys(gender!).map((key) => {
                              return (
                                gender[key]! !== undefined && (
                                  <option key={key} value={gender[key]!}>
                                    {" "}
                                    {gender[key]!}
                                  </option>
                                )
                              );
                            })}
                          </Select>
                          {/* </FormControl> */}

                          {errors.gender && touched.gender && <Text className="error">{errors.gender}</Text>}
                        </Box>

                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="admin_role"
                            name="admin_role"
                            width={"100%"}
                            height={["30px", "50px"]}
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            placeholder="Select Role "
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.admin_role}
                          >
                            {Object.keys(adminRole!).map((key) => {
                              return (
                                adminRole[key]! !== undefined &&
                                adminRole[key] !== "NONE" && (
                                  <option key={key} value={adminRole[key]!}>
                                    {adminRole[key] !== undefined && SlugUnderscores(adminRole[key]!)}
                                  </option>
                                )
                              );
                            })}
                          </Select>
                          {/* </FormControl> */}

                          {errors.staff_type && touched.staff_type && (
                            <Text className="error">{errors.staff_type}</Text>
                          )}
                        </Box>
                        <Box
                          display={"flex"}
                          flexDir={["column", "row"]}
                          justifyContent={"space-between"}
                          my={"10"}
                        >
                          <Button
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            py={[".4rem", "1.5rem"]}
                            px={["1.8rem", "3.8rem"]}
                            mb={{ base: "2.5" }}
                            bg={inverseSecondBg}
                            color={textColorInverse}
                            borderRadius={"lg"}
                            borderWidth={".1rem"}
                            _hover={{ bg: inverseSecondBg }}
                            isLoading={isLoading}
                            loadingText="Submitting"
                            variant="outline"
                            type="submit"
                          >
                            Create
                          </Button>
                        </Box>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
