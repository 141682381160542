import { createCreatorType } from "@Redux/Services/Creators/creatorsService";

import { useAppDispatch } from "@Redux/hooks";
import { creatorTypeValue } from "@Schema/schema";
import { creatorTypeValidate } from "@Validation/CreatValidation";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Box,
  Text,
  Input,
  Stack,
  Select,
  ModalCloseButton,
} from "@chakra-ui/react";

import { UseCustomAppColors, inputBorderColor } from "@utils/color";
import { inputFontSize } from "@utils/fonts";

import { useCustomToast } from "@utils/toast";

import { Formik } from "formik";
import React, { useState } from "react";

type Props = { isOpen: boolean; onOpen: () => void; onClose: () => void; tittle: string };

export const CreatePromotion = React.memo(({ isOpen, onOpen, onClose, tittle }: Props) => {
  const { secondBg, inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const { notifyError, notifySuccess } = useCustomToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>{`Create ${tittle}`}</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody py={["1", "3", "14"]} px={["8", "18", "48"]}>
            <Box bg={secondBg} boxShadow={"0px 0px 4px #b7b7b7"} rounded={"lg"} px={"14"} pb={["10", "20"]}>
              <Heading py={"12"}>{`Create ${tittle}`}</Heading>
              <Box bg="white" rounded="md">
                <Formik
                  initialValues={creatorTypeValue}
                  validationSchema={creatorTypeValidate}
                  onSubmit={(values, action) => {
                    try {
                      setIsLoading(true);
                      const init = async () => {
                        let creatorsCreate = await dispatch(createCreatorType(values));

                        if (creatorsCreate.meta.requestStatus === "fulfilled") {
                          notifySuccess(creatorsCreate.payload.message);
                          action.resetForm();
                          setIsLoading(false);
                          onClose();
                        }
                        if (creatorsCreate.meta.requestStatus === "rejected") {
                          console.log(creatorsCreate);
                          setIsLoading(false);
                          notifyError(creatorsCreate.payload);
                        }
                      };
                      init();
                    } catch (error: any) {
                      console.log(error.message);
                    }
                  }}
                >
                  {({ handleSubmit, errors, touched, handleChange, values, handleBlur }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack spacing={"3"} flexDir={"column"}>
                        <Box>
                          <Input
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="name"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            value={values.name}
                            type="text"
                            onBlur={handleBlur}
                            name="name"
                          />
                          {errors.name && touched.name && <Text className="error">{errors.name}</Text>}
                        </Box>

                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="status"
                            name="status"
                            width={"100%"}
                            height={["30px", "50px"]}
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            placeholder="Select Status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.status}
                          >
                            <option value={"true"}> True</option>
                            <option value={"false"}> False</option>
                          </Select>
                          {/* </FormControl> */}

                          {errors.status && touched.status && <Text className="error">{errors.status}</Text>}
                        </Box>
                        <Box
                          display={"flex"}
                          flexDir={["column", "row"]}
                          justifyContent={"space-between"}
                          my={"10"}
                        >
                          <Button
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            py={[".4rem", "1.5rem"]}
                            px={["1.8rem", "3.8rem"]}
                            mb={{ base: "2.5" }}
                            bg={inverseSecondBg}
                            color={textColorInverse}
                            borderRadius={"lg"}
                            borderWidth={".1rem"}
                            _hover={{ bg: inverseSecondBg }}
                            isLoading={isLoading}
                            loadingText="Submitting"
                            variant="outline"
                            type="submit"
                          >
                            Create
                          </Button>
                        </Box>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
