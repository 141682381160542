import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Box,
  Button,
  Flex,
  Select,
  FormControl,
  HStack,
  FormLabel,
} from "@chakra-ui/react";
import { CustomInputLabelAtLeft } from "@components/modals/CustomInputMain";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";

import { addAccount, getSettings } from "@Redux/Services/AppSetting/appsetting";
import { inputFontSize } from "@utils/fonts";
import { UseCustomAppColors } from "@utils/color";
import { useCustomToast } from "@utils/toast";

import { selectAdminConstants } from "@Redux/Slices/Constants/constantSlices";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

type CreateType = {
  type: string;
  bank_code: string;
  account_number: string;
};

export const AddNewAccount: React.FC<Props> = ({ isOpen, onClose, onOpen }) => {
  const { inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [account, setAccount] = useState<CreateType>({
    type: "",
    bank_code: "",
    account_number: "",
  });

  const [accountType, setAccountType] = useState<any>();

  const dispatch = useAppDispatch();
  const { notifyError, notifySuccess } = useCustomToast();

  const AdminConstantData = useAppSelector(selectAdminConstants);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setAccount((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setAccountType(AdminConstantData.AdminAccountType);
  }, [isOpen, AdminConstantData.AdminAccountType]);

  const handleSubmit = async () => {
    try {
      let addAccountDetails = await dispatch(addAccount(account));

      if (addAccountDetails.meta.requestStatus === "fulfilled") {
        notifySuccess(addAccountDetails.payload.message);
        await dispatch(getSettings({}));
        setIsLoading(false);
        onClose();
      }
      if (addAccountDetails.meta.requestStatus === "rejected") {
        notifyError(addAccountDetails.payload);
        setIsLoading(false);
        onClose();
      }

      setAccount({ type: "", bank_code: "", account_number: "" });
    } catch (error) {}
  };

  return (
    <>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay bg={"blackAlpha.200"} />
          <ModalContent>
            <ModalBody py={["1", "3", "14"]} px={["8", "14", "24"]}>
              <Box boxShadow="0px 0px 4px #b7b7b7" rounded="lg" px="14" pb={["5", "10"]}>
                <Heading py="4">{`Add New Account`}</Heading>
                <Box mt={"5"} boxShadow={"base"} py={"3"} px={"3"}>
                  <CustomInputLabelAtLeft
                    label="Account Number"
                    name="account_number"
                    w={"60%"}
                    my={"2"}
                    value={account.account_number}
                    onChange={(e) => handleChange(e)}
                  />
                  <CustomInputLabelAtLeft
                    label="Bank Code"
                    w={"75%"}
                    my={"2"}
                    name="bank_code"
                    onChange={(e) => handleChange(e)}
                    value={account.bank_code}
                  />
                  <Box>
                    <FormControl>
                      <HStack>
                        <FormLabel htmlFor={"account_type"}>AccountType</FormLabel>{" "}
                        <Select
                          id="account_type"
                          name="type"
                          w={"70%"}
                          height={["30px", "50px"]}
                          placeholder="Select Status"
                          fontSize={[inputFontSize.base, inputFontSize.md]}
                          onChange={(e) => handleChange(e)}
                        >
                          {Object.keys(accountType!).map((key) => {
                            return (
                              accountType[key]! !== undefined && (
                                <option key={key} value={accountType[key]!}>
                                  {" "}
                                  {accountType[key]!}
                                </option>
                              )
                            );
                          })}
                        </Select>
                      </HStack>
                    </FormControl>
                  </Box>
                </Box>
                <Flex justify={"flex-end"} mt={"6"}>
                  <Button
                    fontSize={[inputFontSize.base, inputFontSize.md]}
                    py={[".4rem", "1.5rem"]}
                    px={["1.8rem", "3.8rem"]}
                    mb={{ base: "2.5" }}
                    bg={inverseSecondBg}
                    color={textColorInverse}
                    borderRadius={"lg"}
                    borderWidth={".1rem"}
                    _hover={{ bg: inverseSecondBg }}
                    isLoading={isLoading}
                    loadingText="Submitting"
                    variant="outline"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </Flex>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
