import { ReactNode, useEffect, useMemo } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function MotionBox({
  children,
  ...props
}: {
  children: ReactNode;
  className?: any;
  animationType?: "fadeIn" | "fadeUp" | "fadeDown" | "fadeLeft" | "fadeRight";
  delay?: boolean;
}) {
  const control = useAnimation();
  const [ref, inView] = useInView();

  const variantValue = useMemo(() => {
    return {
      fadeIn: { x: 0, y: 1.4 },
      fadeUp: { x: 0, y: 100 },
      fadeDown: { x: 0, y: -100 },
      fadeLeft: { x: 100, y: 0 },
      fadeRight: { x: -100, y: 0 },
    };
  }, []);

  const boxVariant = useMemo(() => {
    return {
      hidden: {
        ...variantValue[props.animationType || "fadeIn"],
        opacity: 0,
        transition: { duration: 0.7 },
        scale: 0.8,
      },
      visible: {
        y: 0,
        x: 0,
        opacity: 1,
        scale: 1,
        transition: { duration: 0.7, delay: props.delay ? 0.5 : 0 },
      },
    };
  }, [props.animationType, props.delay, variantValue]);
  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  return (
    <motion.div
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      className={props.className}
    >
      {children}
    </motion.div>
  );
}
