import React, { useEffect, useState } from "react";
import { MainLayout } from "@layouts/dashboardLayout/MainLayout";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  HStack,
  TableContainer,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Button,
  Portal,
  Flex,
  Text,
  Switch,
  Td,
} from "@chakra-ui/react";
import { UseCustomAppColors } from "@utils/color";

import { Create } from "./Create";
// import { Edit } from "./Edit";
import { InnerSearchHeader } from "@layouts/dashboardLayout/InnerSearchHeader";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import {
  selectStaffs,

  resetStaffMessage,
  resetStaffStatus,
} from "@Redux/Slices/Staff/StaffSlice";
import {  getStaffs, updateStaff } from "@Redux/Services/Staffs/StaffService";
import { useCustomToast } from "@utils/toast";
import { FiEdit } from "react-icons/fi";
import { Font, Weight } from "@utils/fonts";
import { SlugUnderscores, formatDateToCustomFormat } from "@utils/helper";

import { routePath } from "@utils/routes";


import { selectAdminConstants, selectConstants } from "@Redux/Slices/Constants/constantSlices";
import PaginationLayout from "@layouts/dashboardLayout/PaginationLayout";
import { TableList } from "@Skeleton/services";
import { Link } from "react-router-dom";

function Index() {
  const { inverseSecondBg, textColorInverse, textColor } = UseCustomAppColors();
  const { isOpen: createIsOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<Staff[]>();
  const [staffsData, setStaffsData] = useState<Staff[]>();
  const [staffId, setStafftId] = useState<string | undefined>();
  const { notifyError, notifySuccess } = useCustomToast();

  // const ConstantData = useAppSelector(selectConstants);
  const AdminConstantData = useAppSelector(selectAdminConstants);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [totalRecord, setTotalRecords] = useState<number>();
  const dispatch = useAppDispatch();
  const handleFiltedChanges = (filteredData: Staff[]) => setFilteredData(filteredData);
  const {  isSuccess, message, totalRecords, staffs }: IStaff = useAppSelector(selectStaffs);
  const [page, setPage] = useState(1);

  const [status, setStatus] = useState<any>();

  useEffect(() => {
    dispatch(resetStaffMessage());
    dispatch(getStaffs({ page: page, staffType: "ADMIN_STAFF" }));
    dispatch(resetStaffStatus());
    setTotalRecords(totalRecords);
  }, [page]);

  useEffect(() => {
    try {
      if (isSuccess && message) {
        message === "" && notifySuccess(message!);
        dispatch(resetStaffMessage());
      }
      if (staffs) {
        setStaffsData(staffs);
        setStatus(AdminConstantData.staffStatus);
      }
    } catch (error) {
      console.log(error);
    }
  }, [staffs, isLoading]);

  const handleEdit = (staffId: string | undefined) => {
    setStafftId(staffId);
    if (staffId) {
      editOnOpen();
    }
  };

  const handleStatusChange = async (staff: Staff) => {
    try {
      if (staff.status !== status.pending) {
        setIsLoading(true);
        let changeStatus = await dispatch(
          updateStaff({
            status: staff?.status === status.active ? status.suspended : status.active,
            id: staff?.id,
          })
        );
        if (changeStatus.meta.requestStatus === "fulfilled") {
          notifySuccess(changeStatus.payload.message);
          setIsLoading(false);
        }
        if (changeStatus.meta.requestStatus === "rejected") {
          notifyError(changeStatus.payload);
          setIsLoading(false);
        }
      } else {
        notifyError("Status is still pending");
      }
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  // const handleSetStaff = () => {
  //   setStafftId("");
  // };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  let staffDataDisplyed = filteredData && filteredData?.length > 0 ? filteredData : staffsData;

  let enrollmentTableData = staffDataDisplyed?.map((staff, index) => (
    <>
      <Tr key={`${index}_${staff?.first_last}`}>
        <Td color={textColor} p={"1.5"}>
          {index + 1}
        </Td>
        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.SMALL}
        >
          <Link to={`${routePath.staff}${staff.id}`}> {`${staff?.first_last}  ${staff?.last_name}`}</Link>
        </Td>
        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {staff?.email}
        </Td>
        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {SlugUnderscores(staff?.admin_role!)}
        </Td>
        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {staff?.gender}
        </Td>

        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {staff?.status}
        </Td>

        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          {/* {CurrentTime(promotion?.createdAt)} */}
          {formatDateToCustomFormat(staff?.createdAt)}
        </Td>

        <Td
          color={textColor}
          p={"1.5"}
          fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
          fontWeight={Weight.MIN_SMALL}
        >
          <Popover placement="left-start">
            <PopoverTrigger>
              <Button variant={"unstyled"}>Action</Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent w={["140px", "150px", "180px"]}>
                <PopoverArrow />

                <PopoverBody boxShadow={"0px 0px 4px #808080"} rounded={["md", "md", "2xl"]}>
                  <Flex flexDir={"column"}>
                    <Button colorScheme="gray" variant={"unstyled"}>
                      <HStack justifyContent={"space-between"}>
                        <Text mr={["5px", "6px"]}>Name</Text>
                        <Button onClick={() => handleEdit(staff.id)} variant={"unstyled"}>
                          {" "}
                          <FiEdit />
                        </Button>
                      </HStack>
                    </Button>
                    {/* <Button colorScheme="gray" variant={"unstyled"}>
                        <HStack justifyContent={"space-between"}>
                          <Text mr={["4px"]}>Delete</Text>
                          <Button onClick={() => handleDeleteEnrol(staff.id)} variant={"unstyled"}>
                            {" "}
                            <MdDeleteForever fontSize={"1.4rem"} color="red" />
                          </Button>
                        </HStack>
                      </Button> */}

                    <Button colorScheme="gray" variant={"unstyled"}>
                      <HStack justifyContent={"space-between"}>
                        <Text mr={["5px", "6px"]}>Status</Text>
                        <Switch
                          size="lg"
                          isChecked={staff.status === "ACTIVE" ? true : false}
                          onChange={() => handleStatusChange(staff)}
                        />
                      </HStack>
                    </Button>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Td>
      </Tr>
    </>
  ));

  return (
    <MainLayout>
      <InnerSearchHeader
        onOpen={createOnOpen}
        title="Staff"
        isCreate={true}
        searches={["first_last", "last_name", "admin_role", "status", "email"]}
        data={staffsData}
        setFilteredData={handleFiltedChanges}
      />
      <Box maxH={"100%"}>
        <TableContainer
          css={{
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
              borderRadius: "24px",
            },
          }}
        >
          <Table variant="simple" overflowY={"scroll"}>
            <Thead bg={inverseSecondBg} color={textColorInverse} p={"2"}>
              <Tr>
                <Th color={textColorInverse}>S/N</Th>
                <Th color={textColorInverse}>Full Name</Th>
                <Th color={textColorInverse}>Email</Th>
                <Th color={textColorInverse}>Role</Th>
                <Th color={textColorInverse}>Gender</Th>
                <Th color={textColorInverse}>Status</Th>
                <Th color={textColorInverse}>Created At</Th>
                <Th color={textColorInverse}>Action</Th>
              </Tr>
            </Thead>
            <Tbody overflowX={"scroll"} minH={"100%"} alignItems={"flex-start"}>
              {enrollmentTableData ? enrollmentTableData : <TableList />}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
          <Create isOpen={createIsOpen} onOpen={createOnOpen} onClose={createOnClose} tittle="Staff" />
          {/* <Edit
            isOpen={editIsOpen}
            onOpen={editOnOpen}
            onClose={editOnClose}
            tittle="Staff"
            Id={staffId}
            setId={handleSetStaff}
          /> */}
        </TableContainer>
      </Box>
      <PaginationLayout page={page} totalRecord={totalRecord} onPageChange={handlePageChange} />
    </MainLayout>
  )
  }

export default Index;
