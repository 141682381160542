import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { logoIconLight } from "@components/icons/logo";
import { NotificationMoadal } from "./NotificationMoadal";
import { Font } from "@utils/fonts";
import { IoMdNotifications } from "react-icons/io";
import { UseCustomAppColors } from "@utils/color";
import { ProfileMoadal } from "./ProfileMoadal";

import { IoMoon } from "react-icons/io5";
import { MdOutlineWbSunny } from "react-icons/md";

export const MainHeader = React.memo(() => {
  const { textColorInverse, HeaderColor, textColor } = UseCustomAppColors();
  const {
    isOpen: isOpenNotification,
    onClose: onCloseNotification,
    onOpen: onOpenNotification,
  } = useDisclosure();

  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen: isOpenProfile, onClose: onCloseProfile, onOpen: onOpenProfile } = useDisclosure();
  return (
    <Flex
      maxH={"8vh"}
      display={["none", "none", "block", "block"]}
      // borderWidth={"1px"}
      borderBottomWidth={"0.5px"}
      borderBottomColor={"blackAlpha.300"}
      alignItems={"center"}
      px={"8"}
      bg={textColorInverse}
    >
      <Flex flexDir={"row"} alignItems={"center"} justifyContent={"space-between"} h={55}>
        <Box>
          <Icon as={logoIconLight} height={["0", "0", "10", "14"]} mt={3} w={["0", "0", "24", "36"]} />
        </Box>
        <Flex maxW={"96"} h={"100%"} alignItems={"center"}>
          <Box>
            <Button variant={"unstyled"} onClick={toggleColorMode}>
              {colorMode === "light" ? (
                <Icon as={MdOutlineWbSunny} fontSize={"2xl"} color={textColor} />
              ) : (
                <Icon as={IoMoon} fontSize={"2xl"} color={textColor} />
              )}
            </Button>
          </Box>
          <Box>
            <Button
              variant={"unstyled"}
              onClick={onOpenNotification}
              _hover={{ bg: "transparent", color: { HeaderColor } }}
              aria-label="Notifications"
            >
              <Icon as={IoMdNotifications} fontSize={"2xl"} color={textColor} />
            </Button>
          </Box>
          <NotificationMoadal
            isOpen={isOpenNotification}
            onClose={onCloseNotification}
            onOpen={onOpenNotification}
            isLoading={false}
            notifications={[]}
          />
          <Box w={"max-content"} mr={".3rem"}>
            <Text onClick={onOpenProfile} textTransform={"capitalize"} fontSize={Font.PRIMARY}>
              {" "}
              super admin
            </Text>
          </Box>
          <Box w={"2.6rem"} position={"relative"}>
            <Image
              onClick={onOpenProfile}
              borderRadius="full"
              src="https://bit.ly/dan-abramov"
              alt="Profile"
            />
          </Box>
          <ProfileMoadal
            isOpen={isOpenProfile}
            onClose={onCloseProfile}
            onOpen={onOpenProfile}
            isLoading={false}
            details={[]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
});
