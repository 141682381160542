import { createEnrollment } from "@Redux/Services/EnrollmentAndPromotion/EnrollmentService";
import { selectConstants, selectAdminConstants } from "@Redux/Slices/Constants/constantSlices";

import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import { enrollmentValue } from "@Schema/schema";
import { enrollmentValidate } from "@Validation/CreatValidation";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Box,
  Text,
  Input,
  Stack,
  Select,
  ModalCloseButton,
} from "@chakra-ui/react";

import { UseCustomAppColors, inputBorderColor } from "@utils/color";
import { inputFontSize } from "@utils/fonts";

import { useCustomToast } from "@utils/toast";

import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";

type Props = { isOpen: boolean; onOpen: () => void; onClose: () => void; tittle: string };

export const CreatePromotion = React.memo(({ isOpen, onOpen, onClose, tittle }: Props) => {
  const { secondBg, inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const { notifyError, notifySuccess } = useCustomToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const ConstantData = useAppSelector(selectConstants);
  const AdminConstantData = useAppSelector(selectAdminConstants);

  const [enrollmentStages, setEnrollmentStages] = useState<any>();
  const [statusData, setStatus] = useState<any>();

  useEffect(() => {
    setStatus(ConstantData.Status);
    setEnrollmentStages(AdminConstantData.EnrollmentStages);
  }, [isOpen]);
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>{`Create ${tittle}`}</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody py={["1", "3", "14"]} px={["8", "18", "48"]}>
            <Box bg={secondBg} boxShadow={"0px 0px 4px #b7b7b7"} rounded={"lg"} px={"14"} pb={["10", "20"]}>
              <Heading py={"12"}>{`Create ${tittle}`}</Heading>
              <Box bg="white" rounded="md">
                <Formik
                  initialValues={enrollmentValue}
                  validationSchema={enrollmentValidate}
                  onSubmit={(values, action) => {
                    try {
                      setIsLoading(true);
                      const init = async () => {
                        console.log(values);

                        let enrollCreate = await dispatch(createEnrollment(values));

                        if (enrollCreate.meta.requestStatus === "fulfilled") {
                          notifySuccess(enrollCreate.payload.message);
                          // dispatch(getEnrollment());
                          action.resetForm();
                          setIsLoading(false);
                          onClose();
                        }
                        if (enrollCreate.meta.requestStatus === "rejected") {
                          console.log(enrollCreate);
                          setIsLoading(false);
                          notifyError(enrollCreate.payload);
                        }
                      };
                      init();
                    } catch (error: any) {
                      console.log(error.message);
                    }
                  }}
                >
                  {({ handleSubmit, errors, touched, handleChange, values, handleBlur }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack spacing={"3"} flexDir={"column"}>
                        <Box>
                          <Input
                            as={Field}
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="Amount"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            value={values.amount}
                            type="number"
                            onBlur={handleBlur}
                            name="amount"
                          />
                          {errors.amount && touched.amount && <Text className="error">{errors.amount}</Text>}
                        </Box>

                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="status"
                            name="status"
                            width={"100%"}
                            height={["30px", "50px"]}
                            placeholder="Select Status"
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.status}
                          >
                            {Object.keys(statusData!).map((key) => {
                              return (
                                statusData[key]! !== undefined && (
                                  <option key={key} value={statusData[key]!}>
                                    {" "}
                                    {statusData[key]!}
                                  </option>
                                )
                              );
                            })}
                          </Select>
                          {/* </FormControl> */}

                          {errors.status && touched.status && <Text className="error">{errors.status}</Text>}
                        </Box>

                        <Box>
                          {/* <FormControl> */}
                          <Select
                            id="stage"
                            name="stage"
                            width={"100%"}
                            height={["30px", "50px"]}
                            focusBorderColor={inputBorderColor.focusBorderColor}
                            borderColor={inputBorderColor.borderColor}
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            placeholder="Select Stage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.stage}
                          >
                            {Object.keys(enrollmentStages!).map((key) => {
                              return (
                                enrollmentStages[key]! !== undefined && (
                                  <option key={key} value={enrollmentStages[key]!}>
                                    {" "}
                                    {enrollmentStages[key] !== undefined && enrollmentStages[key]!}
                                  </option>
                                )
                              );
                            })}
                          </Select>
                          {/* </FormControl> */}

                          {errors.stage && touched.stage && <Text className="error">{errors.stage}</Text>}
                        </Box>
                        <Box
                          display={"flex"}
                          flexDir={["column", "row"]}
                          justifyContent={"space-between"}
                          my={"10"}
                        >
                          <Button
                            fontSize={[inputFontSize.base, inputFontSize.md]}
                            py={[".4rem", "1.5rem"]}
                            px={["1.8rem", "3.8rem"]}
                            mb={{ base: "2.5" }}
                            bg={inverseSecondBg}
                            color={textColorInverse}
                            borderRadius={"lg"}
                            borderWidth={".1rem"}
                            _hover={{ bg: inverseSecondBg }}
                            isLoading={isLoading}
                            loadingText="Submitting"
                            variant="outline"
                            type="submit"
                          >
                            Create
                          </Button>
                        </Box>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
