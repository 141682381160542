import { Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { ReactNode } from "react";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { VscError } from "react-icons/vsc";
import "../";
import { capitalizeFirstLetter } from "./helper";

export const useCustomToast = () => {
  const toast = useToast();
  const notifySuccess = (message: string) => {
    return toast({
      position: "top-right",
      render: () => (
        <Flex color="white" align={"center"} borderRadius="10" py={4} px={5} bg="green.500">
          <Text mt={2} className="toastIconSuccess">
            <Icon as={AiFillCheckCircle} color={"#fff"} />
          </Text>
          <Text pl={"10px"} fontWeight={"bold"} color={"white"}>
            {capitalizeFirstLetter(message)}
          </Text>
        </Flex>
      ),
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const notifyError = (message: string) => {
    return toast({
      position: "top-right",
      render: (): ReactNode => (
        <Flex color="white" align={"center"} borderRadius="10" py={4} px={5} bg="red.500">
          <Text mt={2} className="toastIconSuccess">
            <Icon as={VscError} color={"#fff"} />
          </Text>
          <Text pl={"10px"} fontWeight={"bold"} color={"white"}>
            {capitalizeFirstLetter(message)}
          </Text>
        </Flex>
      ),
      status: "error",
      duration: 7000,
      isClosable: true,
    });
  };

  const notifyWarning = (message: string) => {
    return toast({
      position: "top-right",
      render: (): ReactNode => (
        <Flex color="white" align={"center"} borderRadius="10" py={4} px={5} bg="yellow.500">
          <Text mt={2} className="toastIconSuccess">
            <Icon as={AiFillWarning} color={"#fff"} />
          </Text>
          <Text pl={"10px"} fontWeight={"bold"} color={"white"}>
            {capitalizeFirstLetter(message)}
          </Text>
        </Flex>
      ),
      status: "error",
      duration: 7000,
      isClosable: true,
    });
  };

  return { notifySuccess, notifyError, notifyWarning };
};
