import { selectAppSettings } from "@Redux/Slices/AppSetting/appsettingSlices";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { updateSetting, getSettings } from "@Redux/Services/AppSetting/appsetting";
import { useCustomToast } from "@utils/toast";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onDelete: () => void;
  id: string;
  name: string;
  valueToRemove: any;
  type: string;
}

export const RemoveItemRangeItem: React.FC<Props> = ({
  isOpen,
  onClose,
  onDelete,
  id,
  valueToRemove,
  name,
  type,
}: Props) => {
  const [appRangeData, setAppRangeData] = useState<AppRange[] | null>();
  const { isLoading, appRange }: IAppSettings = useAppSelector(selectAppSettings);
  const { notifyError, notifySuccess } = useCustomToast();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setAppRangeData(appRange);
  }, []);
  useEffect(() => {
    setAppRangeData(appRange);
  }, [appRange, isLoading]);

  const handleDeleteRange = async (
    id: string,
    appRangeData: AppRange[],
    valueToRemove: string,
    name: string | number,
    type: string | number,
    onDelete: () => void,
    onClose: () => void
  ) => {
    try {
      const editRange = appRangeData?.filter(
        (range: AppRange) => range.name === name && range.type === type
      )[0];
      const editedRange = editRange?.value
        ? Object.entries(editRange?.value)
            .filter(([key, objValue]) => {
              return key !== valueToRemove;
            })
            .reduce((acc: any, [k, v]) => ((acc[k] = v), acc), {})
        : null;

      const updateRange = {
        id: id,
        name: name,
        type: type,
        value: editedRange,
      };

      let removeItem = await dispatch(updateSetting(updateRange));
      if (removeItem.meta.requestStatus === "fulfilled") {
        notifySuccess(removeItem.payload.message);

        await dispatch(getSettings({}));
        onDelete();
        onClose();
      }
      if (removeItem.meta.requestStatus === "rejected") {
        notifyError(removeItem.payload);
        console.log("error", removeItem);
        onDelete();
        onClose();
      }
    } catch (error: any) {
      // notifyError(error.message);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Deletion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete {name} of type {type}?
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => handleDeleteRange(id, appRangeData!, valueToRemove, name, type, onDelete, onClose)}
          >
            Delete
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
