import { extendTheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";

import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const NoIcon = definePartsStyle({
  icon: {
    display: "none",
  },
});

const accordionTheme = defineMultiStyleConfig({
  variants: { NoIcon },
});

const theme = {
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      html: {
        "font-size": "16px",
        "font-style": "normal",
        // bg: "white",
      },
      body: {
        margin: 0,
        padding: 0,
        "box-sizing": "border-box",
        "font-family": "Montserrat",
        bg: "#ffffff",
      },

      "*": {
        margin: 0,
        padding: 0,
        "box-sizing": "border-box",
        "font-family": "Montserrat !important",
      },
    }),
  },
  components: { Accordion: accordionTheme },
};

// export const customStyle = {
//   fontSize: {
//     navItem: { base: "0.7rem", md: "1rem" },
//     sideItem: { base: "0.7rem", md: "1.2rem" },
//     logo: { base: "1rem", md: "1.5rem" },
//     logoText: { base: "1rem", md: "1.4rem" },
//     bannerText: { base: "1.5rem", md: "2.8rem" },
//     sectionHeader: { base: "1rem", md: "2rem" },
//     paragragh: { base: "0.7rem", md: "1rem" },
//     cardTitle: { base: "0.9rem", md: "1.4rem" },
//   },
//   fontWeight: {
//     navItem: { base: "600", md: "600" },
//     cardTitle: { base: "700", md: "700" },
//   },
//   padding: {
//     psHome: { base: "1rem", md: "4rem" },
//     peHome: { base: "1rem", md: "4rem" },
//   },
// };

export default extendTheme(theme);
