import { MainLayout } from "@layouts/dashboardLayout/MainLayout";

function index() {
  return (
    <MainLayout>
      <div>
        Dashboard
        <h1>dgdgdgg</h1>
      </div>
    </MainLayout>
  );
}

export default index;
