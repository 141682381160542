import { SetPayload } from "@utils/helper";
import config from "@utils/requestConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addSettings = createAsyncThunk("add-settings", async (settings: any, thunkAPI) => {
  const payload = SetPayload(settings);
  console.log(payload);
  try {
    const Response = await config.post(`add/setting/data`, payload);
    console.log(Response);
    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const getSettings = createAsyncThunk("get-settings", async (settings: any, thunkAPI) => {
  try {
    const payload = SetPayload(settings);
    const Response = await config.post(`get/setting/data`, payload);
    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateSetting = createAsyncThunk("update-settings", async (settings: any, thunkAPI) => {
  try {
    const payload = SetPayload(settings);
    const Response = await config.patch(`edit/setting/data`, payload);
    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteSettings = createAsyncThunk("delete-settings", async (settings: any, thunkAPI) => {
  try {
    const payload = SetPayload(settings);
    const Response = await config.delete(`delete/setting/data`, payload);
    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addAccount = createAsyncThunk("add-account", async (accountDetails: any, thunkAPI) => {
  try {
    const payload = SetPayload(accountDetails);
    const Response = await config.post(`add/app/accounts`, payload);
    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// /add/app / accounts;
