import * as yup from "yup";

export const promotionValidate = yup.object({
  amount: yup.number().required("amount is required"),
  status: yup.string().required("status is required"),
  paymentMethod: yup.string().required("paymentMethod is required"),
  duration: yup
    .number()
    .required("duration is required")
    .integer("Please enter a valid integer")
    .min(1, "Month must be between 1 and 12")
    .max(12, "Month must be between 1 and 12"),
  name: yup.string().required("name is required"),
});

export const enrollmentValidate = yup.object({
  amount: yup.number().required("amount is required"),
  status: yup.string().required("status is required"),
  stage: yup.string().required("stage is required"),
});

export const staffValidate = yup.object({
  first_last: yup.string().required("first_name is required"),
  last_name: yup.string().required("last_name is required"),
  gender: yup.string().required("gender is required"),
  email: yup.string().email().required("email is required"),
  password: yup.string().required("password is required"),
  admin_role: yup.string().required("admin_role is required"),
});

export const creatorTypeValidate = yup.object({
  name: yup.string().uppercase("name should be uppercase").required("name is required"),
  status: yup.string().required("status is required"),
});

export const creatorCustomDataTypeValidate = yup.object({
  name: yup.string().uppercase("Name should be uppercase").required("Name is required"),
  status: yup.string().required("status is required"),
  isRequired: yup.string().required("isRequired is required"),
  dataType: yup.string().required("dataType is required").uppercase("dataType should be uppercase"),
  validtionRequired: yup.string().required("validtionRequired is required"),
  description: yup.string().required("description is required"),
  verifier: yup.string().required(),
});

// verifier: yup
//   .string()
//   .test("verifier-required", 'verifier is required because validtionRequired is "true"', function (value) {
//     const validtionRequired = this.resolve(yup.ref("validtionRequired"));
//     if (validtionRequired === "true") {
//       return typeof value === "string" && value.trim().length > 0;
//     }
//     return true;
//   }),
