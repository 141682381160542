import { SetPayload } from "@utils/helper";
import config from "@utils/requestConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createCreatorType = createAsyncThunk(
  "create-creator-type",
  async (creatorType: any, thunkAPI) => {
    const payload = SetPayload(creatorType);
    try {
      const Response = await config.post(`admin/add/creator/type`, payload);
      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createCreatorTypeData = createAsyncThunk(
  "create-creator-type-data",
  async (creatorTypeData: any, thunkAPI) => {
    const payload = SetPayload(creatorTypeData);
    try {
      const Response = await config.post(`admin/add/creator/customdata`, payload);
      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCreatorType = createAsyncThunk(
  "delete-creator-type",
  async (creatorTypeName: any, thunkAPI) => {
    console.log(creatorTypeName);

    const payload = SetPayload(creatorTypeName);
    console.log(payload.data);

    try {
      const Response = await config.delete(`admin/delete/creator/type`, payload);

      console.log(Response);
      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCreatorType = createAsyncThunk("get-creator-type", async (_, thunkAPI) => {
  // const payload = SetPayload(creatorTypeData);
  try {
    const Response = await config.get(`admin/get/creator/types`);
    console.log(Response.data);

    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateCreatorTypeStatus = createAsyncThunk(
  "update-creator-type-status",
  async (creatorTypeStatus: any, thunkAPI) => {
    const payload = SetPayload(creatorTypeStatus);

    try {
      const Response = await config.patch(`admin/toggle/creator/status`, payload);
      return Response;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOneCreatorType = createAsyncThunk("get-one-creator-type", async (id: string, thunkAPI) => {
  try {
    const Response = await config.get(`admin/get/creator/type/${id}`);
    return Response;
  } catch (error: any) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const getOneCustomCreatorTypeData = createAsyncThunk(
  "get-one-creator-creator-type-data",
  async (name: string, thunkAPI) => {
    try {
      const Response = await config.get(`admin/get/creator/type/${name}`);
      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCustomCreatorTypeData = createAsyncThunk(
  "get-creator-creator-type-data",
  async (id: string, thunkAPI) => {
    try {
      const Response = await config.get(`admin/get/creator/custom/data/${id}`);
      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCustomCreatorTypeData = createAsyncThunk(
  "get-all-custom-creator-type-data",
  async (creatorType: any, thunkAPI) => {
    const payload = SetPayload(creatorType);
    try {
      const Response = await config.get(`get/creator/customdata`, payload);
      return Response;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
