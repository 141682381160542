import React, { useEffect, useState } from "react";
import { MainLayout } from "../../../layouts/dashboardLayout/MainLayout";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  HStack,
  TableContainer,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Button,
  Portal,
  Flex,
  Text,
  Switch,
} from "@chakra-ui/react";
import { UseCustomAppColors } from "../../../utils/color";

import { CreatePromotion } from "./Create";
import { EditPromotion } from "./Edit";
import { InnerSearchHeader } from "@layouts/dashboardLayout/InnerSearchHeader";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import {
  selectPromotion,
  resetPromotionMessage,
  resetPromotionStatus,
} from "@Redux/Slices/EnrollmentAndPromotion/PromotionSlice";
import {
  deletePromotion,
  getPromotions,
  updatePromotion,
} from "@Redux/Services/EnrollmentAndPromotion/PromotionService";
import { useCustomToast } from "@utils/toast";
import { FiEdit } from "react-icons/fi";
import { Font, Weight } from "@utils/fonts";
import {  formatDateToCustomFormat,  } from "@utils/helper";
import { MdDeleteForever } from "react-icons/md";
import { routePath } from "@utils/routes";
import PaginationLayout from "@layouts/dashboardLayout/PaginationLayout";
import { TableList } from "@Skeleton/services";

interface Item {
  id: number;
  name: string;
  email: string;
  test: boolean;
}

function Index() {
  const { inverseSecondBg, textColorInverse, textColor } = UseCustomAppColors();
  const { isOpen: createIsOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<promotionType[]>();
  const [promotionData, setPromotionData] = useState<promotionType[]>();
  const [promotionId, setPromotionId] = useState<string | undefined>();
  const { notifyError, notifySuccess } = useCustomToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  console.log(promotionId);
  const handleFiltedChanges = (filteredData: promotionType[]) => setFilteredData(filteredData);

  const {  isSuccess, message, promotions }: IPromotion = useAppSelector(selectPromotion);

  useEffect(() => {
    dispatch(resetPromotionMessage());
    dispatch(getPromotions());
  }, []);

  useEffect(() => {
    try {
      console.log("loging 3");
      if (isSuccess && message) {
        message !== "" && notifySuccess(message!);
        dispatch(resetPromotionMessage());
        dispatch(resetPromotionStatus());
      }

      if (promotions) {
        setPromotionData(promotions);
        dispatch(resetPromotionMessage());
        dispatch(resetPromotionStatus());
      }
    } catch (error) {
      console.log(error);
    }
  }, [promotions, isLoading]);

  const handleEdit = (promotionId: string | undefined) => {
    setPromotionId(promotionId);
    if (promotionId) {
      editOnOpen();
    }
  };

  const handleStatusChange = async (promotion: promotionType) => {
    try {
      setIsLoading(true);
      let changeStatus = await dispatch(
        updatePromotion({
          status: promotion?.status === "ACTIVE" ? "IN_ACTIVE" : "ACTIVE",
          id: promotion?.id,
        })
      );

      if (changeStatus.meta.requestStatus === "fulfilled") {
        notifySuccess(changeStatus.payload.message);
        dispatch(getPromotions());
        setIsLoading(false);
      }
      if (changeStatus.meta.requestStatus === "rejected") {
        notifySuccess(changeStatus.payload);
        setIsLoading(false);
      }
    } catch (error: any) {
      notifyError(error.message);
    }
  };
  const handleSetPromotion = () => {
    setPromotionId("");
  };

  const handleDeletePromotion = async (promotionId: string | any) => {
    try {
      setIsLoading(true);
      let deletePromo = await dispatch(deletePromotion(promotionId));

      if (deletePromo.meta.requestStatus === "fulfilled") {
        notifySuccess(deletePromo.payload.message);

        setIsLoading(false);
      }
      if (deletePromo.meta.requestStatus === "rejected") {
        notifySuccess(deletePromo.payload);
        setIsLoading(false);
      }
    } catch (error: any) {
      notifyError(error.message);
    }
  };

  let promotionDataDisplyed = filteredData && filteredData?.length > 0 ? filteredData : promotionData;

  let promotionTalbeData =
    promotions &&
    promotionDataDisplyed?.map((promotion, index) => (
      <>
        <Tr key={`${index}_${promotion?.name}`}>
          <Td color={textColor} p={"1.5"}>
            {index + 1}
          </Td>
          <Td
            color={textColor}
            p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.SMALL}
          >
            {" "}
            {promotion?.name}
          </Td>
          <Td
            color={textColor}
            p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {promotion?.paymentMethod}
          </Td>
          <Td
            color={textColor}
            p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {promotion?.duration} Month
          </Td>
          <Td
            isNumeric
            px={"3.5"}
            alignItems={"center"}
            color={textColor}
            p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {promotion?.amount}
          </Td>
          <Td
            color={textColor}
            p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {promotion?.status}
          </Td>
          <Td
            color={textColor}
            p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {/* {CurrentTime(promotion?.createdAt)} */}
            {formatDateToCustomFormat(promotion?.createdAt)}
          </Td>

          <Td
            color={textColor}
            p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            <Popover placement="left-start">
              <PopoverTrigger>
                <Button variant={"unstyled"}>Action</Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent w={["140px", "150px", "180px"]}>
                  <PopoverArrow />

                  <PopoverBody boxShadow={"0px 0px 4px #808080"} rounded={["md", "md", "2xl"]}>
                    <Flex flexDir={"column"}>
                      <Button colorScheme="gray" variant={"unstyled"}>
                        <HStack justifyContent={"space-between"}>
                          <Text mr={["5px", "6px"]}>Name</Text>
                          <Button onClick={() => handleEdit(promotion.id)} variant={"unstyled"}>
                            {" "}
                            <FiEdit />
                          </Button>
                        </HStack>
                      </Button>
                      <Button colorScheme="gray" variant={"unstyled"}>
                        <HStack justifyContent={"space-between"}>
                          <Text mr={["4px"]}>Delete</Text>
                          <Button onClick={() => handleDeletePromotion(promotion.id)} variant={"unstyled"}>
                            {" "}
                            <MdDeleteForever fontSize={"1.4rem"} color="red" />
                          </Button>
                        </HStack>
                      </Button>

                      <Button colorScheme="gray" variant={"unstyled"}>
                        <HStack justifyContent={"space-between"}>
                          <Text mr={["5px", "6px"]}>Status</Text>
                          <Switch
                            size="lg"
                            isChecked={promotion.status === "ACTIVE" ? true : false}
                            onChange={() => handleStatusChange(promotion)}
                          />
                        </HStack>
                      </Button>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Td>
        </Tr>
      </>
    ));

  return (
    <MainLayout>
      <InnerSearchHeader
        onOpen={createOnOpen}
        title="Promotion"
        isCreate={true}
        searches={["name", "status", "duration"]}
        data={promotionData}
        setFilteredData={handleFiltedChanges}
        extraHeadersButton={[{ path: routePath.reset_password, DisplayedName: "extraHeaders" }]}
      />
      <Box maxH={"100%"}>
        <TableContainer
          css={{
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
              borderRadius: "24px",
            },
          }}
        >
          <Table variant="simple" overflowY={"scroll"}>
            <Thead bg={inverseSecondBg} color={textColorInverse} p={"2"}>
              <Tr>
                <Th color={textColorInverse}>S/N</Th>
                <Th color={textColorInverse}>Name</Th>
                <Th color={textColorInverse}>Payment Method</Th>
                <Th color={textColorInverse}>Duration</Th>
                <Th isNumeric color={textColorInverse}>
                  Amount
                </Th>
                <Th color={textColorInverse}>Status</Th>
                <Th color={textColorInverse}>Created At</Th>

                <Th color={textColorInverse}>Action</Th>
              </Tr>
            </Thead>
            <Tbody overflowX={"scroll"} minH={"100%"} alignItems={"flex-start"}>
              {promotionTalbeData ? promotionTalbeData : <TableList />}
              {/* {tableSkeletons} */}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
          <CreatePromotion
            isOpen={createIsOpen}
            onOpen={createOnOpen}
            onClose={createOnClose}
            tittle="Promotion"
          />
          <EditPromotion
            isOpen={editIsOpen}
            onOpen={editOnOpen}
            onClose={editOnClose}
            tittle="Promotion"
            promotionId={promotionId}
            setPromotionId={handleSetPromotion}
          />
        </TableContainer>
      </Box>
      <PaginationLayout />
    </MainLayout>
  );
}

export default Index;
