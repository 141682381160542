import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Heading,
  HStack,
  Button,
  Flex,
  Stack,
  useDisclosure,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";
import { UseCustomAppColors } from "@utils/color";

import { CustomInputMain } from "@components/modals/CustomInputMain";
import { useEffect, useState } from "react";
import { IoIosRemoveCircle } from "react-icons/io";

import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import {
  resetSettings,
  resetSettingsMessage,
  selectAppSettings,
} from "@Redux/Slices/AppSetting/appsettingSlices";

import { getSettings } from "@Redux/Services/AppSetting/appsetting";
import { BsThreeDots } from "react-icons/bs";

import { onlySlugUnderscores, SlugUnderscores } from "@utils/helper";

import { MainLayout2 } from "@layouts/dashboardLayout/MainLayout2";
import { RemoveItemRangeItem } from "./AppSettingComponentsForRange/RemoveItemInRange";
import { FiEdit } from "react-icons/fi";
import { MdAddCircleOutline } from "react-icons/md";
import { EditAppRange } from "./AppSettingComponentsForRange/EditRange";
import { AddNewAppRange } from "./AppSettingComponentsForRange/CreateRange";

function Index() {
  const { isOpen: removeIsOpen, onOpen: removeOnOpen, onClose: removeOnClose } = useDisclosure();
  const { isOpen: createIsOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();
  const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  const { inverseSecondBg, textColor, secondBg } = UseCustomAppColors();
  const [appRangeData, setAppRangeData] = useState<AppRange[] | null>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToRemoveItem, setItemToRemoveItem] = useState<{
    id: string;
    valueToRemove: any;
    name: string;
    type: string;
  } | null>();

  const [valueToEdit, setValueToEdit] = useState<string | number>();

  const [editItem, setEditItem] = useState<AppRange | null>();

  const dispatch = useAppDispatch();
  const { appRange }: IAppSettings = useAppSelector(selectAppSettings);
  useEffect(() => {
    dispatch(resetSettingsMessage());
    dispatch(getSettings({}));
    dispatch(resetSettings());
  }, []);

  const handleDelete = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    try {
      if (appRange) {
        setAppRangeData(appRange);
      }
    } catch (error) {}
  }, [appRange]);

  let TabsForAppRangeForSmallScreen = appRangeData?.map((appRange: AppRange, index: number) => {
    return (
      <Tab
        _selected={{ color: textColor, bg: secondBg }}
        key={`${appRange.type}_${index}`}
        minW={"200px"}
        boxShadow={"0px 0px 4px #b7b7b7"}
      >
        {" "}
        {onlySlugUnderscores(appRange.name!)}
      </Tab>
    );
  });

  let TabsForAppRange = appRangeData?.map((appRange: AppRange, index: number) => {
    return (
      <Tab
        key={`${appRange.type}_${index}`}
        _selected={{ color: "white", bg: inverseSecondBg }}
        display={"flex"}
        justifyContent={"center"}
        onClick={() => {
          console.log(appRange.type!);
        }}
      >
        <Box
          w={"5"}
          h={"5"}
          bg={inverseSecondBg}
          rounded={"full"}
          outline={"solid"}
          outlineOffset={"2px"}
          p={"2"}
          mr={"2"}
        ></Box>
        <Text textTransform={"capitalize"} w={"60%"}>
          {SlugUnderscores(appRange.name!)}
        </Text>
      </Tab>
    );
  });

  let listTabsForAppRange = appRangeData?.map((appRange: AppRange, index: number) => {
    return (
      <>
        <TabPanel key={`${appRange.type}_${index}`}>
          <Box key={`${appRange.type}_${index}`} padding={"1"} py="1" pb={"12"}>
            <Flex justify={"space-between"} boxShadow={"base"} py={"3"} px={"5"}>
              <Flex justify={"flex-start"}>
                <HStack mr={"3"}>
                  <Heading fontWeight={"medium"} fontSize={["13px", "18px", "22px"]} mr={"1"}>
                    Name:
                  </Heading>
                  <Text
                    textTransform={"capitalize"}
                    fontWeight={"medium"}
                    fontSize={["11px", "14px", "18px"]}
                  >
                    {onlySlugUnderscores(appRange.name!)}
                  </Text>
                </HStack>

                <HStack>
                  <Heading fontWeight={"medium"} fontSize={["13px", "18px", "22px"]} mr={"1"}>
                    Type:
                  </Heading>
                  <Text
                    textTransform={"capitalize"}
                    fontWeight={"medium"}
                    fontSize={["11px", "14px", "18px"]}
                  >
                    {onlySlugUnderscores(appRange.type!)}
                  </Text>
                </HStack>
              </Flex>

              <Button
                colorScheme="gray"
                variant={"unstyled"}
                onClick={() => {
                  setEditItem(
                    appRangeData?.find(
                      (range: AppRange) => range.name === appRange.name && range.type === appRange.type
                    ) || null
                  );
                  return createOnOpen();
                }}
              >
                <MdAddCircleOutline fontSize={"2.2rem"} />
              </Button>
            </Flex>
            <Box mt={"5"} boxShadow={"base"} py={"3"} px={"5"}>
              {appRange.value &&
                Object.entries(appRange?.value).map(([key, valueObj], index) => (
                  <>
                    <Stack
                      key={`${key}_${index}`}
                      direction={["column", "column", "row", "row"]}
                      align={"flex-end"}
                    >
                      <CustomInputMain
                        label="Name"
                        name="name"
                        value={key}
                        isReadOnly={true}
                        color={"gray.400"}
                      />
                      <CustomInputMain
                        label="Start Range"
                        name="start"
                        value={valueObj.start}
                        color={"gray.400"}
                        isReadOnly={true}
                      />
                      <CustomInputMain
                        label="End Range"
                        name="end"
                        value={valueObj.end}
                        isReadOnly={true}
                        color={"gray.400"}
                      />

                      <Popover placement="left-start">
                        <PopoverTrigger>
                          <Button variant={"unstyled"}>
                            <BsThreeDots fontSize={"1.6rem"} />
                          </Button>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent w={["140px", "150px", "180px"]}>
                            <PopoverArrow />
                            <PopoverBody boxShadow={"0px 0px 4px #808080"} rounded={["md", "md", "2xl"]}>
                              <Flex flexDir={"column"}>
                                <Button
                                  colorScheme="gray"
                                  variant={"unstyled"}
                                  onClick={() => {
                                    setEditItem(
                                      appRangeData?.find(
                                        (range: AppRange) =>
                                          range.name === appRange.name && range.type === appRange.type
                                      ) || null
                                    );
                                    setValueToEdit(key);
                                    return editOnOpen();
                                  }}
                                >
                                  <HStack justifyContent={"space-between"}>
                                    <Text mr={["5px", "6px"]}>Edit</Text>
                                    <Button variant={"unstyled"}>
                                      {" "}
                                      <FiEdit />
                                    </Button>
                                  </HStack>
                                </Button>

                                <Button
                                  colorScheme="gray"
                                  variant={"unstyled"}
                                  onClick={() => {
                                    setItemToRemoveItem({
                                      valueToRemove: key,
                                      id: appRange.id!,
                                      name: appRange.name!,
                                      type: appRange.type!,
                                    });
                                    setIsModalOpen(true);
                                    removeOnOpen();
                                  }}
                                >
                                  <HStack justifyContent={"space-between"}>
                                    <Text mr={["4px"]}>Remove</Text>
                                    <Button variant={"unstyled"}>
                                      {" "}
                                      <IoIosRemoveCircle fontSize={"1.8rem"} />
                                    </Button>
                                  </HStack>
                                </Button>
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Portal>
                      </Popover>
                    </Stack>
                  </>
                ))}
            </Box>
          </Box>
        </TabPanel>
      </>
    );
  });

  return (
    <MainLayout2>
      <Box display={["none", "none", "none", "flex"]} flexDir={"row"}>
        <Tabs w={"100%"}>
          <Box justifyContent={"space-between"} display={"flex"} minH={"85vh"}>
            <Box w={"20%"} bg={secondBg} py={"20"}>
              <TabList display={"flex"} flexDir={"column"}>
                {TabsForAppRange}
              </TabList>
            </Box>
            <Box w={"78%"} bg={secondBg}>
              <TabPanels>{listTabsForAppRange}</TabPanels>
            </Box>
          </Box>
        </Tabs>
      </Box>
      <Box display={["flex", "flex", "flex", "none"]}>
        <Tabs>
          <Box overflowX={"scroll"} maxW={["300px", "500px"]}>
            {" "}
            <TabList>{TabsForAppRangeForSmallScreen}</TabList>
          </Box>

          <TabPanels display={"flex"} flexDir={"row"} flexWrap={"wrap"}>
            {listTabsForAppRange}
          </TabPanels>
        </Tabs>
      </Box>
      {/* <PaginationLayout /> */}

      <RemoveItemRangeItem
        id={itemToRemoveItem ? itemToRemoveItem?.id : ""}
        valueToRemove={itemToRemoveItem ? itemToRemoveItem?.valueToRemove : ""}
        name={itemToRemoveItem ? itemToRemoveItem?.name : ""}
        type={itemToRemoveItem ? itemToRemoveItem?.type : ""}
        isOpen={removeIsOpen}
        onOpen={removeOnOpen}
        onClose={removeOnClose}
        onDelete={handleDelete}
      />

      <EditAppRange
        isOpen={editIsOpen}
        onOpen={editOnOpen}
        onClose={editOnClose}
        editData={editItem!}
        valueToEdit={valueToEdit!}
      />

      <AddNewAppRange
        editData={editItem!}
        isOpen={createIsOpen}
        onClose={createOnClose}
        onOpen={createOnOpen}
      />
    </MainLayout2>
  );
}

export default Index;
