import { useState, useEffect, useRef } from "react";
import AuthLayout from "@layouts/authLayout/AppAuthlayout";
import { UseCustomAppColors } from "@utils/color";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, Input, Stack, Text } from "@chakra-ui/react";

import { useFormik } from "formik";
import { otpValue } from "@Schema/schema";
import { otpSchema } from "@Validation/authValidation";
import { Weight, inputFontSize } from "@utils/fonts";

import "./auth.styles.css";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import { AdminAuthStageOne, resetAuthStore, resetMessage } from "@Redux/Slices/Auth/authSlices";
import { AdminLoginStageTwo } from "@Redux/Services/Auth/authSevices";
import { routePath } from "@utils/routes";
import { useCustomToast } from "@utils/toast";

import { AdminConstants, DefaultConstants } from "@Redux/Services/Constants/constantsService";

export default function VerifyLoginOTP() {
  const { inverseSecondBg, secondBg, textColor, textColorInverse, HeaderColor } = UseCustomAppColors();
  const { notifyError, notifySuccess } = useCustomToast();
  const AuthStageOne: IAuthState = useAppSelector(AdminAuthStageOne);
  const [loading, setLoading] = useState<boolean>(false);

  const [user, setUser] = useState<AuthData | undefined>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const intervalRef = useRef<number | null>(null);
  const timeRef = useRef<number>(600);

  const handleIntervalClear = () => {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    try {
      if (AuthStageOne.authStageOne === null) {
        navigate(routePath.login);
      }
      const intervalId = window.setInterval(() => {
        timeRef.current -= 1;

        console.log(timeRef.current);

        if (timeRef.current === 0) {
          handleIntervalClear();
          dispatch(resetAuthStore());
          navigate(routePath.login);
        }
      }, 1000);
      intervalRef.current = intervalId;

      return () => {
        handleIntervalClear();
      };
    } catch (error) {
      console.log(error as TypeError);
    }
  }, []);

  useEffect(() => {
    try {
      console.log("effect 2");

      if (AuthStageOne.isSuccess && AuthStageOne.user !== null) {
        setLoading(false);
        setUser(AuthStageOne?.user);
        AuthStageOne?.message !== "" && notifySuccess(AuthStageOne?.message!);
        navigate(routePath.dashboard);
      }

      if (AuthStageOne.isError && AuthStageOne.message !== "") {
        setLoading(false);
        AuthStageOne?.message !== "" && notifyError(AuthStageOne?.message!);
        dispatch(resetMessage());
      }
    } catch (error) {
      console.log(error as TypeError);
    }
  }, [AuthStageOne.user]);

  const dispatchForConstants = async () => {
    try {
      dispatch(AdminConstants());
      dispatch(DefaultConstants());
    } catch (error) {
      console.error("Error fetching status:", error);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (AuthStageOne.user?.access_token) {
          dispatchForConstants();
        }
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, [user, dispatch]);

  const AdminLogin = useFormik({
    initialValues: otpValue,
    validationSchema: otpSchema,
    onSubmit: (value, action) => {
      dispatch(AdminLoginStageTwo({ otp: value.otp }));
      action.resetForm();
    },
  });
  return (
    <AuthLayout>
      <Flex
        w={"100%"}
        bg={secondBg}
        flexDir={"column"}
        boxShadow={"base"}
        p="6"
        rounded="lg"
        m={"auto"}
        px={{ md: "14", lg: "20" }}
        maxW={["400px", "700px", "1200px"]}
      >
        {/* <Box> */}
        <Text w={"100%"} color={HeaderColor} fontSize={["16px", "30px", "40px"]} fontWeight={Weight.MEDIUM}>
          Verify Identity with OTP
        </Text>
        {/* </Box> */}

        <Box>
          <form onSubmit={AdminLogin.handleSubmit}>
            <Flex flexDir={"column"} pt={"3.5rem"}>
              <Stack spacing={"2"} mb={["8", "12"]}>
                <Input
                  width={"100%"}
                  height={["35px", "55px"]}
                  placeholder="Enter OTP Sent To Your Email"
                  borderColor={textColor}
                  fontSize={[inputFontSize.base, inputFontSize.md]}
                  onChange={AdminLogin.handleChange}
                  value={AdminLogin.values.otp}
                  onBlur={AdminLogin.handleBlur}
                  name="otp"
                />
                {AdminLogin.errors.otp && AdminLogin.touched.otp && (
                  <Text className="error">{AdminLogin.errors.otp}</Text>
                )}
              </Stack>
            </Flex>
            <Flex flexDir={"column"} width={"100%"}>
              <Flex justifyContent={"right"}>
                <Button
                  fontSize={[inputFontSize.base, inputFontSize.md]}
                  py={[".6rem", "1.8rem"]}
                  px={["2rem", "4rem"]}
                  bg={inverseSecondBg}
                  color={textColorInverse}
                  borderRadius={"base"}
                  type="submit"
                  isLoading={loading}
                  borderWidth={".1rem"}
                  _hover={{ bg: inverseSecondBg }}
                >
                  Verify Identity
                </Button>
              </Flex>
            </Flex>
          </form>
        </Box>
      </Flex>
    </AuthLayout>
  );
}
