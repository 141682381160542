import React  from "react";
import { Box, HStack, Button, Text } from "@chakra-ui/react";
import { UseCustomAppColors } from "@utils/color";

type Props = {
  page?: number;
  totalRecord?: number;
  onPageChange?: (page: number) => void;
};

function PaginationLayout(props: Props) {
  const { inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const { page, totalRecord, onPageChange } = props;

  const pageSize = 30;

  const totalPages = Math.ceil(totalRecord! / pageSize);

  const handlePrevPage = () => {
    if (page && page > 1) {
      onPageChange && onPageChange(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page && page < totalPages) {
      onPageChange && onPageChange(page + 1);
    }
  };

  return (
    <Box p={["3", "5"]}>
      <HStack alignItems={"center"} justify={"flex-end"}>
        {page && page > 1 && (
          <Button
            mb={"2"}
            mx={{ md: "2", lg: "1" }}
            bg={inverseSecondBg}
            color={textColorInverse}
            onClick={handlePrevPage}
            _hover={{ bg: { inverseSecondBg }, color: { textColorInverse } }}
          >
            Previous
          </Button>
        )}
        {page && page < totalPages && (
          <Button
            mb={"2"}
            mx={{ md: "2", lg: "1" }}
            bg={inverseSecondBg}
            color={textColorInverse}
            onClick={handleNextPage}
            _hover={{ bg: { inverseSecondBg }, color: { textColorInverse } }}
          >
            Next
          </Button>
        )}

        <Text>{`Page ${page} of ${totalPages}`}</Text>
        <Text>{`${totalRecord} records`}</Text>
      </HStack>
    </Box>
  );
}

export default React.memo(PaginationLayout);
