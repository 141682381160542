import VerifyOtp from "@pages/Auth/VerifyLoginOTP";
import ForgotPassword from "@pages/Auth/Forgot_password";
import ResetPassword from "@pages/Auth/Reset_password";

import Home from "@components/modals/Home";
import Login from "@pages/Auth/Login";

// icon import

import { IoPerson } from "react-icons/io5";
import { GoHomeFill } from "react-icons/go";
import { BiLogOutCircle } from "react-icons/bi";

import { BsSendPlusFill } from "react-icons/bs";
import { BsFillSendCheckFill } from "react-icons/bs";
import { BsSendFill } from "react-icons/bs";
import { BsPersonVideo2 } from "react-icons/bs";

import { IoPersonSharp } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";
import { BsCalendarRange } from "react-icons/bs";
import { PiKeyboardFill } from "react-icons/pi";
import { MdAccountBalance } from "react-icons/md";

// protected routes
import Dashboard from "@pages/Dashboard/Main";
import Agency from "@pages/Dashboard/Agency/Index";
import Promotion from "@dashboardPages/Promotion/Index";
import PromotedProjects from "@dashboardPages/Promotion/PromotedProjects";
import Enrollment from "@pages/Dashboard/Enrollment/Index";
import Staffs from "@pages/Dashboard/Staff/Index";
import Staff from "@pages/Dashboard/Staff/Detail";
import Creators from "@pages/Dashboard/Creators/Index";
import CreatorCustomType from "@pages/Dashboard/Creators/CreatorCustomIndex";

import AppRangeSettings from "@pages/Dashboard/AppSetting/AppRangeSettings";
import AppTypesSettings from "@pages/Dashboard/AppSetting/AppTypesSettings";
import AppAccountSettings from "@pages/Dashboard/AppSetting/AppAccountSettings";

export const routePath = {
  home: "/",
  login: "/auth/admin/login",
  register: "/auth/admin/register",
  forgot_password: "/auth/admin/forgot_password",
  reset_password: "/auth/admin/reset_password",
  verify_otp: "/auth/admin/verify_otp",
  // Protected routes
  dashboard: "/admin/dashboard",
  promotion: "/admin/promotion/list",
  promotedProject: "/admin/promotion/promoted-projects",
  enrollment: "/admin/enrollment/list",
  agency: "/admin/Agency/list",
  staffs: "/admin/staffs/list",
  staff: "/admin/staffs/list/",

  creators: "/admin/creators/list",
  creatorCustomType: "/admin/creators/list/",
  appSettings: "/admin/settings/",
  appRangeSettings: "/admin/settings/ranges",
};

export const unProtectedRoutes: routeList[] = [
  {
    path: routePath.home,
    component: Home,
  },
  // {
  //   path: routeObj.privacy,
  //   component: Privacy,
  // },
  // {
  //   path: routeObj.terms,
  //   component: Terms,
  // },
  // {
  //   path: `${routeObj.courseDetail}:code`,
  //   component: CourseDetail,
  // },
  // {
  //   path: routeObj.verifyEmail,
  //   component: VerifyEmail,
  // },
];

export const authRoute: routeList[] = [
  // {
  //   path: routeObj.register,
  //   component: Register,
  // },
  {
    path: routePath.login,
    component: Login,
  },
  {
    path: routePath.verify_otp,
    component: VerifyOtp,
  },

  {
    path: routePath.forgot_password,
    component: ForgotPassword,
  },
  {
    path: routePath.reset_password,
    component: ResetPassword,
  },
];

export const protectedRoutes: nextedRouteList[] = [
  {
    path: routePath.dashboard,
    component: Dashboard,
    icon: GoHomeFill,
    name: "Dashboard",
    children: [],
  },

  {
    path: routePath.promotion,
    component: Promotion,
    icon: BsSendFill,
    name: "Promotion",
    parentName: "Promotion",
    children: [
      {
        path: routePath.promotion,
        component: Promotion,
        icon: BsSendPlusFill,
        show: true,
        name: "Promotion List",
        children: [],
      },
      {
        path: routePath.promotedProject,
        component: PromotedProjects,
        icon: BsFillSendCheckFill,
        name: "Promoted Projects",
        children: [],
      },
    ],
  },
  {
    path: routePath.enrollment,
    component: Enrollment,
    icon: BsPersonVideo2,
    name: "Enrollment",
  },
  {
    path: routePath.agency,
    component: Agency,
    icon: GoHomeFill,
    name: "Agency",
    children: [],
  },

  {
    path: routePath.staffs,
    component: Staffs,
    icon: IoPersonSharp,
    name: "Staffs",
    parentName: "Staffs",
    children: [],
  },

  {
    path: routePath.creators,
    component: Creators,
    icon: GoHomeFill,
    name: "Creators",
    children: [],
  },

  {
    path: routePath.appSettings,
    component: AppRangeSettings,
    icon: IoSettings,
    name: "Settings",
    parentName: "Settings",
    children: [
      {
        path: `${routePath.appSettings}ranges`,
        component: AppRangeSettings,
        icon: BsCalendarRange,
        show: true,
        name: "Range",
        children: [],
      },
      {
        path: `${routePath.appSettings}types`,
        component: AppTypesSettings,
        icon: PiKeyboardFill,
        show: true,
        name: "Type",
        children: [],
      },
      {
        path: `${routePath.appSettings}accounts`,
        component: AppAccountSettings,
        icon: MdAccountBalance,
        show: true,
        name: "Account",
        children: [],
      },
    ],
  },
];

export const protectedAppRoutes: nextedRouteList[] = [
  {
    path: routePath.dashboard,
    component: Dashboard,
    name: "Dashboard",
    children: [],
  },
  {
    path: routePath.promotion,
    component: Promotion,
    name: "Promotion",
    children: [],
  },

  {
    path: routePath.promotedProject,
    component: PromotedProjects,
    name: "Promoted Projects",
    children: [],
  },

  {
    path: routePath.enrollment,
    component: Enrollment,
    name: "Enrollment",
  },
  {
    path: routePath.agency,
    component: Agency,
    name: "Agency",
    children: [],
  },
  {
    path: routePath.staffs,
    component: Staffs,
    name: "Staffs",
    children: [
      {
        path: `:id`,
        component: Staff,
        name: "Staff",
      },
    ],
  },
  {
    path: routePath.creators,
    component: Creators,
    icon: GoHomeFill,
    name: "creator",
    children: [
      {
        path: `:name`,
        component: CreatorCustomType,
        name: "CreatorCustomType",
      },
    ],
  },

  // {
  //   path: routePath.appSettings,
  //   component: AppRangeSettings,
  //   name: "Settings",
  // },

  {
    path: `${routePath.appSettings}ranges`,
    component: AppRangeSettings,
    name: "Range",
  },
  {
    path: `${routePath.appSettings}types`,
    component: AppTypesSettings,
    name: "Type",
  },
  {
    path: `${routePath.appSettings}accounts`,
    component: AppAccountSettings,
    name: "Account",
  },
];

export const protectedRoutesExtra: nextedRouteList[] = [
  {
    path: routePath.dashboard,
    component: Dashboard,
    icon: IoPerson,
    name: "Profile",
    children: [],
  },

  {
    path: routePath.dashboard,
    component: Dashboard,
    icon: IoPerson,
    name: "Profile",
    children: [],
  },

  {
    path: routePath.dashboard,
    component: Dashboard,
    icon: BiLogOutCircle,
    name: "LogOut",
    children: [],
  },
];
