import config from "@utils/requestConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const AdminConstants = createAsyncThunk("constants/admin-Constants", async (_, thunkAPI) => {
  try {
    const Response = await config.get(`auth/admin/constants`);

    return Response;
  } catch (error: any) {
    console.log(error);

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});

export const DefaultConstants = createAsyncThunk("constants/default-constants", async (_, thunkAPI) => {
  try {
    const Response = await config.get(`constants`);
    return Response;
  } catch (error: any) {
    console.log(error);

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.log(message);
    return thunkAPI.rejectWithValue(message);
  }
});
