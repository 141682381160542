import { Button, IconButton } from "@chakra-ui/button";
import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Box, Flex, Heading } from "@chakra-ui/layout";
import { UseCustomAppColors } from "@utils/color";
import React, { useState } from "react";
import { Link } from "react-router-dom";

type extraHeadersButtonType = { path: string; DisplayedName: string };

type InnerSearchHeaderProps = {
  title: string;
  isCreate?: boolean;
  onOpen?: () => void;
  data?: any;
  searches: string[];
  setFilteredData: (filteredData: any[]) => void;
  extraHeadersButton?: extraHeadersButtonType[];
};

export const InnerSearchHeader = React.memo(
  ({
    title,
    onOpen,
    data,
    searches,
    setFilteredData,
    extraHeadersButton,
    isCreate,
  }: InnerSearchHeaderProps) => {
    const { inverseSecondBg, secondBg, textColorInverse, textColor } = UseCustomAppColors();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [searchedData, setSearchedData] = useState<any | undefined>(data);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchTerm = event.target.value;
      setSearchTerm(searchTerm);

      if (data !== undefined) {
        const filteredDataArray = searches.map((search: string) =>
          data?.filter((item: any) =>
            (item as any)[search]?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          )
        );
        const combinedFilteredData = filteredDataArray.reduce((acc, curr: []) => {
          return curr.length > 0 ? curr : acc;
        }, []);
        setSearchedData(combinedFilteredData);
        setFilteredData(combinedFilteredData);
      }
    };

    // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   const searchTerm = event.target.value;
    //   setSearchTerm(searchTerm);

    //   if (data !== undefined) {
    //     const filteredDataArray = searches.map((search: string) =>
    //       data.filter((item: any) => (item as any)[search].toLowerCase().includes(searchTerm.toLowerCase()))
    //     );

    //     const filteredDataArrayFlat = new Set([].concat(...filteredDataArray));
    //     let uniqueValuesArray = Array.from(filteredDataArrayFlat);

    //     setSearchedData(uniqueValuesArray);
    //     console.log(searchedData);
    //     setFilteredData(uniqueValuesArray);
    //   }
    // };

    return (
      <Box>
        <Heading fontSize={"3xl"} mb={"2rem"}>
          {` Puzzel ${title}`}
        </Heading>
        <Flex justify={"space-between"} alignItems={"center"} flexDir={["column", "column", "column", "row"]}>
          <Box width={["95%", "95%", "95%", "40%"]}>
            <InputGroup size="md" mb={["2", "2", "2", "5"]}>
              <Input pr="5rem" type="text" onChange={handleSearch} value={searchTerm} placeholder="Search" />
              <InputRightElement width={"3rem"}>
                <IconButton
                  aria-label="Search"
                  icon={<SearchIcon />}
                  variant={"outline"}
                  border={"none"}
                  // onChange={handleSearch}
                  _hover={{ bg: "transparent" }}
                />
              </InputRightElement>
            </InputGroup>
          </Box>
          <Box display={"flex"} flexDir={"row"} flexWrap={"wrap"}>
            {extraHeadersButton &&
              extraHeadersButton?.map((element) => (
                <Button
                  mb={"2"}
                  mx={["2", "2", "1", "1"]}
                  variant={"outline"}
                  borderColor={textColor}
                  bg={secondBg}
                  borderWidth={"thin"}
                  onClick={onOpen}
                  color={textColor}
                  _hover={{ bg: { inverseSecondBg }, color: { textColorInverse } }}
                >
                  {<Link to={element.path}>{element.DisplayedName}</Link>}
                </Button>
              ))}
            {isCreate && (
              <Button
                mb={"2"}
                mx={{ md: "2", lg: "1" }}
                bg={inverseSecondBg}
                onClick={onOpen}
                color={textColorInverse}
                _hover={{ bg: { inverseSecondBg }, color: { textColorInverse } }}
              >
                {`Create ${title}`}
              </Button>
            )}
          </Box>
        </Flex>
      </Box>
    );
  }
);
