import React, { useEffect, useState } from "react";
import { MainLayout } from "@layouts/dashboardLayout/MainLayout";
import { Box, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer, useDisclosure } from "@chakra-ui/react";
import { UseCustomAppColors } from "@utils/color";

import { InnerSearchHeader } from "@layouts/dashboardLayout/InnerSearchHeader";
import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import {
  selectPromotion,
  resetPromotionMessage,
  resetPromotionStatus,
} from "@Redux/Slices/EnrollmentAndPromotion/PromotionSlice";
import { getPromotedAsset } from "@Redux/Services/EnrollmentAndPromotion/PromotionService";
import { useCustomToast } from "@utils/toast";

import { Font, Weight } from "@utils/fonts";
import { formatDateToCustomFormat } from "@utils/helper";

import PaginationLayout from "@layouts/dashboardLayout/PaginationLayout";

function Index() {
  const { inverseSecondBg, textColorInverse, textColor } = UseCustomAppColors();
  const { isOpen: createIsOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();
  // const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<promotionType[]>();
  const [promotionData, setPromotionData] = useState<promotionType[]>();
  // const [promotionId, setPromotionId] = useState<string | undefined>();
  const { notifySuccess } = useCustomToast();
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleFiltedChanges = (filteredData: promotionType[]) => setFilteredData(filteredData);

  const { isLoading, isSuccess, message, promotions }: IPromotion = useAppSelector(selectPromotion);

  useEffect(() => {
    dispatch(resetPromotionMessage());
    dispatch(getPromotedAsset());
  }, []);

  useEffect(() => {
    try {
      console.log("loging 3");
      if (isSuccess && message) {
        message !== "" && notifySuccess(message!);
        dispatch(resetPromotionMessage());
        dispatch(resetPromotionStatus());
      }

      if (promotions) {
        setPromotionData(promotions);
        dispatch(resetPromotionMessage());
        dispatch(resetPromotionStatus());
      }
    } catch (error) {
      console.log(error);
    }
  }, [promotions, isLoading]);

  let promotionDataDisplyed = filteredData && filteredData?.length > 0 ? filteredData : promotionData;

  let promotionTalbeData =
    promotions &&
    promotionDataDisplyed?.map((promotion, index) => (
      <>
        <Tr key={`${index}_${promotion?.name}`}>
          <Td color={textColor} p={"1.5"}>
            {index + 1}
          </Td>
          <Td
            color={textColor}
            // p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.SMALL}
          >
            {" "}
            {promotion?.name}
          </Td>
          <Td
            color={textColor}
            // p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {promotion?.paymentMethod}
          </Td>
          <Td
            color={textColor}
            // p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {promotion?.duration} Month
          </Td>
          <Td
            isNumeric
            // px={"3.5"}
            alignItems={"center"}
            color={textColor}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {promotion?.amount}
          </Td>
          <Td
            color={textColor}
            // p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {promotion?.status}
          </Td>
          <Td
            color={textColor}
            // p={"1.5"}
            fontSize={[Font.PRIMARY, Font.MIN_SECONDARY]}
            fontWeight={Weight.MIN_SMALL}
          >
            {/* {CurrentTime(promotion?.createdAt)} */}
            {formatDateToCustomFormat(promotion?.createdAt)}
          </Td>
        </Tr>
      </>
    ));

  return (
    <MainLayout>
      <InnerSearchHeader
        onOpen={createOnOpen}
        title="Promoted Projects"
        searches={["name", "status", "duration"]}
        data={promotionData}
        setFilteredData={handleFiltedChanges}
      />
      <Box maxH={"100%"}>
        <TableContainer
          css={{
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              width: "2px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
              borderRadius: "24px",
            },
          }}
        >
          <Table variant="simple" overflowY={"scroll"}>
            <Thead bg={inverseSecondBg} color={textColorInverse} p={"2"}>
              <Tr>
                <Th color={textColorInverse}>S/N</Th>
                <Th color={textColorInverse}>Name</Th>
                <Th color={textColorInverse}>Payment Method</Th>
                <Th color={textColorInverse}>Duration</Th>
                <Th isNumeric color={textColorInverse}>
                  Amount
                </Th>
                <Th color={textColorInverse}>Status</Th>
                <Th color={textColorInverse}>Created At</Th>

                {/* <Th color={textColorInverse}>Action</Th> */}
              </Tr>
            </Thead>
            <Tbody overflowX={"scroll"} minH={"100%"} alignItems={"flex-start"}>
              {promotionTalbeData}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
        </TableContainer>
      </Box>
      <PaginationLayout />
    </MainLayout>
  );
}

export default Index;
