import { Flex, Skeleton } from "@chakra-ui/react";

export const SearchCenters = () => {
  return (
    <Flex minH={"300px"} w={"100%"} justify={"space-between"} flexDir={{ base: "column", md: "column" }}>
      <Flex
        w={"100%"}
        h={"100px"}
        // bg={"red"}
        justify={"space-between"}
        align={"center"}
        // mx={}
        my={15}
      >
        <Skeleton w={{ base: "150px", md: "200px" }} h={"60px"} ml={{ base: 0, md: 2 }} rounded={"xl"} />
        <Skeleton w={"100px"} h={"60px"} mr={{ base: 0, md: 2 }} rounded={"xl"} />
      </Flex>
      <Flex minH={"150px"} w={"100%"} justify={"space-between"} flexDir={{ base: "row", md: "row" }}>
        <Skeleton borderRadius={"15px"} h={"180px"} w={{ base: "47%", md: "32%" }}></Skeleton>
        <Skeleton
          borderRadius={"15px"}
          h={"180px"}
          w={{ base: "47%", md: "32%" }}
          mt={{ base: 0, md: 0 }}
        ></Skeleton>
        <Skeleton
          display={{ base: "none", md: "block" }}
          borderRadius={"15px"}
          h={"180px"}
          w={{ base: "100%", md: "32%" }}
          mt={{ base: 5, md: 0 }}
        ></Skeleton>
      </Flex>
    </Flex>
  );
};

export const SearchBoxAllServices = () => {
  return (
    <Flex minH={"300px"} w={"100%"} justify={"space-around"} flexDir={{ base: "column", md: "column" }}>
      <Skeleton rounded={["full", "xl", "full"]} shadow={"xl"} px={4} mt={"30px"}>
        <Flex h={"100px"}></Flex>
      </Skeleton>
      <Flex w={"100%"} justify={"center"} align={"center"}>
        <Skeleton h={"60px"} shadow={"xl"} w={200} borderRadius={40} />
      </Flex>
    </Flex>
  );
};

export const SearchedCategory = () => {
  return (
    <Flex minH={"300px"} w={"100%"} justify={"space-between"} flexDir={{ base: "column", md: "column" }}>
      <Flex
        w={"100%"}
        h={"100px"}
        // bg={"red"}
        justify={"space-between"}
        align={"center"}
        // mx={}
        my={15}
      >
        <Skeleton w={{ base: "150px", md: "200px" }} h={"60px"} ml={{ base: 0, md: 2 }} rounded={"xl"} />
        <Skeleton w={"100px"} h={"60px"} mr={{ base: 0, md: 2 }} rounded={"xl"} />
      </Flex>
      <Flex minH={"150px"} w={"100%"} justify={"space-between"} flexDir={{ base: "column", md: "row" }}>
        <Skeleton borderRadius={"15px"} h={"280px"} w={{ base: "100%", md: "32%" }}></Skeleton>
        <Skeleton
          borderRadius={"15px"}
          h={"280px"}
          w={{ base: "100%", md: "32%" }}
          mt={{ base: 5, md: 0 }}
        ></Skeleton>
        <Skeleton
          display={{ base: "none", md: "block" }}
          borderRadius={"15px"}
          h={"280px"}
          w={{ base: "100%", md: "32%" }}
          mt={{ base: 5, md: 0 }}
        ></Skeleton>
      </Flex>
    </Flex>
  );
};

export const TableList = () => {
  return (
    // <Flex minH={"prose"} w={"100%"} flexDir={{ base: "column", md: "column" }}>
    <>
      <Skeleton h={["25px", "30px", "40px"]} display={"block"} />
      <Skeleton h={["25px", "30px", "40px"]} w={["12rem", "32rem", "45rem", "55rem"]} my={"1"} />
      <Skeleton h={["25px", "30px", "40px"]} w={"100%"} my={"1"} />
      <Skeleton h={["25px", "30px", "40px"]} w={"100%"} my={"1"} />
      <Skeleton h={["25px", "30px", "40px"]} w={"100%"} my={"1"} />
      <Skeleton h={["25px", "30px", "40px"]} w={"100%"} my={"1"} />
      <Skeleton h={["25px", "30px", "40px"]} w={"100%"} my={"1"} />
      <Skeleton h={["25px", "30px", "40px"]} w={"100%"} my={"1"} />
      <Skeleton h={["25px", "30px", "40px"]} w={"100%"} my={"1"} />
      <Skeleton h={["25px", "30px", "40px"]} w={"100%"} mb={"2"} />
    </>
    // </Flex>
  );
};
