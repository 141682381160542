import { createSlice } from "@reduxjs/toolkit";
import { addAccount, getSettings, updateSetting } from "@Redux/Services/AppSetting/appsetting";

const initialState: IAppSettings = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  appRange: [],
  appAccounts: [],
  appType: [],
};

const AppSettings = createSlice({
  name: `settings`,
  initialState,
  reducers: {
    resetSettings: (state) => {
      state.appRange = [];
      state.appAccounts = [];
      state.appType = [];
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    resetSettingsStatus: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    },
    resetSettingsMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(addSettings.pending, (state, action) => {
      //   state.isLoading = true;
      // })
      // .addCase(addSettings.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.enrollments.push(action.payload?.data?.Enrollment);
      // })
      // .addCase(addSettings.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = false;
      //   state.isError = true;
      // })
      .addCase(getSettings.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.appRange = action.payload.data?.CustomAppData.filter((data: any) => data.type === "app_range");
        state.appType = action.payload.data?.CustomAppData.filter((data: any) => data.type !== "app_range");
        state.appAccounts = action.payload.data?.AdminBankDetails;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(updateSetting.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateSetting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action?.payload?.data?.message;
      })
      .addCase(updateSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action?.payload as string;
      })
      .addCase(addAccount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        console.log(action.payload);
        console.log(action.payload?.data?.BankDetail);

        state.appAccounts.push(action.payload?.data?.BankDetail);
      })
      .addCase(addAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      });
  },
});

export const { resetSettings, resetSettingsMessage, resetSettingsStatus } = AppSettings.actions;

export const selectAppSettings = (state: any) => state.AppSettingsStore;
export default AppSettings.reducer;
