import {
  Box,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Heading,
  HStack,
  Button,
  Flex,
} from "@chakra-ui/react";
import { UseCustomAppColors } from "@utils/color";

import { CustomInputLabelAtLeft } from "@components/modals/CustomInputMain";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@Redux/hooks";
import {
  resetSettings,
  resetSettingsMessage,
  selectAppSettings,
} from "@Redux/Slices/AppSetting/appsettingSlices";

import { getSettings } from "@Redux/Services/AppSetting/appsetting";

import { MainLayout2 } from "@layouts/dashboardLayout/MainLayout2";
import { SlugUnderscores } from "@utils/helper";
import { AddNewAccount } from "./AppSettingComponentsForAccount/CreateAccount";
import { MdAddCircleOutline } from "react-icons/md";

function Index() {
  const { inverseSecondBg, textColor, secondBg } = UseCustomAppColors();
  const { isOpen: createIsOpen, onOpen: createOnOpen, onClose: createOnClose } = useDisclosure();
  // const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure();
  const [appAccountsData, setAppAccountsData] = useState<AppAccount[] | null>();

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { appAccounts, isLoading }: IAppSettings = useAppSelector(selectAppSettings);

  useEffect(() => {
    dispatch(resetSettingsMessage());
    dispatch(getSettings({}));
    dispatch(resetSettings());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) setAppAccountsData(appAccounts);
  }, [appAccounts, isLoading]);

  console.log(appAccountsData);

  let TabsForAppAccountForSmallScreen = appAccountsData?.map((account: AppAccount) => {
    return (
      <Tab
        _selected={{ color: textColor, bg: secondBg }}
        textTransform={"capitalize"}
        minW={"200px"}
        boxShadow={"0px 0px 4px #b7b7b7"}
      >
        {" "}
        {account.type && SlugUnderscores(account.type!)}
      </Tab>
    );
  });

  let TabsForAppAccount = appAccountsData?.map((account: AppAccount) => {
    return (
      <Tab
        key={account.type}
        _selected={{ color: "white", bg: inverseSecondBg }}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box
          w={"5"}
          h={"5"}
          bg={inverseSecondBg}
          rounded={"full"}
          outline={"solid"}
          outlineOffset={"2px"}
          p={"2"}
          mr={"2"}
        ></Box>
        <Text textTransform={"capitalize"} w={"60%"}>
          {account.type && SlugUnderscores(account.type!)}
        </Text>
      </Tab>
    );
  });

  // let accountHeader = () => (
  //   <Flex justify={"space-between"} boxShadow={"base"} py={"3"} px={"5"}>
  //     <Flex justify={"flex-start"}>
  //       <HStack mr={"3"}>
  //         <Heading fontWeight={"medium"} fontSize={["13px", "18px", "22px"]} mr={"1"}>
  //           Name:
  //         </Heading>
  //         <Text textTransform={"capitalize"} fontWeight={"medium"} fontSize={["11px", "14px", "18px"]}>
  //           App Accounts
  //         </Text>
  //       </HStack>
  //     </Flex>
  //     <Button
  //       colorScheme="gray"
  //       variant={"unstyled"}
  //       onClick={() => {
  //         console.log("account Label");
  //         return createOnOpen();
  //       }}
  //     >
  //       <MdAddCircleOutline fontSize={"2.2rem"} />
  //     </Button>
  //   </Flex>
  // );

  let listTabsForAppAccount =
    appAccountsData &&
    appAccountsData?.map((account: AppAccount) => {
      return (
        <TabPanel key={account.type}>
          <Box padding={"1"} py="1" pb={"12"}>
            <Box mt={"5"} boxShadow={"base"} py={"3"} px={"5"}>
              <CustomInputLabelAtLeft
                label="Account Type"
                name="type"
                maxW={["180px", "200px", "300px", "500px"]}
                my={"2"}
                value={account.type}
                isReadOnly={true}
              />
              <CustomInputLabelAtLeft
                label="Account Name"
                maxW={["180px", "200px", "300px", "500px"]}
                my={"2"}
                name="account_name"
                value={account.account_name}
                isReadOnly={true}
              />
              <CustomInputLabelAtLeft
                label="Account Number"
                name="account_number"
                maxW={["180px", "200px", "300px", "500px"]}
                my={"2"}
                value={account.account_number}
                isReadOnly={true}
              />
              <CustomInputLabelAtLeft
                label="Bank Name"
                name="bank_name"
                maxW={["180px", "200px", "300px", "500px"]}
                my={"2"}
                value={account.bank_name}
                isReadOnly={true}
              />
              <CustomInputLabelAtLeft
                label="Bank Code"
                name="bank_code"
                maxW={["180px", "200px", "300px", "500px"]}
                my={"2"}
                value={account.bank_code}
                isReadOnly={true}
              />
            </Box>
          </Box>
        </TabPanel>
      );
    });

  return (
    <MainLayout2>
      {/* <accountHeader /> */}
      <Box display={["none", "none", "none", "flex"]} flexDir={"row"}>
        <Tabs w={"100%"}>
          <Box justifyContent={"space-between"} display={"flex"} minH={"85vh"}>
            <Box w={"20%"} bg={secondBg} py={"20"}>
              <TabList display={"flex"} flexDir={"column"}>
                {TabsForAppAccount ? TabsForAppAccount : TabsForAppAccount}
              </TabList>
            </Box>
            <Box w={"78%"} bg={secondBg}>
              <Flex justify={"space-between"} boxShadow={"base"} py={"3"} px={"5"}>
                <Flex justify={"flex-start"}>
                  <HStack mr={"3"}>
                    <Heading fontWeight={"medium"} fontSize={["13px", "18px", "22px"]} mr={"1"}>
                      Name:
                    </Heading>
                    <Text
                      textTransform={"capitalize"}
                      fontWeight={"medium"}
                      fontSize={["11px", "14px", "18px"]}
                    >
                      App Accounts
                    </Text>
                  </HStack>
                </Flex>
                <Button
                  colorScheme="gray"
                  variant={"unstyled"}
                  onClick={() => {
                    console.log("account Label");
                    return createOnOpen();
                  }}
                >
                  <MdAddCircleOutline fontSize={"2.2rem"} />
                </Button>
              </Flex>
              <TabPanels>{listTabsForAppAccount}</TabPanels>
            </Box>
          </Box>
        </Tabs>
      </Box>

      <Box display={["flex", "flex", "flex", "none"]}>
        <Tabs>
          <Box overflowX={"scroll"} maxW={["350px", "500px", "600px"]}>
            {" "}
            <TabList>{TabsForAppAccountForSmallScreen}</TabList>
          </Box>

          <TabPanels display={"flex"} flexDir={"row"} flexWrap={"wrap"}>
            {listTabsForAppAccount}
          </TabPanels>
        </Tabs>
      </Box>

      <AddNewAccount isOpen={createIsOpen} onClose={createOnClose} onOpen={createOnOpen} />
    </MainLayout2>
  );
}

export default Index;
