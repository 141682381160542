import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NoAccessRoute, ProtectedRoute } from "./accessLevels";

import { authRoute, protectedAppRoutes, protectedRoutesExtra } from "@utils/routes";
import { useAppDispatch, useAppSelector } from "./Redux/hooks";
import { useEffect } from "react";

import { DefaultConstants, AdminConstants } from "@Redux/Services/Constants/constantsService";
import { AdminAuthStageOne } from "./Redux/Slices/Auth/authSlices";
import { NotFound } from "@pages/Dashboard/NotFound/Index";

function App() {
  const dispatch = useAppDispatch();
  const AuthStageOne: IAuthState = useAppSelector(AdminAuthStageOne);

  useEffect(() => {
    const init = async () => {
      try {
        if (AuthStageOne.user?.access_token) {
          dispatch(AdminConstants());
          dispatch(DefaultConstants());
        }
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, [AuthStageOne.user, dispatch]);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NoAccessRoute />}>
          {authRoute.map((route) => (
            <Route key={route.path} path={route.path} element={<route.component />}></Route>
          ))}
        </Route>
        <Route element={<ProtectedRoute />}>
          {protectedAppRoutes.map((route, index) =>
            route.children?.length! > 0 ? (
              route?.children?.map((child, childindex) => (
                <Route key={`${route.name}_${index}`} path={route.path} element={<route.component />}>
                  <Route
                    key={`${child.name}_${childindex}`}
                    path={child.path}
                    element={<child.component />}
                  ></Route>
                </Route>
              ))
            ) : (
              <Route key={`${route.name}_${index}`} path={route.path} element={<route.component />}>
                {" "}
              </Route>
            )
          )}
          {protectedRoutesExtra.map((route) => (
            <Route key={route.name} path={route.path} element={<route.component />}>
              {" "}
            </Route>
          ))}
        </Route>
        <Route element={<NotFound />} path="*"></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
