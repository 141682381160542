import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Box,
  Stack,
  Button,
  Flex,
} from "@chakra-ui/react";
import { CustomInputMain } from "@components/modals/CustomInputMain";
import { useAppDispatch } from "@Redux/hooks";

import { formatAndCapitalizeSlug } from "@utils/helper";
import { getSettings, updateSetting } from "@Redux/Services/AppSetting/appsetting";
import { inputFontSize } from "@utils/fonts";
import { UseCustomAppColors } from "@utils/color";
import { useCustomToast } from "@utils/toast";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  valueToEdit: string | number;
  editData: AppType;
};

type EditType = {
  name: string | number;
  value: string | number;
};

export const EditAppType: React.FC<Props> = ({ isOpen, onClose, onOpen, editData, valueToEdit }) => {
  const { inverseSecondBg, textColorInverse } = UseCustomAppColors();
  const [appTypeData, setAppTypeData] = useState<AppType>();

  const [appTypeEdit, setAppTypeEdit] = useState<EditType>({ name: "", value: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { notifyError, notifySuccess } = useCustomToast();

  useEffect(() => {
    setAppTypeData(editData);
  }, [isOpen, editData]);

  useEffect(() => {
    if (appTypeData) {
      appTypeData.value &&
        Object.entries(appTypeData.value).map(([k, v]) => {
          if (k === valueToEdit) {
            setAppTypeEdit({
              name: k,
              value: v,
            });
          }
        });
    }
  }, [appTypeData, valueToEdit]);

  const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setAppTypeEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let data = null;

      if (appTypeData && appTypeData.value) {
        const updatedValue = Object.entries(appTypeData.value).filter(([k, v]) => k !== valueToEdit);
        const newValue = updatedValue.reduce<{ [key: string]: any }>((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

        data = {
          id: appTypeData?.id!,
          type: appTypeData?.type!,
          name: appTypeData?.name!,
          value: {
            ...newValue,
            [appTypeEdit.name]: appTypeEdit.value,
          },
        };
      }
      let editValue = await dispatch(updateSetting(data));
      if (editValue.meta.requestStatus === "fulfilled") {
        notifySuccess(editValue.payload.message);
        await dispatch(getSettings({}));
        setIsLoading(false);
        onClose();
      }
      if (editValue.meta.requestStatus === "rejected") {
        notifyError(editValue.payload);
        setIsLoading(false);
        onClose();
      }

      setAppTypeEdit({ name: "", value: "" });
    } catch (error) {}
  };

  return (
    <>
      {isOpen && appTypeData && (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
          <ModalOverlay bg={"blackAlpha.200"} />
          <ModalContent>
            <ModalBody py={["1", "3", "14"]} px={["8", "14", "24"]}>
              <Box boxShadow="0px 0px 4px #b7b7b7" rounded="lg" px="14" pb={["5", "10"]}>
                <Heading py="4">{`Edit ${formatAndCapitalizeSlug(appTypeData.name!)}`}</Heading>
                <Box mt={"5"} boxShadow={"base"}>
                  <Stack direction={["column", "column", "row", "row"]} p={"2"}>
                    <CustomInputMain
                      label="Name"
                      name="name"
                      value={appTypeEdit.name}
                      onChange={(e) => handleKeyChange(e)}
                    />
                    <CustomInputMain
                      label="Value"
                      name="value"
                      value={appTypeEdit?.value}
                      onChange={(e) => handleKeyChange(e)}
                    />
                  </Stack>
                </Box>
                <Flex justify={"flex-end"} mt={"6"}>
                  <Button
                    fontSize={[inputFontSize.base, inputFontSize.md]}
                    py={[".4rem", "1.5rem"]}
                    px={["1.8rem", "3.8rem"]}
                    mb={{ base: "2.5" }}
                    bg={inverseSecondBg}
                    color={textColorInverse}
                    borderRadius={"lg"}
                    borderWidth={".1rem"}
                    _hover={{ bg: inverseSecondBg }}
                    isLoading={isLoading}
                    loadingText="Submitting"
                    variant="outline"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Edit
                  </Button>
                </Flex>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
