import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminLoginStageOne, AdminLoginStageTwo } from "@Redux/Services/Auth/authSevices";

let usersInfo;
if (typeof window !== "undefined") {
  usersInfo = localStorage.getItem("userInfo");
}

const user = usersInfo ? JSON.parse(localStorage?.getItem("userInfo")!) : null;

const initialState: IAuthState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isLoggedOut: false,
  authStageOne: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    resetAuthStore: (state) => {
      state.user = null;
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isLoggedOut = false;
      state.authStageOne = null;
      state.message = "";
      window.localStorage.removeItem("userInfo");
    },
    resetMessage: (state) => {
      state.message = "";
    },
    resetStatus: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
    },
    setCredentials: (state, { payload }: PayloadAction<AuthData>) => {
      state.user = payload;
      const userInfo = payload;
      window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
  },
  extraReducers(builder) {
    builder.addCase(AdminLoginStageOne.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(AdminLoginStageOne.fulfilled, (state, action) => {
      console.log(action.payload.data);

      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.authStageOne = action.payload.data;
      state.message = action.payload.message;
    });
    builder.addCase(AdminLoginStageOne.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = action.payload as string;
      console.log("rejected", action.payload);
    });

    builder.addCase(AdminLoginStageTwo.pending, (state, action) => {
      state.isLoading = true;
      console.log("pending", state);
    });
    builder.addCase(AdminLoginStageTwo.fulfilled, (state, action) => {
      console.log(action?.payload);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.authStageOne = null;
      state.user = action?.payload.data;
      state.message = action.payload.message;
      localStorage.setItem("userInfo", JSON.stringify(action?.payload.data));
    });
    builder.addCase(AdminLoginStageTwo.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.user = null;
      state.authStageOne = null;
      state.message = action.payload as string;
      console.log("rejected", action.payload);
    });
  },
});

export const { resetStatus, resetAuthStore, resetMessage, setCredentials } = authSlice.actions;
export const AdminAuthStageOne = (state: any) => state.authStore;

export const selectCurrentUser = (state: any) => state.authStore.user;

export default authSlice.reducer;
