import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Divider,
  Center,
  ModalHeader,

} from "@chakra-ui/react";

type NotificationTypes = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  // notifications?: NotificationType[] | undefined;
  notifications?: [] | undefined;
  isLoading: boolean;
};

export function NotificationMoadal({ isOpen, onClose, onOpen, notifications }: NotificationTypes) {
  return (
    <Box w={"1rem"}>
      <Modal size={"sm"} onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
        {/* <ModalOverlay /> */}
        <ModalContent position={"absolute"} right={["4rem", "4rem", "10rem", "10rem"]} top={".4rem"}>
          <Center>
            <ModalHeader p={"2.5"}> Notifications</ModalHeader>
          </Center>
          <Divider variant={"solid"} borderWidth={["thin", "thin", "1.8px", "2px"]} />
          <ModalCloseButton />
          <ModalBody>
            <Box></Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
